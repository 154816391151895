<!-- <header>
  <div class="container">
     <nav>
       <a href="javascript:void(0)" class="pull-left">
         <img src="assets/img/logo_white.png" class="logo">
       </a>
       <div class="navLinks pull-right">
         <ul>
           <li><a href="javascript:void(0)">OVERVIEW</a></li>
           <li><a href="javascript:void(0)">PRODUCTS</a></li>
           <li><a href="javascript:void(0)">FEATURES</a></li>
           <li><a href="javascript:void(0)">PRICING </a></li>
           <li><a href="javascript:void(0)"> LOG IN  <img src="assets/img/ico_login.png" alt="Login" /></a></li>
         </ul>
       </div>
     </nav>
  </div>
</header> -->


<div id="page-top">
  <svg width="1440px" height="1778px" viewBox="0 0 1440 1778" class="d-none d-lg-block" style="position:absolute;left:0;top:0;bottom:0;right:0;margin-left:auto;margin-right:auto;width:100%;pointer-events: none;">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="NEW-LOG-IN">
        <g id="CONNECTIONS" transform="translate(-79.000000, -18.000000)">
          <g id="CONNECTIONS-2">
            <!--<path d="M1196.5,375.5 L1582.5,283.5" id="Line" stroke="#6CD3D6" stroke-width="0.5" stroke-linecap="square"></path>-->
            <circle id="Oval" fill="#FFFFFF" cx="1170" cy="77" r="3"></circle>
            <path d="M1383.5,138.5 L1350.5,0.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1694.5,126.5 L1383.5,137.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1273.5,573.5 L1579.5,663.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="1272.5" cy="572.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1383.5" cy="137.5" r="1.5"></circle>
            <path d="M1384.5,137.5 L1288.5,201.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1441.5,390.5 L1520.5,541.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1475.5,653.5 L1520.5,541.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1475.5,653.5 L1370.5,782.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1370.5,782.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1441.5,390.5 L1537.5,348.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1373.5,509.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1521.5,542.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1269.5,421.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1119.5,510.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="1474.5" cy="654.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1370.5" cy="780.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="268.5" cy="135.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="153.5" cy="562.5" r="1.5"></circle>
            <path d="M268.5,136.5 L309.5,224.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L575.5,205.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L178.5,6.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L185.5,216.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M272.5,427.5 L69.5,235.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M0.5,341.5 L157.5,365.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M186.5,216.5 L69.5,235.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M305.5,505.5 L154.5,562.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
            <path d="M273.5,429.5 L154.5,562.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1129.5,1181.5 L1522.5,1225.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1528.5,1479.5 L1394.5,1409.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1454.66429,1677.33645 L1546.33571,1536.66355" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M247.5,1729.5 L61.6666667,1801.66096" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(154.500000, 1765.500000) rotate(-180.000000) translate(-154.500000, -1765.500000) "></path>
            <path d="M63.3405797,1482.6625 L188.5,1537.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(126.000000, 1510.000000) rotate(-180.000000) translate(-126.000000, -1510.000000) "></path>
            <path d="M278.5,1379.5 L63.6594203,1302.3375" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(171.000000, 1341.000000) rotate(-180.000000) translate(-171.000000, -1341.000000) "></path>
          </g>
          <g id="CONNECTIONS-1" transform="translate(156.000000, 162.000000)">
            <circle id="Oval-3" fill="#FFFFFF" cx="1073.5" cy="86.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1073.5" cy="86.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1114.5" cy="259.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1284.5" cy="228.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1218.5" cy="347.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1011.5" cy="7.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1132.5" cy="39.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1240.5" cy="83.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1041.5" cy="213.5" r="2.5"></circle>
            <path d="M1011.5,7.5 L1074.5,87.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1011.5,7.5 L1132.5,39.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1132.5,39.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1241.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1040.5,214.5 L1241.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1040.5,214.5 L1114.5,259.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1285.5,228.5 L1114.5,259.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1284.5,228.5 L1240.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1285.5,228.5 L1217.5,347.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L963.5,74.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1041.5,214.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1011.5,7.5 L895.5,0.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="150.5" cy="343.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="153.5" cy="62.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1.5" cy="203.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="29.5" cy="54.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="117.5" cy="266.5" r="1.5"></circle>
            <path d="M353.5,120.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M30.5,54.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M30.5,54.5 L1.5,203.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M116.5,265.5 L1.5,203.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M115.5,266.5 L228.5,160.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M115.5,266.5 L333.5,146.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M149.5,343.5 L261.5,282.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M149.5,343.5 L119.5,266.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M228.5,160.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          </g>
        </g>
      </g>
    </g>
  </svg> 
  <!-- Navigation -->

  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav" style="">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" routerLink="/"><img src="assets/img/logo_white.png" class="logo" alt="Logotype" /></a>
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav">
          <!--
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#about">Overview</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#customers">Collaboration</a>
        </li>
        -->
        
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/services">{{ 'front.services' | translate }}</a>
          </li>
          <li class="nav-item">
              <a class="nav-link js-scroll-trigger" routerLink="/vocean">Lösning</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/education">Utbildning</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/about">{{ 'front.about' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/blog">{{ 'front.blog' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger login" routerLink="/contact">{{ 'front.contact' | translate }} <img src="assets/img/ico_login.png" alt="Contact" /></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!--
  <svg width="627px" height="437px" viewBox="0 0 627 437" class="paralax navtoform on d-none d-lg-block ">
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="NEW-LOG-IN" transform="translate(-494.000000, -58.000000)">
              <g id="RIGHT-TRIANGLE" transform="translate(494.000000, 58.000000)">
                  <polygon id="Triangle" fill="#0BB8FF" opacity="0.511605525" transform="translate(479.500000, 86.500000) rotate(-136.000000) translate(-479.500000, -86.500000) " points="472.847743 73 500 90.3170711 459 100"></polygon>
                  <path d="M596.5,0.5 L476.5,87.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <path d="M2.5,130.5 L480.5,85.7257919" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
                  <path d="M545.5,159.725792 L479.5,85.7257919" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <path d="M622.5,299.5 L547.5,164.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <path d="M622.5,299.5 L544.5,434.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <circle id="Oval" fill="#FFFFFF" cx="624" cy="300" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="546" cy="434" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="3" cy="130" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="478" cy="86" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="546" cy="161" r="3"></circle>
              </g>
          </g>
      </g>
  </svg>

  <svg width="95px" height="154px" viewBox="0 0 95 154" class="paralax emailtopassword on d-none d-lg-block">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="NEW-LOG-IN" transform="translate(-320.000000, -289.000000)">
        <g id="LEFT-TRIANGLE" transform="translate(320.000000, 289.000000)">
          <polygon id="Triangle" fill="#0BB8FF" opacity="0.511605525" points="17.9007411 118 53 141.089428 0 154"></polygon>
          <path d="M16.5,141.5 L88.5,2.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
          <path d="M16.5,141.5 L92.5,123.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
          <circle id="Oval" fill="#FFFFFF" cx="89" cy="3" r="3"></circle>
          <circle id="Oval" fill="#FFFFFF" cx="92" cy="125" r="3"></circle>
          <circle id="Oval" fill="#FFFFFF" cx="19" cy="139" r="3"></circle>
        </g>
      </g>
    </g>
  </svg>
  -->
  <section id="contact" class="block">
    <!--
  <svg width="312px" height="574px" viewBox="0 0 312 574" class="paralax on contact">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="GREEN-LINES">
        <path d="M64.5,264.5 L64.5,198.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M6.5,123.5 L63.5,195.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M65.5,264.5 L65.5,353.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M65.5,424.5 L65.5,353.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M65.5,424.5 L65.5,495.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M106.5,573.5 L65.5,495.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <circle id="Oval" fill="#FFFFFF" cx="315.5" cy="-1" r="4.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="195.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="265.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="352.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="491.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="5" cy="124" r="5"></circle>
        <path d="M9.5,122.5 L311.5,0.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
      </g>
    </g>
  </svg>
     -->

    <div class="contactForm blog-wrapper" style="margin-top: 115px; min-height: 1600px; text-align: left; margin-bottom: 100px;">

      <div class="blog-page">
        <router-outlet></router-outlet>
      </div>

      <div class="row blog-list-container">
        <div class="col-lg-4">
          <img src="../../assets/img/blog/leadership.jpg" />
        </div>
        <div class="col-lg-8">
          <a routerLink="./blog-190831"><h3>Trust-Leadership-Relations</h3></a>
          <p class="blog-list-date">augusti 31, 2019</p>
          <p class="blog-list-text">Relationer är viktigaste elementet för en ledare att skapa förtroende enligt denna artikel från Harvard. Instämmer och vill lägga till att relationer som är positiva och enklast skapar förtroende kallas kollaborativa.</p>
          <div class="blog-list-more"><a routerLink="./blog-190831">Läs mer</a></div>
        </div>
      </div>

      <div class="row blog-list-container">
        <div class="col-lg-4">
          <img src="../../assets/img/blog/nm-active-thinking.jpeg" />
        </div>
        <div class="col-lg-8">
          <a routerLink="./blog-190629"><h3>Active thinking</h3></a>
          <p class="blog-list-date">juni 29, 2019</p>
          <p class="blog-list-text">Active Thinking Organization; Vad betyder det för dig?</p>
          <div class="blog-list-more"><a routerLink="./blog-190629">Läs mer</a></div>
        </div>
      </div>

      <div class="row blog-list-container">
        <div class="col-lg-4">
          <img src="../../assets/img/blog/city-globe.png" />
        </div>
        <div class="col-lg-8">
          <a routerLink="./blog-190625"><h3>Kod till framgång!</h3></a>
          <p class="blog-list-date">juni 25, 2019</p>
          <p class="blog-list-text">Koden för framgång i din organisation tror vi är när medarbetare, känner, tänker och sedan tar action. Viktigt att detta fångas upp av ledning, medarbetare får återkoppling och kan genom dessa steg snabbt utvecklas.</p>
          <div class="blog-list-more"><a routerLink="./blog-190625">Läs mer</a></div>
        </div>
      </div>

      <div class="row blog-list-container">
        <div class="col-lg-4">
          <img src="../../assets/img/blog/trust1.png" />
        </div>
        <div class="col-lg-8">
          <a routerLink="./blog-190624"><h3>Släpp din osäkerhet: Tillit får ditt team att växa!</h3></a>
          <p class="blog-list-date">juni 24, 2019</p>
          <p class="blog-list-text">VOCEAN® existerar för att företagets grundare insåg att de flesta organisationer inte till fullo drar nytta av alla de färdigheter och den unika potential som medarbetarna har.</p>
          <div class="blog-list-more"><a routerLink="./blog-190624">Läs mer</a></div>
        </div>
      </div>

    </div>
  </section>

  <!-- Footer -->
  <footer>
    <div class="container-fluid">
      <p class="m-0 text-center text-white">&copy;2019 NextMind® AB. ALL RIGHTS RESERVED.</p>
    </div>
    <!-- /.container -->
  </footer>
</div>
