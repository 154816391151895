<h1>Active thinking</h1>
<p>juni 29, 2019</p>
<img src="../../../assets/img/blog/nm-active-thinking.jpeg" />

<p>Active Thinking Organization;<br>Vad betyder det för dig?</p>

<p>För mig känner jag att det andas framtid. Vad händer om vi inte börjar ta action på det vi tänker i organisationer, ta tillvara på medarbetarnas engagemang, våra leverantörers, partners och kunders konstanta flöde av kloka tyckanden.</p>

<p>Det kanske inte är framtid utan nutid och för att vara konkurrenskraftig eller överleva.</p>

<p>Hur säkerställer du detta i din organisation? Är det du som ledare på grund av ditt eget begränsade sätt att jobba som gör att din organisation inte fångar och navigerar efter detta?</p>

<p>Personligen är jag lite orolig att vi som ledare inte lyckas fånga det viktigaste i vår vardag för vår framtid, utvecklingen går oerhört snabbt framåt och vi som ledare vet att vi måste agera men inte hur.</p>

<p>Peter Kollin,<br>Founder & CEO<br>NextMind & Vocean</p>
