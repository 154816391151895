 <!-- <header>
   <div class="container">
      <nav>
        <a href="javascript:void(0)" class="pull-left">
          <img src="assets/img/logo_white.png" class="logo">
        </a>
        <div class="navLinks pull-right">
          <ul>
            <li><a href="javascript:void(0)">OVERVIEW</a></li>
            <li><a href="javascript:void(0)">PRODUCTS</a></li>
            <li><a href="javascript:void(0)">FEATURES</a></li>
            <li><a href="javascript:void(0)">PRICING </a></li>
            <li><a href="javascript:void(0)"> LOG IN  <img src="assets/img/ico_login.png" alt="Login" /></a></li>
          </ul>
        </div>
      </nav>
   </div>
 </header> -->


<div id="page-top">
  <svg width="1440px" height="1778px" viewBox="0 0 1440 1778" class="d-none d-lg-block" style="position:absolute;left:0;top:0;bottom:0;right:0;margin-left:auto;margin-right:auto;width:100%;">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="NEW-LOG-IN">
        <g id="CONNECTIONS" transform="translate(-79.000000, -18.000000)">
          <g id="CONNECTIONS-2">
            <!--<path d="M1196.5,375.5 L1582.5,283.5" id="Line" stroke="#6CD3D6" stroke-width="0.5" stroke-linecap="square"></path>-->
            <circle id="Oval" fill="#FFFFFF" cx="1170" cy="77" r="3"></circle>
            <path d="M1383.5,138.5 L1350.5,0.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1694.5,126.5 L1383.5,137.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1273.5,573.5 L1579.5,663.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="1272.5" cy="572.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1383.5" cy="137.5" r="1.5"></circle>
            <path d="M1384.5,137.5 L1288.5,201.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1441.5,390.5 L1520.5,541.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1475.5,653.5 L1520.5,541.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1475.5,653.5 L1370.5,782.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1370.5,782.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1441.5,390.5 L1537.5,348.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1373.5,509.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1521.5,542.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1269.5,421.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1119.5,510.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="1474.5" cy="654.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1370.5" cy="780.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="268.5" cy="135.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="153.5" cy="562.5" r="1.5"></circle>
            <path d="M268.5,136.5 L309.5,224.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L575.5,205.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L178.5,6.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L185.5,216.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M272.5,427.5 L69.5,235.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M0.5,341.5 L157.5,365.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M186.5,216.5 L69.5,235.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M305.5,505.5 L154.5,562.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
            <path d="M273.5,429.5 L154.5,562.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1129.5,1181.5 L1522.5,1225.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1528.5,1479.5 L1394.5,1409.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1454.66429,1677.33645 L1546.33571,1536.66355" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M247.5,1729.5 L61.6666667,1801.66096" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(154.500000, 1765.500000) rotate(-180.000000) translate(-154.500000, -1765.500000) "></path>
            <path d="M63.3405797,1482.6625 L188.5,1537.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(126.000000, 1510.000000) rotate(-180.000000) translate(-126.000000, -1510.000000) "></path>
            <path d="M278.5,1379.5 L63.6594203,1302.3375" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(171.000000, 1341.000000) rotate(-180.000000) translate(-171.000000, -1341.000000) "></path>
          </g>
          <g id="CONNECTIONS-1" transform="translate(156.000000, 162.000000)">
            <circle id="Oval-3" fill="#FFFFFF" cx="1073.5" cy="86.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1073.5" cy="86.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1114.5" cy="259.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1284.5" cy="228.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1218.5" cy="347.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1011.5" cy="7.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1132.5" cy="39.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1240.5" cy="83.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1041.5" cy="213.5" r="2.5"></circle>
            <path d="M1011.5,7.5 L1074.5,87.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1011.5,7.5 L1132.5,39.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1132.5,39.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1241.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1040.5,214.5 L1241.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1040.5,214.5 L1114.5,259.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1285.5,228.5 L1114.5,259.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1284.5,228.5 L1240.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1285.5,228.5 L1217.5,347.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L963.5,74.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1041.5,214.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1011.5,7.5 L895.5,0.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="150.5" cy="343.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="153.5" cy="62.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1.5" cy="203.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="29.5" cy="54.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="117.5" cy="266.5" r="1.5"></circle>
            <path d="M353.5,120.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M30.5,54.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M30.5,54.5 L1.5,203.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M116.5,265.5 L1.5,203.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M115.5,266.5 L228.5,160.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M115.5,266.5 L333.5,146.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M149.5,343.5 L261.5,282.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M149.5,343.5 L119.5,266.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M228.5,160.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- Navigation -->

  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav" style="">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" routerLink="/"><img src="assets/img/logo_white.png" class="logo" alt="Logotype" /></a>
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav">
          <!--
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#about">Overview</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#customers">Collaboration</a>
        </li>
        -->
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="/">{{ 'front.overview' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/services">{{ 'front.services' | translate }}</a>
          </li>
          <li class="nav-item">
              <a class="nav-link js-scroll-trigger" routerLink="/vocean">{{ 'front.digital' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/about">{{ 'front.about' | translate }}</a>
          </li>
          <li class="nav-item">
              <a class="nav-link js-scroll-trigger" routerLink="/blog">{{ 'front.blog' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger login" routerLink="/contact">{{ 'front.contact' | translate }} <img src="assets/img/ico_login.png" alt="Contact" /></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!--
  <svg width="627px" height="437px" viewBox="0 0 627 437" class="paralax navtoform on d-none d-lg-block ">
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="NEW-LOG-IN" transform="translate(-494.000000, -58.000000)">
              <g id="RIGHT-TRIANGLE" transform="translate(494.000000, 58.000000)">
                  <polygon id="Triangle" fill="#0BB8FF" opacity="0.511605525" transform="translate(479.500000, 86.500000) rotate(-136.000000) translate(-479.500000, -86.500000) " points="472.847743 73 500 90.3170711 459 100"></polygon>
                  <path d="M596.5,0.5 L476.5,87.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <path d="M2.5,130.5 L480.5,85.7257919" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
                  <path d="M545.5,159.725792 L479.5,85.7257919" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <path d="M622.5,299.5 L547.5,164.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <path d="M622.5,299.5 L544.5,434.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <circle id="Oval" fill="#FFFFFF" cx="624" cy="300" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="546" cy="434" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="3" cy="130" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="478" cy="86" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="546" cy="161" r="3"></circle>
              </g>
          </g>
      </g>
  </svg>
  
  <svg width="95px" height="154px" viewBox="0 0 95 154" class="paralax emailtopassword on d-none d-lg-block">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="NEW-LOG-IN" transform="translate(-320.000000, -289.000000)">
        <g id="LEFT-TRIANGLE" transform="translate(320.000000, 289.000000)">
          <polygon id="Triangle" fill="#0BB8FF" opacity="0.511605525" points="17.9007411 118 53 141.089428 0 154"></polygon>
          <path d="M16.5,141.5 L88.5,2.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
          <path d="M16.5,141.5 L92.5,123.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
          <circle id="Oval" fill="#FFFFFF" cx="89" cy="3" r="3"></circle>
          <circle id="Oval" fill="#FFFFFF" cx="92" cy="125" r="3"></circle>
          <circle id="Oval" fill="#FFFFFF" cx="19" cy="139" r="3"></circle>
        </g>
      </g>
    </g>
  </svg>
  -->
  <section id="contact" class="block">
    <!--
  <svg width="312px" height="574px" viewBox="0 0 312 574" class="paralax on contact">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="GREEN-LINES">
        <path d="M64.5,264.5 L64.5,198.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M6.5,123.5 L63.5,195.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M65.5,264.5 L65.5,353.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M65.5,424.5 L65.5,353.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M65.5,424.5 L65.5,495.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M106.5,573.5 L65.5,495.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <circle id="Oval" fill="#FFFFFF" cx="315.5" cy="-1" r="4.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="195.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="265.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="352.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="491.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="5" cy="124" r="5"></circle>
        <path d="M9.5,122.5 L311.5,0.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
      </g>
    </g>
  </svg>
     -->
   
      <div class="contactForm" style="margin-top: 65px; margin-bottom: 95px;">

        <div class="row">
          <div class="col-12 text-center">

            <h1 style="margin-top: 15px; margin-bottom: 20px;">Integritetspolicy</h1>
            <p>Så här behandlar NextMind AB dina personuppgifter.</p>
            <p>NextMind AB: (”NextMind”) är personuppgiftsansvariga för behandlingen av dina personuppgifter och ansvarar för att behandling av personuppgifter sker enligt vid var tid tillämplig dataskyddslagstiftning, vilket för närvarande är Dataskyddsförordningen (EU) 2016/679 (GDPR), benämnd ”Tillämplig Dataskyddslagstiftning”.</p>
            <p>NextMind värnar om din personliga integritet och skyddar dina personuppgifter med nödvändiga tekniska och organisatoriska säkerhetsåtgärder. Du som är registrerad hos oss kan alltid kontakta oss gällande vår personuppgiftsbehandling och äger rätt att få reda på vilka personuppgifter vi behandlar om dig. Du kan läsa mer om dina rättigheter i nedan Integritetspolicy samt besöka datainspektionens hemsida för ytterligare information om personuppgiftsbehandling.</p>
            <p>”Personuppgifter” är all slags information som direkt eller indirekt kan hänföras till en fysisk person som är i livet.</p>
            <p>Denna Integritetspolicy beskriver hur NextMind behandlar dina Personuppgifter. Har du några frågor om vår behandling av Personuppgifter är du givetvis alltid välkommen att kontakta oss.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Vem ansvarar för dina Personuppgifter?</h4>
            <p>NextMind AB, org.nr. 556678-8922 är personuppgiftsansvarig för behandlingen av dina Personuppgifter och ansvarar för att sådan behandling är förenlig med Tillämplig Dataskyddslagstiftning.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Hur får NextMind dina Personuppgifter?</h4>
            <p>NextMind får in Personuppgifter som du lämnar till oss när du exempelvis blir kund hos oss, kontaktar oss via e-post eller telefon, ansöker om arbete hos oss, eller i övrigt använder dig av våra tjänster. NextMind behandlar även Personuppgifter som samlats in från andra källor än från dig. Personuppgifterna kan komma från exempelvis Kreditupplysningsföretag eller Skatteverket.</p>
            <p>NextMind behandlar de Personuppgifter som lämnas till oss eller inhämtas av oss. Det kan till exempel vara namn, e-postadress, telefonnummer, adressuppgifter, personnummer och bankuppgifter samt uppgifter lämnade vid användning av tjänster tillhandahållna av NextMind.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Vilket ändamål har NextMind med personuppgiftbehandlingen?</h4>
            <p>NextMind behandlar dina Personuppgifter som krävs för fullgörandet av vårt åtagande enligt avtal mot dig samt om du har lämnat samtycke till behandlingen. NextMind kan även behöva behandla dina Personuppgifter på grund av krav i lag eller förordning. NextMind behandlar även Personuppgifter när det är nödvändigt för att tillgodose vårt och ditt berättigade intresse av att hantera exempelvis kontakten mellan dig och din leverantör samt för att utvärdera, utveckla och förbättra våra tjänster, produkter och system.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Vem har tillgång till dina personuppgifter?</h4>
            <p>Dina Personuppgifter behandlas av NextMind och delvis av de leverantörer som vi sätter er i kontakt med på er begäran. I övrigt lämnar vi inte ut personuppgifter till andra bolag eller till myndigheter om det inte krävs för nödvändig hantering för uppfyllande av NextMind rättsliga förpliktelser, förordning eller myndighetsbeslut eller för att skydda våra rättigheter eller tredje parts rättigheter.</p>
            <p>Vi vidarebefordrar, säljer eller byter aldrig dina Personuppgifter för marknadsföringsändamål till tredje part utanför NextMind.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Överföring av personuppgifter till tredjeland?</h4>
            <p>NextMind överför inte dina Personuppgifter till länder utanför EU/EES om inte detta särskilt angivits i samband med att du lämnar dina Personuppgifter till oss.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Hur länge behandlar NextMind dina Personuppgifter?</h4>
            <p>Personuppgifterna lagras inte under en längre tid än vad som är nödvändigt för de ändamål för vilka Personuppgifterna behandlas. Personuppgifter som exempelvis behandlas för bokföringsändamål sparas under 7 år, vilket följer av rättligs förpliktelse enligt Bokföringslagen.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Vad har du för rättigheter som registrerad?</h4>
            <ul style="list-style-position: inside; padding: 0px;">
              <li>Du har rätt att när som helst begära information om vilka Personuppgifter vi har behandlar om dig.</li>
              <li>Om dina uppgifter är felaktiga, ofullständiga eller irrelevanta, kan du begära att få dem rättade.</li>
              <li>Du har rätt att begära att vår behandling av dina Personuppgifter begränsas.</li>
              <li>Du har rätt att begära att vi raderar dina Personuppgifter. Vi kan ha rätt att neka din begäran ifall det finns legala skyldigheter som hindrar oss från att omedelbart radera vissa Personuppgifter. Dessa skyldigheter kommer från bokförings- och skattelagstiftning, bank- och penningtvättslagstiftning. Samma gäller om behandlingen är nödvändig för att vi ska kunna fastställa, göra gällande eller försvara rättsliga anspråk. Skulle vi vara förhindrade att tillmötesgå en begäran om radering kommer vi istället att blockera Personuppgifterna från att kunna användas till andra syften än det syfte som hindrar den begärda raderingen.</li>
              <li>Du har alltid rätt att invända mot viss typ av behandling, exempelvis mot direktmarknadsföring och att invända mot all behandling av Personuppgifter som bygger på en intresseavvägning.</li>
              <li>Om vår rätt att behandla dina Personuppgifter grundar sig antingen på ditt samtycke eller fullgörande av ett avtal med dig har du rätt att begära att få de uppgifter som rör dig överlämnade för att du ska kunna överföra den till annan part.</li>
              <li>För det fall du har lämnat samtycke till viss behandling har du alltid rätt att när som helst återkalla ditt samtycke. Återkallar du ditt samtycke kommer behandlingen som baseras på ditt samtycke att upphöra, om inte NextMind bedömer att det finns en annan legal grund för behandlingen.</li>
            </ul>
            <p>Om du vill ändra något enligt dina rättigheter enligt ovan, vänligen kontakta oss på: <a href="mailto:peter@nextmind.com">peter@nextmind.com</a></p>
            <p>Du har rätt att i förekommande fall inge klagomål till Datainspektionen som är tillsynsmyndighet för behandling av Personuppgifter.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Hur skyddas dina uppgifter?</h4>
            <p>NextMind arbetar ständigt med att vidta alla lämpliga tekniska och organisatoriska åtgärder som i varje enskilt fall krävs för att skydda dina Personuppgifter och i övrigt säkerställa att behandlingen sker enligt gällande Tillämplig Dataskyddslagstiftning.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Uppdatering av Integritetspolicy</h4>
            <p>Denna integritetspolicy uppdaterar NextMind löpande. Den nya versionen publiceras på hemsidan thinkthank.se och gäller från publiceringsdatum.</p>

            <h4 style="margin-top: 30px; margin-bottom: 15px;">Kontaktinformation</h4>
            <p>Tveka inte att kontakta oss om du har några frågor om vår behandling av dina Personuppgifter eller om denna Integritetspolicy.</p>
            <p><a href="mailto:peter@nextmind.com">peter@nextmind.com</a></p>
            <p>
              NextMind AB<br />
              Kyrkogatan 33<br />
              803 11 Gävle<br />
              Sverige
            </p>
            <p>Denna Integritetspolicy är gällande fr.o.m. 25 maj 2018.</p>

          </div>
      </div>
    </div>
  </section>
  
  <!-- Footer -->
  <footer>
    <div class="container-fluid">
      <p class="m-0 text-center text-white">&copy;2019 NextMind® AB. ALL RIGHTS RESERVED.</p>
    </div>
    <!-- /.container -->
  </footer>
</div>
