<h1>Trust-Leadership-Relations</h1>
<p>augusti 31, 2019</p>
<img src="../../../assets/img/blog/leadership.jpg" />

<p>Relationer är viktigaste elementet för en ledare att skapa förtroende enligt denna artikel från Harvard. Instämmer och vill lägga till att relationer som är positiva och enklast skapar förtroende kallas kollaborativa.</p>

<p>Relationer kan skapas både vid fysiska möten men även digitala, där forskningen är tydlig att frekvensen, dvs. hur ofta vi kontaktar varandra har enormt stor betydelse. Det kanske förklarar varför våra ungdomar enkelt bygger relation via Snapchat eller messenger, och enligt forskningen är frekvens viktigare än mötesform. Något att tänka på i den digitala era vi lever i, för oss "lite äldre" som fortfarande är starkt övertygade om det fysiska mötets betydelse. Aldrig försent att tänka om eller ännu bättre kombinera!</p>

<p><a href="https://hbr.org/2019/02/the-3-elements-of-trust" target="_blank">Artikel från Harvard: The 3 Elements of Trust</a></p>

<p>Peter Kollin,<br>Founder & CEO<br>NextMind & Vocean</p>
