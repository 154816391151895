<div id="page-top">
  <svg width="1440px" height="1778px" viewBox="0 0 1440 1778" class="d-none d-lg-block" style="position:absolute;left:0;top:0;bottom:0;right:0;margin-left:auto;margin-right:auto;width:100%;">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="NEW-LOG-IN">
        <g id="CONNECTIONS" transform="translate(-79.000000, -18.000000)">
          <g id="CONNECTIONS-2">
            <!--<path d="M1196.5,375.5 L1582.5,283.5" id="Line" stroke="#6CD3D6" stroke-width="0.5" stroke-linecap="square"></path>-->
            <circle id="Oval" fill="#FFFFFF" cx="1170" cy="77" r="3"></circle>
            <path d="M1383.5,138.5 L1350.5,0.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1694.5,126.5 L1383.5,137.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1273.5,573.5 L1579.5,663.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="1272.5" cy="572.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1383.5" cy="137.5" r="1.5"></circle>
            <path d="M1384.5,137.5 L1288.5,201.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1441.5,390.5 L1520.5,541.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1475.5,653.5 L1520.5,541.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1475.5,653.5 L1370.5,782.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1370.5,782.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1441.5,390.5 L1537.5,348.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1373.5,509.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1521.5,542.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1269.5,421.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1119.5,510.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="1474.5" cy="654.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1370.5" cy="780.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="268.5" cy="135.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="153.5" cy="562.5" r="1.5"></circle>
            <path d="M268.5,136.5 L309.5,224.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L575.5,205.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L178.5,6.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L185.5,216.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M272.5,427.5 L69.5,235.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M0.5,341.5 L157.5,365.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M186.5,216.5 L69.5,235.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M305.5,505.5 L154.5,562.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
            <path d="M273.5,429.5 L154.5,562.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1129.5,1181.5 L1522.5,1225.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1528.5,1479.5 L1394.5,1409.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1454.66429,1677.33645 L1546.33571,1536.66355" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M247.5,1729.5 L61.6666667,1801.66096" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(154.500000, 1765.500000) rotate(-180.000000) translate(-154.500000, -1765.500000) "></path>
            <path d="M63.3405797,1482.6625 L188.5,1537.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(126.000000, 1510.000000) rotate(-180.000000) translate(-126.000000, -1510.000000) "></path>
            <path d="M278.5,1379.5 L63.6594203,1302.3375" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(171.000000, 1341.000000) rotate(-180.000000) translate(-171.000000, -1341.000000) "></path>
          </g>
          <g id="CONNECTIONS-1" transform="translate(156.000000, 162.000000)">
            <circle id="Oval-3" fill="#FFFFFF" cx="1073.5" cy="86.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1073.5" cy="86.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1114.5" cy="259.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1284.5" cy="228.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1218.5" cy="347.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1011.5" cy="7.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1132.5" cy="39.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1240.5" cy="83.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1041.5" cy="213.5" r="2.5"></circle>
            <path d="M1011.5,7.5 L1074.5,87.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1011.5,7.5 L1132.5,39.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1132.5,39.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1241.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1040.5,214.5 L1241.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1040.5,214.5 L1114.5,259.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1285.5,228.5 L1114.5,259.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1284.5,228.5 L1240.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1285.5,228.5 L1217.5,347.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L963.5,74.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1041.5,214.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1011.5,7.5 L895.5,0.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="150.5" cy="343.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="153.5" cy="62.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1.5" cy="203.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="29.5" cy="54.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="117.5" cy="266.5" r="1.5"></circle>
            <path d="M353.5,120.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M30.5,54.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M30.5,54.5 L1.5,203.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M116.5,265.5 L1.5,203.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M115.5,266.5 L228.5,160.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M115.5,266.5 L333.5,146.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M149.5,343.5 L261.5,282.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M149.5,343.5 L119.5,266.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M228.5,160.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          </g>
        </g>
      </g>
    </g>
  </svg> 
  <!-- Navigation -->

  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav" style="">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" routerLink="/"><img src="assets/img/logo_white.png" class="logo" alt="Logotype" /></a>
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav">
          <!--
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#about">Overview</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#customers">Collaboration</a>
        </li>
        -->
        
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/services">{{ 'front.services' | translate }}</a>
          </li>
          <li class="nav-item">
              <a class="nav-link js-scroll-trigger" routerLink="/vocean">Lösning</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/education">Utbildning</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/about">{{ 'front.about' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/blog">{{ 'front.blog' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger login" routerLink="/contact">{{ 'front.contact' | translate }} <img src="assets/img/ico_login.png" alt="Contact" /></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!--
  <svg width="627px" height="437px" viewBox="0 0 627 437" class="paralax navtoform on d-none d-lg-block ">
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="NEW-LOG-IN" transform="translate(-494.000000, -58.000000)">
              <g id="RIGHT-TRIANGLE" transform="translate(494.000000, 58.000000)">
                  <polygon id="Triangle" fill="#0BB8FF" opacity="0.511605525" transform="translate(479.500000, 86.500000) rotate(-136.000000) translate(-479.500000, -86.500000) " points="472.847743 73 500 90.3170711 459 100"></polygon>
                  <path d="M596.5,0.5 L476.5,87.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <path d="M2.5,130.5 L480.5,85.7257919" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
                  <path d="M545.5,159.725792 L479.5,85.7257919" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <path d="M622.5,299.5 L547.5,164.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <path d="M622.5,299.5 L544.5,434.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
                  <circle id="Oval" fill="#FFFFFF" cx="624" cy="300" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="546" cy="434" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="3" cy="130" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="478" cy="86" r="3"></circle>
                  <circle id="Oval" fill="#FFFFFF" cx="546" cy="161" r="3"></circle>
              </g>
          </g>
      </g>
  </svg>

  <svg width="95px" height="154px" viewBox="0 0 95 154" class="paralax emailtopassword on d-none d-lg-block">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="NEW-LOG-IN" transform="translate(-320.000000, -289.000000)">
        <g id="LEFT-TRIANGLE" transform="translate(320.000000, 289.000000)">
          <polygon id="Triangle" fill="#0BB8FF" opacity="0.511605525" points="17.9007411 118 53 141.089428 0 154"></polygon>
          <path d="M16.5,141.5 L88.5,2.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
          <path d="M16.5,141.5 L92.5,123.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
          <circle id="Oval" fill="#FFFFFF" cx="89" cy="3" r="3"></circle>
          <circle id="Oval" fill="#FFFFFF" cx="92" cy="125" r="3"></circle>
          <circle id="Oval" fill="#FFFFFF" cx="19" cy="139" r="3"></circle>
        </g>
      </g>
    </g>
  </svg>
  -->
  <section id="contact" class="block">
    <!--
  <svg width="312px" height="574px" viewBox="0 0 312 574" class="paralax on contact">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="GREEN-LINES">
        <path d="M64.5,264.5 L64.5,198.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M6.5,123.5 L63.5,195.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M65.5,264.5 L65.5,353.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M65.5,424.5 L65.5,353.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M65.5,424.5 L65.5,495.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <path d="M106.5,573.5 L65.5,495.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
        <circle id="Oval" fill="#FFFFFF" cx="315.5" cy="-1" r="4.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="195.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="265.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="352.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="64.5" cy="491.5" r="6.5"></circle>
        <circle id="Oval" fill="#FFFFFF" cx="5" cy="124" r="5"></circle>
        <path d="M9.5,122.5 L311.5,0.5" id="Line" stroke="#81AC3F" stroke-linecap="square"></path>
      </g>
    </g>
  </svg>
     -->

    <div class="contactForm" style="margin-top: 65px;">

      <div class="row">
        <div class="col-12 text-center" style="border-bottom: 1px solid #606367; margin-bottom: 20px; padding-bottom: 20px;">

          <h1 style="margin-top: 15px; margin-bottom: 20px;">Din verklighet - vår affärsidé</h1>
          <p>NextMind är din strategiska partner och rådgivare. Oavsett fråga, finns vi alltid som resurs för din organisation. Vi stödjer din utveckling och vägleder dig på ditt företags strategiska resa. Med dina utmaningar och din vardag som utgångspunkt arbetar vi tillsammans för att skapa förutsättningar för tillväxt och förändring. Din framgång är vårt genuina mål.</p>
          
          <div class="row" style="margin-bottom: 2rem;">
            <div class="col-lg-12">
              <img src="../../assets/img/photos/kamera.png" style="width: 60%; border-radius: 10px; margin-top: 15px; opacity: 0.9;">
            </div>
          </div>
          
          <h4>Utmaningar är möjligheter</h4>
          <p>Vi ser möjligheter i utmaningar. Genom att ifrågasätta gamla sanningar och invanda mönster, skapar vi förutsättning för att växa och bygga framtid. Vi ser kraften i att arbeta tillsammans för att öka din konkurrenskraft och stärka hela värdekedjan, från produktion till försäljning, inför internationalisering eller förflyttningar.</p>
          <h4>Vi stödjer dig hela vägen</h4>
          <p>Tillsammans med dig och dina medarbetare arbetar vi med att stärka ledarskapet och bygga ett framgångsrikt team. Vi driver idéer till verklighet och tar tillvara på ditt företag och dina medarbetares potential. Vi coachar och skapar möjlighet att generationsväxla, växla upp eller ner utifrån dina förutsättningar och önskemål. Letar du samarbeten, vill växa, bygga eller siktar du på en börsintroduktion - och vill veta hur - vi stödjer dig för att du ska lyckas.</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center" style="border-bottom: 1px solid #606367; margin-bottom: 20px; padding-bottom: 20px;">

          <h1 style="margin-top: 25px; margin-bottom: 20px;">Vår mission</h1>
          <p style="font-size: 18px;"><b>Vi digitaliserar framtidens hållbara företag och stimulera nästa generation till stordåd.</b></p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center" style="border-bottom: 1px solid #606367; margin-bottom: 20px; padding-bottom: 20px;">

          <h1 style="margin-top: 15px; margin-bottom: 20px;">Vision</h1>
          
          <!--
          <div class="row" style="margin-bottom: 1rem;">
            <div class="col-lg-7 d-none d-lg-block" style="text-align: left;">
              <p><b>Vi ska medverka till att dagens företag och organisationer blir morgondagens föregångare.</b></p>
              <p>Vi strävar efter att snabbt anpassa oss till kraven på förnyelse och kompetensutveckling som våra kunder och marknaden ställer på en ledande leverantör inom strategisk rådgivning, strategisk kommunikation, förmedling av företag och digitaliseringsprocesser.</p>
            </div>
            <div class="col-lg-7 d-lg-none">
                <p><b>Vi ska medverka till att dagens företag och organisationer blir morgondagens föregångare.</b></p>
                <p>Vi strävar efter att snabbt anpassa oss till kraven på förnyelse och kompetensutveckling som våra kunder och marknaden ställer på en ledande leverantör inom strategisk rådgivning, strategisk kommunikation, förmedling av företag och digitaliseringsprocesser.</p>
              </div>
            <div class="col-lg-5">
              <img src="../../assets/img/photos/vision-mission-nm.jpg" style="width: 100%; border-radius: 10px;">
            </div>
          </div>
          -->

          <div class="row">
            <div class="col-lg-12">
              <p style="font-size: 18px;"><b>Vi ska medverka till att dagens företag och organisationer blir morgondagens digitala föregångare.</b></p>
            </div>
          </div>

          <div class="row" style="margin-bottom: 2rem;">
            <div class="col-lg-12">
              <img src="../../assets/img/photos/vision-mission-nm.jpg" style="width: 60%; border-radius: 10px; margin-top: 15px;">
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <p>Vi strävar efter att snabbt anpassa oss till kraven på förnyelse och kompetensutveckling som våra kunder och marknaden ställer på en ledande leverantör inom strategisk rådgivning, strategisk kommunikation, förmedling av företag och digitaliseringsprocesser.</p>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center" style="border-bottom: 1px solid #606367; margin-bottom: 20px; padding-bottom: 20px;">

          <h1 style="margin-top: 15px; margin-bottom: 20px;">Strategi</h1>
          <p style="font-size: 18px;"><b>Vi sätter människan i fokus vid digitalisering och är navigatörer på kundens resa.</b></p>
          <p>End to End, Good to Great, Ideation to Innovation och Local to Global är fyra strategiska resor som vi erbjuder dig som kund. Med dessa stödjer vi din strategiska förflyttning idag, imorgon och in i framtiden.</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center" style="border-bottom: 1px solid #606367; margin-bottom: 20px; padding-bottom: 20px;">

          <h1 style="margin-top: 15px; margin-bottom: 20px;">Värdegrund</h1>
          <p>Vårt sätt att tänka, vara och agera baserar sig på fyra hörnstenar:</p>
          <ul style="list-style-position: inside; padding: 0;">
            <li>Ödmjukhet</li>
            <li>Öppenhet</li>
            <li>Nyfikenhet</li>
            <li>Framsynthet</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center" style="border-bottom: 1px solid #606367; margin-bottom: 20px; padding-bottom: 20px;">

          <h1 style="margin-top: 15px; margin-bottom: 20px;">Vårt löfte till dig som kund</h1>

          <!--
          <div class="row" style="margin-bottom: 1rem;">
            <div class="col-lg-5">
              <img src="../../assets/img/photos/kundlofte-nextmind.jpg" style="width: 100%; border-radius: 10px;">
            </div>
            <div class="col-lg-7 d-none d-lg-block" style="text-align: left;">
                <p>Vi är genuint intresserade av din framtid och framgång.</p>
            </div>
            <div class="col-lg-7 d-lg-none">
                <p style="margin-top: 20px;">Vi är genuint intresserade av din framtid och framgång.</p>
            </div>
          </div>
        -->

        <div class="row">
          <div class="col-lg-12">
            <p style="font-size: 18px;"><b>Vi är genuint intresserade av digitalsering av din framtid och framgång.</b></p>
          </div>
        </div>

        <div class="row" style="margin-bottom: 2rem;">
          <div class="col-lg-12">
            <img src="../../assets/img/photos/kundlofte-nextmind.jpg" style="width: 60%; border-radius: 10px; margin-top: 15px;">
          </div>
        </div>

        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center" style="border-bottom: 1px solid #606367; margin-bottom: 20px; padding-bottom: 20px;">

          <h1 style="margin-top: 15px; margin-bottom: 20px;">Rekommendationer av kunder</h1>
          <h4 style="margin-bottom: 12px;"><a href="https://www.hudikhus.se/" target="_blank">Hudikhus</a></h4>
          <p><i>Varmt rekommenderar!</i><br />Strategisk rådgivning, "Good to Great"</p>
          <p><i>"Vi har bra erfarenheter av den strategiska resan, Good to Great. Det är kännbart, men nyttigt att göra förflyttningen. Vårt företag står nu på en tydligare, stabilare strategisk plattform och känslan är att vi kommer att få ett rejält lyft, inom såväl ledarskap och medarbetarskap som lönsamhet."</i></p>
          <p>
            Telefonnummer: +46 (0) 70 378 55 92<br />
            Kontaktperson: VD Malin Hagberg
          </p>
          <img src="assets/img/references/hudikhus.png" alt="Hudikhus" />

          <h4 style="margin-top: 30px; margin-bottom: 12px;"><a href="https://haggsbildem.se/" target="_blank">Häggs Bildemontering</a></h4>
          <p><i>Varmt rekommenderar!</i><br />Generationsväxling och strategisk rådgivning, "End to End"</p>
          <p><i>"Att överta ett familjeföretag har sina utmaningar, vi har fått god hjälp att stärka kommunikationen innan övertagandet och därmed har generationsväxlingen underlättats. Vi har även fått bra stöd att förstärka ledarskapet och det strategiska tänkandet, samt fått bra råd vid olika vägval. Kort och gott, vi kan varmt rekommendera fler att ta hjälp med generationsväxlingar av NextMind."</i><br />Mattias Hägg/Caroline Hägg/Niklas Hägg</p>
          <p>
            Telefonnummer: +46 (0) 70 602 62 62<br />
            Kontaktperson: VD Mattias Hägg
          </p>
          <img src="assets/img/references/haggs.png" alt="Haggs" />

          <h4 style="margin-top: 30px; margin-bottom: 12px;"><a href="http://wb-revision.se/" target="_blank">Wennberg & Blennå</a></h4>
          <p><i>Varmt rekommenderar!</i><br />Strategisk rådgivning, "Ideation to Innovation"</p>
          <p><i>"Vi står inför stora utmaningar ur ett digitalt perspektiv, men även utmaningar i ledarskap och medarbetarskap. Att lyfta det strategiska tänkandet och framtidsfokus har haft enormt stor betydelse för mig som äger ett företag i kraftig förändring."</i></p>
          <p>
            Telefonnummer: +46 (0) 70 533 90 05<br />
            Kontaktperson: VD Mats Blennå
          </p>
          <img src="assets/img/references/WB.png" alt="WandB" />

        </div>
      </div>

      <h1 style="margin-top: 35px; margin-bottom: 20px; font-size: 2rem;">Tyck till om NextMind och VOCEAN</h1>
      
      <div class="row" style="margin-bottom: 2rem;">
        <div class="col-lg-12">
          <a href="http://tycktill.nextmind.com" target="_blank" style="z-index: 999;"><img src="../../assets/img/about-qr-code.png" style="width: 25%; border-radius: 10px; margin-top: 15px;"></a>
        </div>
      </div>
      <h1 style="margin-bottom: 110px; font-size: 2rem;">Tack för ditt bidrag!</h1>

    </div>
  </section>

  <!-- Footer -->
  <footer>
    <div class="container-fluid">
      <p class="m-0 text-center text-white">&copy;2019 NextMind® AB. ALL RIGHTS RESERVED.</p>
    </div>
    <!-- /.container -->
  </footer>
</div>
