<canvas id="braincanvas" style="z-index:20;position:absolute;" height="905">
  Your Browser Don't Support Canvas, Please Download Chrome
</canvas>
<canvas id="stroke-page-canvas" class="d-none d-lg-block" style="z-index:20;position:absolute;top:905px;" height="3400">
  Your Browser Don't Support Canvas, Please Download Chrome
</canvas>
<div id="page-top">
  <div id="videoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" style="color:#FFF;" class="close js-stop" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body" id="vimeo-iframe-container">
        </div>
      </div>
    </div>
  </div>

  <svg width="1440px" height="4151px" viewBox="0 0 1440 4151" class="d-none d-lg-block svgresponsive" style="position:absolute;left:0;top:0;bottom:0;right:0;margin-left:auto;margin-right:auto;z-index:10;width:100%;">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="body-bg-linearGradient-1">
        <stop stop-color="#A5EBED" offset="0%"></stop>
        <stop stop-color="#0F98FF" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="body-bg-linearGradient-2">
        <stop stop-color="#00B1DD" offset="0%"></stop>
        <stop stop-color="#B6519E" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="3.33824936%" x2="50%" y2="100%" id="body-bg-linearGradient-3">
        <stop stop-color="#B6519E" offset="0%"></stop>
        <stop stop-color="#FFCC00" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="2.93019495%" y1="69.887509%" x2="61.8789287%" y2="16.7923306%" id="body-bg-linearGradient-4">
        <stop stop-color="#61BEC3" offset="0%"></stop>
        <stop stop-color="#FFCC00" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
      <g id="Desktop-HD">
        <g id="CONNECTIONS" transform="translate(-265.000000, -18.000000)">
          <path d="M154.5,905.5 L831.5,1096.5" id="Line" stroke="url(#body-bg-linearGradient-1)" stroke-width="0.5"></path>
          <path d="M1071.5,971.5 L1971.5,1315.5" id="Line" stroke="url(#body-bg-linearGradient-1)" stroke-width="0.5"></path>
          <path d="M211.5,2377.5 L755.5,1755.5" id="Line" stroke="url(#body-bg-linearGradient-2)" stroke-width="0.5"></path>
          <path d="M167.5,1235.5 L810.5,1598.5" id="Line" stroke="url(#body-bg-linearGradient-2)" stroke-width="0.5"></path>
          <path d="M1254.5,2012.5 L1790.5,1447.5" id="Line" stroke="#B6519E" stroke-width="0.5"></path>
          <path d="M955.5,2873.5 L1703.5,2524.5" id="Line" stroke="url(#body-bg-linearGradient-3)" stroke-width="0.5"></path>
          <path d="M1296.5,371.5 L1768.5,283.5" id="Line" stroke="#6CD3D6" stroke-width="0.5"></path>
          <path d="M785.5,3368.5 L0.5,3493.5" id="Line" stroke="#FFCC00" stroke-width="0.5"></path>
          <!--<path d="M939.5,4167.5 L1813.5,4061.5" id="Line" stroke="url(#body-bg-linearGradient-4)"></path>-->
          <path d="M1483.5,135.5 L1536.5,0.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M1807.5,229.5 L1495.5,243.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M1574.5,651.5 L1765.5,663.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M832.5,1097.5 L776.5,726.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M244.5,165.5 L403.5,230.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M334.5,336.5 L259.5,176.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M252.5,538.5 L491.5,360.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M242.5,911.5 L488.5,557.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M243.5,596.5 L488.5,557.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M520.5,211.5 L399.5,1.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1"></path>
          <path d="M1237.5,559.5 L1804.5,903.5" id="Line" stroke="#6CD3D6" stroke-width="0.5"></path>
          <path d="M720.5,316.5 L196.5,219.5" id="Line" stroke="#6CD3D6" stroke-width="0.5"></path>
        </g>
      </g>
    </g>
  </svg>
  <!-- Navigation -->
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav" style="">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" routerLink="/"><img src="assets/img/logo_white.png" class="logo" alt="Logotype" /></a>
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav">
          <!--
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#about">Overview</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#customers">Collaboration</a>
        </li>
        -->
        
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/services">{{ 'front.services' | translate }}</a>
          </li>
          <li class="nav-item">
              <a class="nav-link js-scroll-trigger" routerLink="/vocean">Lösning</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/education">Utbildning</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/about">{{ 'front.about' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/blog">{{ 'front.blog' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger login" routerLink="/contact">{{ 'front.contact' | translate }} <img src="assets/img/ico_login.png" alt="Contact" /></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <section id="top" style="height:905px;padding:0;width:100%;">
    <div class="svg-brain-container">
      <svg viewBox="0 0 375 578" id="brain-mobile" style="margin-top:-80px;" class="d-lg-none">
        <defs>
          <linearGradient x1="0%" y1="0%" x2="60.6287339%" y2="77.0497533%" id="brain-mobile-linearGradient-1">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="110.194886%" y1="0%" x2="50%" y2="104.255642%" id="brain-mobile-linearGradient-2">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="100%" y1="100%" x2="19.2234848%" y2="-19.921875%" id="brain-mobile-linearGradient-3">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="96.8456167%" y1="100%" x2="9.64290653%" y2="32.3283451%" id="brain-mobile-linearGradient-4">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="100%" y1="100%" x2="50%" y2="-9.34151786%" id="brain-mobile-linearGradient-5">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="8.58087225%" y1="100%" x2="110.194883%" y2="19.4029018%" id="brain-mobile-linearGradient-6">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="8.58087225%" y1="100%" x2="110.194883%" y2="19.4029018%" id="brain-mobile-linearGradient-7">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#B6D6E7" offset="73.4952829%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="100%" y1="0%" x2="-4.56960867%" y2="0%" id="brain-mobile-linearGradient-8">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <filter x="-41.9%" y="-18.3%" width="183.8%" height="136.6%" filterUnits="objectBoundingBox" id="brain-mobile-filter-9">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-252.6%" y="-254.8%" width="605.2%" height="609.5%" filterUnits="objectBoundingBox" id="brain-mobile-filter-10">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-252.6%" y="-254.8%" width="605.2%" height="609.5%" filterUnits="objectBoundingBox" id="brain-mobile-filter-11">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-113.0%" y="-114.0%" width="326.0%" height="328.0%" filterUnits="objectBoundingBox" id="brain-mobile-filter-12">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-159.0%" y="-160.4%" width="418.1%" height="420.8%" filterUnits="objectBoundingBox" id="brain-mobile-filter-13">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-41.9%" y="-20.5%" width="183.9%" height="140.9%" filterUnits="objectBoundingBox" id="brain-mobile-glow-filter-1">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-41.9%" y="-20.5%" width="183.9%" height="140.9%" filterUnits="objectBoundingBox" id="brain-mobile-glow-filter-2">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-41.9%" y="-20.5%" width="183.9%" height="140.9%" filterUnits="objectBoundingBox" id="brain-mobile-glow-filter-3">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-41.9%" y="-34.7%" width="183.9%" height="169.4%" filterUnits="objectBoundingBox" id="brain-mobile-glow-filter-4">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-41.9%" y="-34.7%" width="183.9%" height="169.4%" filterUnits="objectBoundingBox" id="brain-mobile-glow-filter-5">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-41.9%" y="-19.5%" width="183.9%" height="138.9%" filterUnits="objectBoundingBox" id="brain-mobile-glow-filter-6">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-41.9%" y="-19.5%" width="183.9%" height="138.9%" filterUnits="objectBoundingBox" id="brain-mobile-glow-filter-7">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-56.8%" y="-22.9%" width="213.7%" height="145.9%" filterUnits="objectBoundingBox" id="brain-mobile-glow-filter-8">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-50.8%" y="-19.5%" width="201.6%" height="138.9%" filterUnits="objectBoundingBox" id="brain-mobile-glow-filter-9">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="MOBILE-VERSION">
            <g id="GLOW" transform="translate(-12.000000, 90.000000)">
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-glow-filter-1)" transform="translate(215.550458, 134.380134) rotate(235.000000) translate(-215.550458, -134.380134) " points="231.256627 60.7129661 251.500133 66.2466501 181.694938 207.355591 179.600782 208.047301"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-glow-filter-2)" transform="translate(215.550458, 134.380134) rotate(235.000000) translate(-215.550458, -134.380134) " points="231.256627 60.7129661 251.500133 66.2466501 181.694938 207.355591 179.600782 208.047301"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-glow-filter-3)" transform="translate(347.173568, 115.012240) rotate(235.000000) translate(-347.173568, -115.012240) " points="362.879737 41.3450723 383.123244 46.8787563 313.318049 187.987697 311.223893 188.679407"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-glow-filter-4)" transform="translate(318.872148, 124.119780) rotate(231.000000) translate(-318.872148, -124.119780) " points="334.578317 80.6619513 354.821824 83.9263891 285.016629 167.169553 282.922473 167.577608"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-glow-filter-5)" transform="translate(318.872148, 124.119780) rotate(231.000000) translate(-318.872148, -124.119780) " points="334.578317 80.6619513 354.821824 83.9263891 285.016629 167.169553 282.922473 167.577608"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-glow-filter-6)" transform="translate(109.222483, 218.382982) rotate(-344.000000) translate(-109.222483, -218.382982) " points="124.928652 140.911406 145.172158 146.730868 75.3669633 295.127124 73.2728074 295.854557"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-glow-filter-7)" transform="translate(109.222483, 218.382982) rotate(-344.000000) translate(-109.222483, -218.382982) " points="124.928652 140.911406 145.172158 146.730868 75.3669633 295.127124 73.2728074 295.854557"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-glow-filter-8)" transform="translate(89.657040, 121.070130) rotate(-261.000000) translate(-89.657040, -121.070130) " points="110.929134 55.311947 116.192136 57.2872731 65.2160994 186.10088 63.1219436 186.828313"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-glow-filter-9)" transform="translate(81.178360, 115.190558) rotate(-261.000000) translate(-81.178360, -115.190558) " points="103.143957 37.7189824 110.868607 42.4061304 53.5822684 191.9347 51.4881126 192.662133"></polygon>
            </g>
            <g id="BRAIN" transform="translate(-278.000000, 22.000000)">
              <path d="M530.60161,446.423166 L467.725844,542.011059" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
              <polygon id="Triangle" fill="#70DCDF" opacity="0.511605525" points="467.30719 530.582072 491.828221 546.575332 454.80138 555.518044"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.0927875906" points="342.157162 172.473807 419.870399 221.653085 377.254601 155.157159"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="373.761503 154.464494 344.419479 229.27241 343.022239 174.551804"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="461.787577 250.052386 515.581288 287.456345 485.540644 237.5844"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.862941576" points="516.279908 69.2665891 574.265337 105.977881 482.746166 78.2712456"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.103430707" points="652.345633 73.1195431 627.94989 145.854873 615.127763 130.58646"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.103430707" points="289.658342 58.6425554 344.016954 128.032255 344.016954 102.51861"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="649.716258 162.776484 622.470092 195.331781 550.165689 196.87878"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="576.535851 196.87878 615.425223 130.547227 550.165689 196.87878"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.616677989" points="595.086113 167.909247 569.398196 128.177011 599.397906 105.543612"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.147984601" points="540.032975 75.5005821 375.857362 85.1979045 442.226227 114.982538"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.274513134" points="505.287565 101.85706 425.893264 88.7315829 442.226227 114.982538"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="649.716258 162.776484 622.470092 195.331781 627.360429 147.537835"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="447.116564 69.9592549 418.47316 79.6565774 442.226227 114.982538"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="519.68909 181.245771 483.674702 162.152138 521.266442 215.646373"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="519.68909 181.245771 483.674702 162.152138 521.266442 215.646373"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.64696558" points="482.553772 121.834789 484.19662 161.991824 519.897175 106.517674"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="536.393877 130.171131 440.8631 114.354809 511.393664 144.991746"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.713088768" points="607.105918 311.742942 574.77361 248.493888 592.420579 293.772862"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="482.426875 162.02294 484.297592 201.54954 424.324099 219.226049"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="542.314405 224.404132 549.81365 241.04773 615.298325 236.537961"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.59227808" points="621.751005 194.415893 551.050562 241.571288 588.850387 231.930109"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="461.787577 250.052386 515.581288 287.456345 485.540644 237.5844"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.480638587" points="445.719325 258.364377 515.581288 287.456345 549.81365 242.433062"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.807971014" points="506.499233 311.699651 541.430215 314.034692 544.767761 290.06737"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="474.823928 291.044814 466.844382 267.578051 486.053011 238.264214"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="641.208653 227.295877 671.21041 274.351836 672.912613 215.66396"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="641.208653 227.295877 624.966429 261.375174 672.912613 215.66396"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="641.208653 227.295877 624.966429 261.375174 672.912613 215.66396"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.768115942" points="657.952328 221.172142 641.208653 302.931745 672.912613 215.66396"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="367.473926 315.16298 376.555982 252.82305 409.391104 284.685681"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="392.686317 322.803274 359.557829 275.162201 409.923938 283.409255"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.668591486" points="422.133408 332.715702 449.51534 311.508221 445.160566 259.086806"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.544157609" points="507.896472 370.576251 551.21089 377.156577 632.250767 346.332945"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="590.696544 368.523282 551.21089 377.156577 539.483085 330.707377"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="538.911363 331.291137 551.21089 377.50291 606.40184 312.392317"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="544.938322 289.319237 592.855169 293.027435 607.65035 256.680063"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.41870471" points="633.280958 263.566136 559.809915 291.159131 607.65035 256.680063"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="535.841258 397.590221 530.252301 446.769499 591.032209 368.498254"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.0283627717" points="695.126534 128.14319 652.510736 73.4225844 736.345092 95.5878929"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.851505888" points="373.761503 154.464494 344.419479 229.27241 343.022239 174.551804"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.605921649" points="292.977468 145.864343 314.764303 247.891864 307.217301 170.695468"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(347.838259, 106.199710) rotate(-46.000000) translate(-347.838259, -106.199710) " points="341.691351 93.4207148 366.780942 109.81292 328.895575 118.978706"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(347.838259, 106.199710) rotate(-46.000000) translate(-347.838259, -106.199710) " points="341.691351 93.4207148 366.780942 109.81292 328.895575 118.978706"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(347.838259, 106.199710) rotate(-46.000000) translate(-347.838259, -106.199710) " points="341.691351 93.4207148 366.780942 109.81292 328.895575 118.978706"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(411.550372, 115.442065) rotate(-46.000000) translate(-411.550372, -115.442065) " points="396.641148 105.290152 438.318189 113.04063 384.782556 125.593979"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(315.036389, 194.676793) rotate(-46.000000) translate(-315.036389, -194.676793) " points="302.482852 185.958921 349.225052 200.834684 280.847726 203.394665"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(296.533656, 280.578814) rotate(-46.000000) translate(-296.533656, -280.578814) " points="307.236909 264.189013 328.791824 296.968615 264.275487 289.904828"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(382.249322, 225.918662) rotate(-46.000000) translate(-382.249322, -225.918662) " points="381.606649 202.362286 411.020079 250.468199 353.478566 201.369126"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(294.621449, 174.133748) rotate(-46.000000) translate(-294.621449, -174.133748) " points="314.277492 153.493691 306.179653 180.086022 274.965406 157.901417 280.583862 194.773804"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(362.936729, 165.764711) rotate(-46.000000) translate(-362.936729, -165.764711) " points="359.32895 140.807625 341.237334 158.129606 384.636124 190.721797"></polygon>
              <path d="M467.725844,542.011059 L550.16296,516.382421" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
              <path d="M421.267638,222.345751 L327.303298,351.527939" id="Line" stroke="#0079B9" stroke-width="2" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L485.889954,237.930733" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L515.231979,287.110012" id="Line" stroke="url(#brain-mobile-linearGradient-1)" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L409.740414,283.646682" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L444.671396,258.71071" id="Line" stroke="#FFFFFF" stroke-linecap="square"></path>
              <ellipse id="Oval-3" fill="#FFFFFF" cx="342.672929" cy="150.654831" rx="1.74654908" ry="1.73166473"></ellipse>
              <ellipse id="Oval-3" fill="#FFFFFF" cx="343.371549" cy="174.205471" rx="1.74654908" ry="1.73166473"></ellipse>
              <ellipse id="Oval-3" fill="#FFFFFF" cx="293.070936" cy="145.80617" rx="1.74654908" ry="1.73166473"></ellipse>
              <ellipse id="Oval-3" fill="#FFFFFF" cx="307.043328" cy="170.049476" rx="1.74654908" ry="1.73166473"></ellipse>
              <ellipse id="Oval-3" fill="#FFFFFF" cx="300.057132" cy="198.448778" rx="1.74654908" ry="1.73166473"></ellipse>
              <path d="M420.918328,223.38475 L376.905291,253.169383" id="Line" stroke="url(#brain-mobile-linearGradient-2)" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L398.5625,165.200815" id="Line" stroke="url(#brain-mobile-linearGradient-3)" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L342.672929,173.512806" id="Line" stroke="url(#brain-mobile-linearGradient-4)" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L299.358512,198.448778" id="Line" stroke="url(#brain-mobile-linearGradient-5)" stroke-linecap="square"></path>
              <path d="M397.86388,165.893481 L342.672929,174.205471" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M342.672929,150.654831 L343.371549,174.898137" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M342.672929,150.654831 L307.043328,170.049476" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M342.672929,150.654831 L293.070936,145.80617" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M342.672929,150.654831 L377.603911,155.503492" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M343.371549,174.205471 L377.603911,155.503492" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M342.672929,150.654831 L342.672929,102.860885" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M342.672929,150.654831 L342.672929,102.860885" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M293.070936,145.80617 L307.043328,170.049476" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M300.057132,198.448778 L307.043328,169.35681" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M300.057132,200.526775 L293.070936,145.80617" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M342.672929,102.860885 L378.302531,156.196158" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M342.672929,102.860885 L375.508052,84.8515716" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M342.672929,102.860885 L293.070936,145.80617" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M268.619248,176.976135 L293.070936,145.80617" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M269.317868,176.976135 L300.057132,199.141444" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M269.317868,219.92142 L300.057132,197.756112" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M270.016488,219.92142 L292.372316,262.17404" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M346.864647,291.266007 L292.372316,262.17404" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M346.864647,291.266007 L367.124617,316.201979" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M346.864647,291.266007 L367.124617,316.201979" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M392.973543,323.128638 L367.124617,316.201979" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M392.973543,323.128638 L422.315567,333.518626" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M468.424463,324.51397 L422.315567,333.518626" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M468.424463,324.51397 L494.972009,352.913271" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M513.834739,373.000582 L494.972009,352.913271" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M513.834739,373.000582 L536.889187,396.551222" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M534.793328,418.023865 L536.889187,396.551222" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M534.793328,418.023865 L529.902991,447.115832" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M534.793328,418.023865 L591.381518,368.844587" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.16296,377.156577 L536.889187,396.551222" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M592.080138,368.151921 L536.889187,396.551222" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.16296,377.156577 L591.381518,368.844587" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M539.683666,330.747963 L591.381518,368.844587" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M539.683666,330.747963 L631.901457,345.986612" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.86158,377.156577 L539.683666,330.747963" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M513.13612,372.307916 L539.683666,331.440629" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M495.670629,352.220605 L540.382285,330.747963" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M641.682132,303.041327 L540.382285,330.747963" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M529.902991,447.115832 L591.381518,368.844587" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M631.202837,346.679278 L591.381518,368.844587" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M631.901457,346.679278 L641.682132,302.348661" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M671.024156,273.94936 L641.682132,302.348661" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M649.366948,162.430151 L673.120015,215.765425" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M649.366948,162.430151 L628.408359,146.498836" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M615.134586,129.182189 L628.408359,146.498836" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M615.833206,129.874854 L599.066334,105.631548" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M567.628451,89.7002328 L599.066334,105.631548" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M567.628451,89.7002328 L540.382285,75.1542491" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M515.930598,70.3055879 L540.382285,75.1542491" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M515.930598,69.612922 L485.191334,77.9249127" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M622.120782,194.292782 L574.614647,247.628056" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M670.325537,273.94936 L574.614647,247.628056" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M622.120782,194.292782 L550.86158,197.063446" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M622.120782,193.600116 L650.065567,162.430151" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M622.120782,194.292782 L673.120015,215.765425" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M622.120782,194.292782 L671.024156,273.94936" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M420.219709,79.3102445 L375.508052,84.8515716" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M419.521089,80.0029104 L441.876917,115.328871" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M432.794862,165.200815 L441.178298,116.021537" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M432.794862,165.200815 L483.095475,162.430151" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M483.794095,77.2322468 L483.095475,162.430151" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M484.492715,201.912107 L483.095475,162.430151" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M484.492715,201.912107 L550.16296,197.063446" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M484.492715,201.912107 L485.889954,237.930733" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M484.492715,201.912107 L485.889954,237.930733" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M514.533359,286.417346 L485.889954,237.930733" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M444.671396,259.403376 L485.889954,237.930733" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.16296,242.779395 L485.191334,237.930733" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.16296,242.779395 L573.916028,247.628056" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.86158,197.063446 L573.916028,247.628056" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M592.080138,294.036671 L573.916028,247.628056" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M592.080138,294.036671 L641.682132,302.348661" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M592.080138,294.036671 L544.574003,289.880675" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.86158,197.063446 L485.889954,237.930733" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.86158,241.394063 L544.574003,289.880675" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M575.313267,246.93539 L544.574003,289.880675" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M486.588574,237.930733 L544.574003,289.880675" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M515.231979,287.110012 L544.574003,289.880675" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M539.683666,331.440629 L544.574003,289.880675" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M539.683666,331.440629 L592.778758,293.344005" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M631.901457,345.293946 L592.778758,293.344005" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M671.722776,273.256694 L592.778758,293.344005" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.86158,197.063446 L483.095475,162.430151" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M540.382285,74.4615832 L483.095475,162.430151" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M569.72431,89.0075669 L483.095475,162.430151" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M599.764954,105.631548 L483.095475,162.430151" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M599.066334,104.938882 L575.313267,106.324214" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M569.02569,89.7002328 L575.313267,106.324214" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M483.095475,162.430151 L575.313267,106.324214" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M550.86158,197.063446 L575.313267,106.324214" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M599.764954,105.631548 L550.86158,197.756112" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M616.531825,129.874854 L550.86158,197.756112" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M627.709739,145.80617 L550.86158,197.756112" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M516.629218,69.612922 L483.095475,162.430151" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M446.068635,69.612922 L441.178298,116.021537" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M420.219709,79.3102445 L446.767255,69.612922" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M483.794095,77.9249127 L446.767255,69.612922" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M398.5625,165.893481 L432.794862,165.200815" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M398.5625,165.200815 L441.876917,114.636205" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M441.178298,115.328871 L377.603911,155.503492" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M441.178298,115.328871 L376.905291,85.5442375" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M375.508052,84.8515716 L377.603911,155.503492" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M432.794862,164.508149 L377.603911,155.503492" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M344.768788,228.926077 L343.371549,174.898137" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M344.768788,228.926077 L346.864647,291.958673" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M344.768788,228.926077 L300.755752,199.141444" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M344.768788,228.926077 L376.905291,253.862049" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M409.740414,283.646682 L376.905291,253.862049" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M346.166028,291.266007 L376.206672,253.169383" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M346.166028,291.266007 L409.740414,283.646682" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M367.124617,315.509313 L409.740414,283.646682" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M392.274923,323.128638 L409.740414,283.646682" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M422.315567,333.518626 L409.740414,283.646682" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M468.424463,324.51397 L409.740414,283.646682" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M444.671396,258.71071 L409.740414,283.646682" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M444.671396,258.71071 L515.231979,287.110012" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M444.671396,258.71071 L467.725844,323.821304" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M467.725844,323.821304 L515.231979,287.110012" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M539.683666,331.440629 L515.231979,287.110012" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M494.27339,352.913271 L515.231979,287.110012" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M292.372316,262.17404 L376.206672,253.169383" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M292.372316,262.17404 L345.467408,228.233411" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M292.372316,262.17404 L297.262653,224.077416" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M300.755752,197.756112 L297.262653,224.077416" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M270.016488,219.92142 L297.262653,224.077416" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M344.768788,228.926077 L297.262653,224.077416" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M299.358512,198.448778 L343.371549,174.205471" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M307.043328,170.049476 L343.371549,174.205471" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L432.794862,163.815483" id="Line" stroke="#FFFFFF" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L483.794095,77.2322468" id="Line" stroke="url(#brain-mobile-linearGradient-6)" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L482.396856,162.430151" id="Line" stroke="url(#brain-mobile-linearGradient-7)" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L484.492715,201.912107" id="Line" stroke="#FFFFFF" stroke-linecap="square"></path>
              <path d="M420.918328,221.999418 L550.86158,197.063446" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
              <path d="M420.918328,223.38475 L344.070169,228.926077" id="Line" stroke="url(#brain-mobile-linearGradient-8)" stroke-linecap="square"></path>
              <path d="M586.985352,9.95996094 L539.445169,74.9202561" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
              <path d="M496.369248,43.2916182 L483.794095,77.9249127" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M496.369248,43.2916182 L516.629218,69.612922" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M652.161426,73.0762515 L696.174463,128.489523" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M652.161426,73.0762515 L736.694402,95.24156" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M763.940567,589.805006 L554.354678,517.075087" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M725.516488,352.913271 L641.682132,303.733993" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M629.106979,399.321886 L726.215107,352.220605" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M629.106979,399.321886 L590.682899,369.537253" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M629.106979,399.321886 L631.901457,347.371944" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M629.106979,399.321886 L530.60161,446.423166" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M695.475844,127.796857 L627.709739,145.80617" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M652.161426,73.0762515 L615.833206,129.874854" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M289.577837,58.5302678 L341.97431,102.168219" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M289.577837,58.5302678 L216.921396,109.094878" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M214.126917,303.733993 L292.372316,261.481374" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M214.126917,303.733993 L279.797163,311.353318" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M346.864647,289.880675 L279.797163,311.353318" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M293.070936,262.17404 L279.797163,311.353318" id="Line-2" stroke="#0BB8FF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M292.372316,145.113504 L216.921396,109.094878" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M130.991181,103.553551 L289.577837,57.8376019" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M289.577837,58.5302678 L375.508052,84.8515716" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <ellipse id="Oval-4" fill="#0079B9" cx="420.569018" cy="222.345751" rx="4.19171779" ry="4.15599534"></ellipse>
              <polygon id="Rectangle-10" fill="#FFFFFF" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-mobile-filter-9)" transform="translate(448.378695, 149.109271) rotate(183.000000) translate(-448.378695, -149.109271) " points="464.097636 66.68203 484.357606 72.8737476 414.495643 230.762547 412.399784 231.536512"></polygon>
              <ellipse id="Oval-5" fill="#FFFFFF" cx="420.219709" cy="222.692084" rx="5.93826687" ry="5.88766007"></ellipse>
              <path d="M575.313267,106.324214 L540.382285,154.118161" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M483.095475,162.430151 L540.382285,154.118161" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M483.095475,162.430151 L540.382285,154.118161" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M439.781058,114.636205 L395.768021,120.177532" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M418.822469,80.0029104 L395.768021,120.177532" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M378.302531,153.425495 L395.768021,120.177532" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M342.672929,102.860885 L395.768021,120.177532" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M375.508052,84.8515716 L395.768021,120.177532" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M403.452837,158.966822 L395.768021,120.177532" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M403.452837,158.966822 L416.027991,180.439464" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M344.768788,206.760768 L416.027991,180.439464" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M344.070169,207.453434 L330.796396,269.793364" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M297.262653,224.077416 L330.796396,269.793364" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M293.070936,262.17404 L330.796396,269.793364" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M346.864647,290.573341 L330.796396,269.793364" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M345.467408,256.632712 L330.796396,269.793364" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M344.768788,228.926077 L330.796396,269.793364" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M375.508052,253.862049 L330.796396,269.793364" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M409.740414,283.646682 L330.796396,269.793364" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M433.493482,163.815483 L416.72661,180.439464" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M483.095475,162.430151 L416.72661,180.439464" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M484.492715,201.219441 L416.027991,180.439464" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M421.616948,221.999418 L416.72661,181.13213" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M399.26112,165.893481 L416.72661,181.13213" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M433.493482,164.508149 L395.768021,120.177532" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M543.176764,174.898137 L540.382285,154.810827" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M543.176764,174.898137 L550.86158,197.756112" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M543.875383,174.898137 L483.794095,162.430151" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M501.958206,185.980792 L550.86158,197.063446" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M501.958206,185.980792 L483.095475,162.430151" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M501.958206,185.980792 L543.875383,174.898137" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M501.958206,185.980792 L484.492715,201.912107" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M541.779525,224.077416 L484.492715,201.912107" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M542.478144,224.770081 L615.134586,236.545402" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M574.614647,247.628056 L615.134586,236.545402" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M672.421396,216.458091 L615.134586,236.545402" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M622.120782,194.985448 L615.134586,236.545402" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M650.764187,192.214785 L615.134586,236.545402" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M651.5,192.5 L622.5,194.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M650.764187,192.214785 L670.325537,273.94936" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M650.764187,192.214785 L672.421396,215.072759" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M650.764187,192.214785 L649.366948,163.122817" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M650.764187,192.214785 L672.421396,215.072759" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M669.626917,273.256694 L615.134586,236.545402" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M592.778758,293.344005 L615.134586,236.545402" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M641.682132,302.348661 L615.134586,236.545402" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M541.779525,224.077416 L486.588574,237.930733" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M474.71204,291.266007 L485.889954,238.623399" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M474.71204,291.266007 L513.834739,287.110012" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M474.71204,291.266007 L445.370015,258.71071" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M474.71204,291.266007 L467.725844,323.821304" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M474.71204,291.266007 L461.438267,250.398719" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M474.71204,291.266007 L539.683666,331.440629" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M474.71204,291.266007 L409.740414,283.646682" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M474.71204,291.266007 L422.315567,332.82596" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M541.779525,224.077416 L483.794095,209.531432" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M541.779525,224.077416 L550.86158,242.086729" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M542.478144,224.077416 L550.86158,197.756112" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="543.685037 175.236353 501.602755 185.764334 550.848617 197.252847"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="543.685037 175.236353 561.912596 154.020755 550.848617 197.252847"></polygon>
              <path d="M542.478144,224.770081 L573.916028,247.628056" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <g id="CIRCLE-GLOW" transform="translate(125.751534, 0.000000)" fill="#024A79">
                <ellipse id="Oval-6" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-mobile-filter-10)" cx="164.524923" cy="57.8376019" rx="5.93826687" ry="5.88766007"></ellipse>
                <ellipse id="Oval-6" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-mobile-filter-11)" cx="343.371549" cy="321.05064" rx="5.93826687" ry="5.88766007"></ellipse>
                <ellipse id="Oval-6" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-mobile-filter-12)" cx="201.202454" cy="351.874272" rx="13.273773" ry="13.1606519"></ellipse>
                <ellipse id="Oval-6" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-mobile-filter-13)" cx="459.431365" cy="9.35098952" rx="9.43136503" ry="9.35098952"></ellipse>
              </g>
              <g id="DOTS" transform="translate(0.000000, 7.311991)" fill="#FFFFFF">
                <ellipse id="Oval-3" cx="629.805598" cy="391.317229" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="279.797163" cy="304.041327" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="289.577837" cy="51.2182771" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="652.161426" cy="65.7642608" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="496.369248" cy="35.9796275" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="398.5625" cy="158.58149" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="403.452837" cy="151.654831" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="483.095475" cy="155.118161" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="575.313267" cy="99.0122235" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="540.032975" cy="147.152503" rx="1" ry="1"></ellipse>
                <ellipse id="Oval-3" cx="501.958206" cy="178.668801" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="395.768021" cy="112.865541" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="615.134586" cy="229.233411" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="474.71204" cy="283.954016" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="416.377301" cy="173.473807" rx="1" ry="1"></ellipse>
                <ellipse id="Oval-3" cx="330.796396" cy="262.481374" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="650.764187" cy="184.902794" rx="1.04792945" ry="1.03899884"></ellipse>
                <ellipse id="Oval-3" cx="542.128834" cy="217.111758" rx="1" ry="1"></ellipse>
                <ellipse id="Oval-3" cx="543.526074" cy="167.93248" rx="1" ry="1"></ellipse>
                <ellipse id="Oval-3" cx="574.614647" cy="241.008731" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="641.682132" cy="295.729336" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="606.75115" cy="304.733993" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="344.768788" cy="199.448778" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="544.574003" cy="282.568685" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="550.86158" cy="234.774738" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="441.178298" cy="107.324214" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="484.492715" cy="194.600116" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="484.492715" cy="202.219441" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="432.794862" cy="157.196158" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="622.120782" cy="186.980792" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="550.86158" cy="189.751455" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="615.833206" cy="122.562864" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="627.709739" cy="138.494179" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="649.366948" cy="155.810827" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="599.066334" cy="98.3195576" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="516.629218" cy="62.3009313" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="539.683666" cy="67.8422584" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="569.02569" cy="82.3882421" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="418.822469" cy="71.9982538" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="446.767255" cy="62.3009313" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="483.794095" cy="70.612922" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="377.603911" cy="147.498836" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="342.672929" cy="95.5488941" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="375.508052" cy="77.5395809" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="445.370015" cy="251.398719" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="461.438267" cy="243.086729" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="514.533359" cy="279.798021" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="485.889954" cy="230.618743" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="534.793328" cy="410.019208" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="530.60161" cy="439.111176" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="590.682899" cy="361.532596" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="631.901457" cy="338.674622" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="592.778758" cy="286.032014" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="513.13612" cy="364.30326" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="539.683666" cy="324.128638" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="536.889187" cy="389.239232" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="550.86158" cy="369.151921" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="467.725844" cy="316.509313" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="494.972009" cy="344.908615" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="422.315567" cy="325.51397" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="392.973543" cy="315.816647" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="367.124617" cy="308.197322" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="345.467408" cy="249.320722" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="409.740414" cy="276.334692" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="376.206672" cy="245.857392" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="344.768788" cy="221.614086" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="346.864647" cy="283.26135" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="297.262653" cy="216.765425" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval-3" cx="292.372316" cy="254.169383" rx="1.74654908" ry="1.73166473"></ellipse>
                <ellipse id="Oval" cx="327.652607" cy="344.562282" rx="2.0958589" ry="2.07799767"></ellipse>
                <ellipse id="Oval" cx="467.725844" cy="534.699069" rx="2.44516871" ry="2.42433062"></ellipse>
                <ellipse id="Oval" cx="553.656058" cy="508.377765" rx="2.44516871" ry="2.42433062"></ellipse>
                <ellipse id="Oval" cx="587.095859" cy="2.07799767" rx="2.0958589" ry="2.07799767"></ellipse>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg viewBox="0 0 1305 905" id="brain" class="d-none d-lg-block">
        <defs>
          <linearGradient x1="0%" y1="0%" x2="60.6287339%" y2="77.0497533%" id="brain-desktop-linearGradient-1">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="110.194886%" y1="0%" x2="50%" y2="104.255642%" id="brain-desktop-linearGradient-2">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="100%" y1="100%" x2="19.2234848%" y2="-19.921875%" id="brain-desktop-linearGradient-3">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="96.8456167%" y1="100%" x2="9.64290653%" y2="32.3283451%" id="brain-desktop-linearGradient-4">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="100%" y1="100%" x2="50%" y2="-9.34151786%" id="brain-desktop-linearGradient-5">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="8.58087225%" y1="100%" x2="110.194883%" y2="19.4029018%" id="brain-desktop-linearGradient-6">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="8.58087225%" y1="100%" x2="110.194883%" y2="19.4029018%" id="brain-desktop-linearGradient-7">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#B6D6E7" offset="73.4952829%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="100%" y1="0%" x2="-4.56960867%" y2="0%" id="brain-desktop-linearGradient-8">
            <stop stop-color="#0079B9" offset="0%"></stop>
            <stop stop-color="#F8F8F8" offset="100%"></stop>
          </linearGradient>
          <filter x="-29.3%" y="-12.7%" width="158.6%" height="125.3%" filterUnits="objectBoundingBox" id="brain-desktop-filter-9">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <polygon id="path-10" points="94.8419505 0.457096136 0 0.457096136 0 14.5051841 0 28.553272 94.8419505 28.553272 94.8419505 0.457096136"></polygon>
          <filter x="-176.5%" y="-176.5%" width="452.9%" height="452.9%" filterUnits="objectBoundingBox" id="brain-desktop-filter-12">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-176.5%" y="-176.5%" width="452.9%" height="452.9%" filterUnits="objectBoundingBox" id="brain-desktop-filter-13">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-176.5%" y="-176.5%" width="452.9%" height="452.9%" filterUnits="objectBoundingBox" id="brain-desktop-filter-14">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-176.5%" y="-176.5%" width="452.9%" height="452.9%" filterUnits="objectBoundingBox" id="brain-desktop-filter-15">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-176.5%" y="-176.5%" width="452.9%" height="452.9%" filterUnits="objectBoundingBox" id="brain-desktop-filter-16">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-176.5%" y="-176.5%" width="452.9%" height="452.9%" filterUnits="objectBoundingBox" id="brain-desktop-filter-17">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-250.0%" y="-250.0%" width="600.0%" height="600.0%" filterUnits="objectBoundingBox" id="brain-desktop-filter-18">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-176.5%" y="-176.5%" width="452.9%" height="452.9%" filterUnits="objectBoundingBox" id="brain-desktop-filter-19">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-78.9%" y="-78.9%" width="257.9%" height="257.9%" filterUnits="objectBoundingBox" id="brain-desktop-filter-20">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-111.1%" y="-111.1%" width="322.2%" height="322.2%" filterUnits="objectBoundingBox" id="brain-desktop-filter-21">
            <feGaussianBlur stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-29.3%" y="-14.2%" width="158.6%" height="128.3%" filterUnits="objectBoundingBox" id="brain-desktop-glow-filter-1">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-29.3%" y="-14.2%" width="158.6%" height="128.3%" filterUnits="objectBoundingBox" id="brain-desktop-glow-filter-2">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-29.3%" y="-14.2%" width="158.6%" height="128.3%" filterUnits="objectBoundingBox" id="brain-desktop-glow-filter-3">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-29.3%" y="-13.5%" width="158.6%" height="126.9%" filterUnits="objectBoundingBox" id="brain-desktop-glow-filter-4">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-29.3%" y="-13.5%" width="158.6%" height="126.9%" filterUnits="objectBoundingBox" id="brain-desktop-glow-filter-5">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
          <filter x="-35.5%" y="-13.5%" width="170.9%" height="126.9%" filterUnits="objectBoundingBox" id="brain-desktop-glow-filter-6">
            <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
          </filter>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Desktop-HD" transform="translate(-67.000000, -18.000000)">
            <g id="GLOW" transform="translate(456.000000, 140.000000)">
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-desktop-glow-filter-1)" transform="translate(310.221121, 194.272221) rotate(235.000000) translate(-310.221121, -194.272221) " points="332.721121 87.7722208 361.721121 95.7722208 261.721121 299.772221 258.721121 300.772221"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-desktop-glow-filter-2)" transform="translate(310.221121, 194.272221) rotate(235.000000) translate(-310.221121, -194.272221) " points="332.721121 87.7722208 361.721121 95.7722208 261.721121 299.772221 258.721121 300.772221"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-desktop-glow-filter-3)" transform="translate(498.778879, 166.272221) rotate(235.000000) translate(-498.778879, -166.272221) " points="521.278879 59.7722208 550.278879 67.7722208 450.278879 271.772221 447.278879 272.772221"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-desktop-glow-filter-4)" transform="translate(157.900115, 315.714427) rotate(-344.000000) translate(-157.900115, -315.714427) " points="180.400115 203.714427 209.400115 212.127572 109.400115 426.662783 106.400115 427.714427"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-desktop-glow-filter-5)" transform="translate(157.900115, 315.714427) rotate(-344.000000) translate(-157.900115, -315.714427) " points="180.400115 203.714427 209.400115 212.127572 109.400115 426.662783 106.400115 427.714427"></polygon>
              <polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-desktop-glow-filter-6)" transform="translate(117.725278, 166.530013) rotate(-261.000000) translate(-117.725278, -166.530013) " points="149.192273 54.5300133 160.258283 61.3061834 78.1922729 277.47837 75.1922729 278.530013"></polygon>
            </g>
            <g id="BRAIN" transform="translate(68.000000, 42.000000)">
              <path d="M759.5,644.5 L669.5,782.5" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
              <polygon id="Triangle" fill="#70DCDF" opacity="0.511605525" points="668.900741 766 704 789.089428 651 802"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.0927875906" points="489.761734 249 601 320 540 224"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="535 223 493 331 491 252"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="661 361 738 415 695 343"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.862941576" points="739 100 822 153 691 113"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.103430707" points="933.763672 105.5625 898.84375 210.570313 880.490234 188.527344"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.103430707" points="1207.03711 327.460938 1288.375 479.333984 1140.57812 445.912109"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.103430707" points="414.615234 84.6621094 492.423828 184.839844 492.423828 148.005859"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.103430707" points="386 316 361.369141 408.529297 307.261719 439.630859"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="930 235 891 282 787.503906 284.233398"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="825.25 284.233398 880.916016 188.470703 787.503906 284.233398"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.616677989" points="851.802734 242.410156 815.033203 185.048828 857.974609 152.373047"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.147984601" points="773 109 538 123 633 166"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.274513134" points="723.265625 147.050781 609.621094 128.101562 633 166"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="930 235 891 282 898 213"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="640 101 599 115 633 166"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="743.879883 261.664062 692.329102 234.098633 746.137695 311.328125"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="743.879883 261.664062 692.329102 234.098633 746.137695 311.328125"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.64696558" points="690.724609 175.892578 693.076172 233.867188 744.177734 153.779297"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="767.791016 187.927734 631.048828 165.09375 732.005859 209.324219"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.713088768" points="869.007812 450.0625 822.727539 358.75 847.987305 424.119141"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="690.542969 233.912109 693.220703 290.976563 607.375 316.496094"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="776.265625 323.97168 787 348 880.734375 341.489258"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.59227808" points="889.970703 280.677734 788.770508 348.755859 842.876953 334.836914"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="661 361 738 415 695 343"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.480638587" points="638 373 738 415 787 350"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.807971014" points="725 450 775 453.371094 779.777344 418.769531"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="679.660156 420.180664 668.238281 386.301758 695.733398 343.981445"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="917.822266 328.146484 960.766602 396.081055 963.203125 311.353516"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="917.822266 328.146484 894.573242 377.34668 963.203125 311.353516"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="917.822266 328.146484 894.573242 377.34668 963.203125 311.353516"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.768115942" points="941.789062 319.305664 917.822266 437.341797 963.203125 311.353516"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="526 455 539 365 586 411"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="562.088867 466.030273 514.668945 397.250977 586.762695 409.157227"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.668591486" points="604.239258 480.34082 643.433594 449.723633 637.200195 374.042969"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.544157609" points="727 535 789 544.5 905 500"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="845.519531 532.036133 789 544.5 772.212891 477.441406"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="771.394531 478.28418 789 545 868 451"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="780.021484 417.689453 848.609375 423.042969 869.787109 370.568359"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.41870471" points="906.474609 380.509766 801.308594 420.345703 869.787109 370.568359"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="767 574 759 645 846 532"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.0283627717" points="995 185 934 106 1054 138"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.851505888" points="535 223 493 331 491 252"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.605921649" points="419.366211 210.583984 450.551758 357.880859 439.749023 246.432617"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(497.893622, 153.320254) rotate(-46.000000) translate(-497.893622, -153.320254) " points="489.094975 134.87125 525.008066 158.536636 470.779177 171.769258"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(497.893622, 153.320254) rotate(-46.000000) translate(-497.893622, -153.320254) " points="489.094975 134.87125 525.008066 158.536636 470.779177 171.769258"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(497.893622, 153.320254) rotate(-46.000000) translate(-497.893622, -153.320254) " points="489.094975 134.87125 525.008066 158.536636 470.779177 171.769258"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(589.090764, 166.663419) rotate(-46.000000) translate(-589.090764, -166.663419) " points="567.749788 152.007127 627.406057 163.196472 550.77547 181.31971"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(450.941220, 281.054395) rotate(-46.000000) translate(-450.941220, -281.054395) " points="432.972161 268.468425 499.87867 289.944527 402.00377 293.640365"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(424.456517, 405.070927) rotate(-46.000000) translate(-424.456517, -405.070927) " points="439.777091 381.409012 470.630668 428.732841 378.282366 418.534869"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(190.314453, 163.064941) rotate(-46.000000) translate(-190.314453, -163.064941) " points="197.298412 151.241897 278.308659 246.617393 102.320247 79.5124895"></polygon>
              <!--<path d="M69.8789062,168.216797 C133.633777,228.13946 165.55435,258.379694 165.640625,258.9375 C165.7269,259.495306 111.366874,264.595566 2.56054688,274.238281 L69.8789062,168.216797 Z" id="Triangle" fill="#0079B9" opacity="0.096410779"></path>-->
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(547.149414, 326.158203) rotate(-46.000000) translate(-547.149414, -326.158203) " points="546.229495 292.149923 588.331705 361.600307 505.967124 290.716099"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(421.719396, 251.396452) rotate(-46.000000) translate(-421.719396, -251.396452) " points="449.85494 221.598455 438.26374 259.989737 393.583852 227.961878 401.626077 281.19445"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.318727355" transform="translate(519.505483, 239.314095) rotate(-46.000000) translate(-519.505483, -239.314095) " points="514.341329 203.283613 488.445096 228.291314 550.565869 275.344578"></polygon>
              <path d="M669.5,782.5 L787.5,745.5" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
              <path d="M603,321 L468.5,507.5" id="Line" stroke="#0079B9" stroke-width="2" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L695.5,343.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L737.5,414.5" id="Line" stroke="url(#brain-desktop-linearGradient-1)" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L586.5,409.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L636.5,373.5" id="Line" stroke="#FFFFFF" stroke-linecap="square"></path>
              <circle id="Oval-3" fill="#FFFFFF" cx="490.5" cy="217.5" r="2.5"></circle>
              <circle id="Oval-3" fill="#FFFFFF" cx="491.5" cy="251.5" r="2.5"></circle>
              <circle id="Oval-3" fill="#FFFFFF" cx="419.5" cy="210.5" r="2.5"></circle>
              <circle id="Oval-3" fill="#FFFFFF" cx="439.5" cy="245.5" r="2.5"></circle>
              <circle id="Oval-3" fill="#FFFFFF" cx="429.5" cy="286.5" r="2.5"></circle>
              <path d="M602.5,322.5 L539.5,365.5" id="Line" stroke="url(#brain-desktop-linearGradient-2)" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L570.5,238.5" id="Line" stroke="url(#brain-desktop-linearGradient-3)" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L490.5,250.5" id="Line" stroke="url(#brain-desktop-linearGradient-4)" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L428.5,286.5" id="Line" stroke="url(#brain-desktop-linearGradient-5)" stroke-linecap="square"></path>
              <path d="M569.5,239.5 L490.5,251.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M490.5,217.5 L491.5,252.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M490.5,217.5 L439.5,245.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M490.5,217.5 L419.5,210.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M490.5,217.5 L540.5,224.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M491.5,251.5 L540.5,224.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M490.5,217.5 L490.5,148.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M490.5,217.5 L490.5,148.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M419.5,210.5 L439.5,245.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M429.5,286.5 L439.5,244.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M429.5,289.5 L419.5,210.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M490.5,148.5 L541.5,225.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M490.5,148.5 L537.5,122.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M490.5,148.5 L419.5,210.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M384.5,255.5 L419.5,210.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M385.5,255.5 L429.5,287.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M385.5,317.5 L429.5,285.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M386.5,317.5 L418.5,378.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M496.5,420.5 L418.5,378.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M496.5,420.5 L525.5,456.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M496.5,420.5 L525.5,456.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M562.5,466.5 L525.5,456.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M562.5,466.5 L604.5,481.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M670.5,468.5 L604.5,481.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M670.5,468.5 L708.5,509.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M735.5,538.5 L708.5,509.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M735.5,538.5 L768.5,572.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M765.5,603.5 L768.5,572.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M765.5,603.5 L758.5,645.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M765.5,603.5 L846.5,532.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M787.5,544.5 L768.5,572.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M847.5,531.5 L768.5,572.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M787.5,544.5 L846.5,532.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M772.5,477.5 L846.5,532.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M772.5,477.5 L904.5,499.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M788.5,544.5 L772.5,477.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M734.5,537.5 L772.5,478.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M709.5,508.5 L773.5,477.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M918.5,437.5 L773.5,477.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M758.5,645.5 L846.5,532.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M903.5,500.5 L846.5,532.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M904.5,500.5 L918.5,436.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M960.5,395.5 L918.5,436.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M960.5,395.5 L963.5,311.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M929.5,234.5 L963.5,311.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M929.5,234.5 L899.5,211.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M880.5,186.5 L899.5,211.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M881.5,187.5 L857.5,152.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M812.5,129.5 L857.5,152.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M812.5,129.5 L773.5,108.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M738.5,101.5 L773.5,108.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M738.5,100.5 L694.5,112.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M890.5,280.5 L822.5,357.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M959.5,395.5 L822.5,357.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M890.5,280.5 L788.5,284.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M890.5,279.5 L930.5,234.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M890.5,280.5 L963.5,311.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M890.5,280.5 L960.5,395.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M387.5,315.5 L385.5,255.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M601.5,114.5 L537.5,122.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M600.5,115.5 L632.5,166.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M619.5,238.5 L631.5,167.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M619.5,238.5 L691.5,234.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M692.5,111.5 L691.5,234.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M693.5,291.5 L691.5,234.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M693.5,291.5 L787.5,284.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M693.5,291.5 L695.5,343.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M693.5,291.5 L695.5,343.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M736.5,413.5 L695.5,343.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M636.5,374.5 L695.5,343.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M787.5,350.5 L694.5,343.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M787.5,350.5 L821.5,357.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M788.5,284.5 L821.5,357.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M847.5,424.5 L821.5,357.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M847.5,424.5 L918.5,436.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M847.5,424.5 L779.5,418.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M788.5,284.5 L695.5,343.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M788.5,348.5 L779.5,418.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M823.5,356.5 L779.5,418.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M696.5,343.5 L779.5,418.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M737.5,414.5 L779.5,418.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M772.5,478.5 L779.5,418.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M772.5,478.5 L848.5,423.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M904.5,498.5 L848.5,423.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M961.5,394.5 L848.5,423.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M788.5,284.5 L691.5,234.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M773.5,107.5 L691.5,234.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M815.5,128.5 L691.5,234.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M858.5,152.5 L691.5,234.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M857.5,151.5 L823.5,153.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M814.5,129.5 L823.5,153.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M691.5,234.5 L823.5,153.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M788.5,284.5 L823.5,153.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M858.5,152.5 L788.5,285.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M882.5,187.5 L788.5,285.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M898.5,210.5 L788.5,285.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M739.5,100.5 L691.5,234.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M638.5,100.5 L631.5,167.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M601.5,114.5 L639.5,100.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M692.5,112.5 L639.5,100.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M570.5,239.5 L619.5,238.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M570.5,238.5 L632.5,165.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M631.5,166.5 L540.5,224.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M631.5,166.5 L539.5,123.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M537.5,122.5 L540.5,224.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M619.5,237.5 L540.5,224.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M493.5,330.5 L491.5,252.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M493.5,330.5 L496.5,421.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M493.5,330.5 L430.5,287.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M493.5,330.5 L539.5,366.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M586.5,409.5 L539.5,366.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M495.5,420.5 L538.5,365.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M495.5,420.5 L586.5,409.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M525.5,455.5 L586.5,409.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M561.5,466.5 L586.5,409.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M604.5,481.5 L586.5,409.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M670.5,468.5 L586.5,409.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M636.5,373.5 L586.5,409.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M636.5,373.5 L737.5,414.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M636.5,373.5 L669.5,467.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M669.5,467.5 L737.5,414.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M772.5,478.5 L737.5,414.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M707.5,509.5 L737.5,414.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M418.5,378.5 L538.5,365.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M418.5,378.5 L494.5,329.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M418.5,378.5 L425.5,323.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M430.5,285.5 L425.5,323.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <circle id="Oval-3" fill="#FFFFFF" cx="385.5" cy="255.5" r="2.5"></circle>
              <path d="M386.5,317.5 L425.5,323.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M493.5,330.5 L425.5,323.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M428.5,286.5 L491.5,251.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M439.5,245.5 L491.5,251.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L619.5,236.5" id="Line" stroke="#FFFFFF" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L692.5,111.5" id="Line" stroke="url(#brain-desktop-linearGradient-6)" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L690.5,234.5" id="Line" stroke="url(#brain-desktop-linearGradient-7)" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L693.5,291.5" id="Line" stroke="#FFFFFF" stroke-linecap="square"></path>
              <path d="M602.5,320.5 L788.5,284.5" id="Line" stroke="#0BB8FF" stroke-linecap="square"></path>
              <path d="M602.5,322.5 L492.5,330.5" id="Line" stroke="url(#brain-desktop-linearGradient-8)" stroke-linecap="square"></path>
              <path d="M596.5,14.5 L599.5,113.5" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
              <path d="M710.5,62.5 L692.5,112.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M710.5,62.5 L739.5,100.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M933.5,105.5 L996.5,185.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M933.5,105.5 L1054.5,137.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1150.5,73.5 L1054.5,137.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M995.5,184.5 L1054.5,137.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M995.5,184.5 L1163.5,181.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M962.5,312.5 L1163.5,181.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M962.5,312.5 L1036.5,357.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1207.5,326.5 L1036.5,357.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1206.5,326.5 L1162.5,181.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1207.5,326.5 L1139.5,445.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1207.5,326.5 L1286.5,477.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1241.5,589.5 L1286.5,477.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1241.5,589.5 L1136.5,718.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1038.5,509.5 L1136.5,718.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1093.5,851.5 L1136.5,718.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1093.5,851.5 L793.5,746.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1207.5,326.5 L1303.5,284.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1286.5,479.5 L1303.5,284.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1038.5,509.5 L1139.5,445.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1038.5,509.5 L1287.5,478.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
              <path d="M1038.5,509.5 L1035.5,357.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1038.5,509.5 L959.5,397.5" id="Line-2" stroke="#0BB8FF" stroke-width="0.3" stroke-linecap="square"></path>
              <path d="M1038.5,509.5 L918.5,438.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M900.5,576.5 L1039.5,508.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M900.5,576.5 L845.5,533.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M900.5,576.5 L904.5,501.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M900.5,576.5 L759.5,644.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M653.5,687.5 L768.5,571.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M653.5,687.5 L443.5,664.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M653.5,687.5 L669.5,784.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M995.5,184.5 L898.5,210.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M995.5,184.5 L963.5,312.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M933.5,105.5 L881.5,187.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M414.5,84.5 L489.5,147.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M414.5,84.5 L310.5,157.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M187.5,149.5 L310.5,157.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M187.5,149.5 L158.5,298.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M273.5,360.5 L158.5,298.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M273.5,360.5 L70.5,168.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1.5,274.5 L70.5,168.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M1.5,274.5 L158.5,298.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M187.5,149.5 L70.5,168.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M272.5,361.5 L385.5,255.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M272.5,361.5 L387.5,316.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M306.5,438.5 L387.5,316.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
              <path d="M306.5,438.5 L155.5,495.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
              <path d="M274.5,362.5 L155.5,495.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M306.5,438.5 L418.5,377.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M306.5,438.5 L400.5,449.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M496.5,418.5 L400.5,449.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M419.5,378.5 L400.5,449.5" id="Line-2" stroke="#0BB8FF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M385.5,255.5 L310.5,157.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M418.5,209.5 L310.5,157.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M187.5,149.5 L414.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <path d="M414.5,84.5 L537.5,122.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
              <circle id="Oval-4" fill="#0079B9" cx="602" cy="321" r="6"></circle>
              <polygon id="Rectangle-10" fill="#FFFFFF" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#brain-desktop-filter-9)" transform="translate(641.806606, 215.268679) rotate(183.000000) translate(-641.806606, -215.268679) " points="664.306606 96.2686786 693.306606 105.207646 593.306606 333.151308 590.306606 334.268679"></polygon>
              <circle id="Oval-5" fill="#FFFFFF" cx="601.5" cy="321.5" r="8.5"></circle>
              <path d="M823.5,153.5 L773.5,222.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M691.5,234.5 L773.5,222.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M691.5,234.5 L773.5,222.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M629.5,165.5 L566.5,173.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M599.5,115.5 L566.5,173.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M541.5,221.5 L566.5,173.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M490.5,148.5 L566.5,173.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M537.5,122.5 L566.5,173.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M577.5,229.5 L566.5,173.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M577.5,229.5 L595.5,260.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M493.5,298.5 L595.5,260.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M492.5,299.5 L473.5,389.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M425.5,323.5 L473.5,389.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M419.5,378.5 L473.5,389.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M496.5,419.5 L473.5,389.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M494.5,370.5 L473.5,389.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M493.5,330.5 L473.5,389.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M537.5,366.5 L473.5,389.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M586.5,409.5 L473.5,389.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M620.5,236.5 L596.5,260.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M691.5,234.5 L596.5,260.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M693.5,290.5 L595.5,260.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M603.5,320.5 L596.5,261.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M571.5,239.5 L596.5,261.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M620.5,237.5 L566.5,173.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M777.5,252.5 L773.5,223.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M777.5,252.5 L788.5,285.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M778.5,252.5 L692.5,234.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M718.5,268.5 L788.5,284.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M718.5,268.5 L691.5,234.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M718.5,268.5 L778.5,252.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M718.5,268.5 L693.5,291.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M775.5,323.5 L693.5,291.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M776.5,324.5 L880.5,341.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M822.5,357.5 L880.5,341.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M962.5,312.5 L880.5,341.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M890.5,281.5 L880.5,341.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M931.5,277.5 L880.5,341.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M931.5,280.5 L890.5,283.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M931.5,277.5 L959.5,395.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M931.5,277.5 L962.5,310.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M931.5,277.5 L929.5,235.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M931.5,277.5 L962.5,310.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M958.5,394.5 L880.5,341.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M848.5,423.5 L880.5,341.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M918.5,436.5 L880.5,341.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M775.5,323.5 L696.5,343.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M679.5,420.5 L695.5,344.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M679.5,420.5 L735.5,414.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M679.5,420.5 L637.5,373.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M679.5,420.5 L669.5,467.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M679.5,420.5 L660.5,361.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M679.5,420.5 L772.5,478.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M679.5,420.5 L586.5,409.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M679.5,420.5 L604.5,480.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M775.5,323.5 L692.5,302.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M775.5,323.5 L788.5,349.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <path d="M776.5,323.5 L788.5,285.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="778.227539 252.988281 717.991211 268.1875 788.481445 284.773438"></polygon>
              <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="778.227539 252.988281 804.318359 222.359375 788.481445 284.773437"></polygon>
              <path d="M776.5,324.5 L821.5,357.5" id="Line-3" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square"></path>
              <g id="TEXTURE" opacity="0.212975543" class="blend-saturation" style="mix-blend-mode: overlay;" transform="translate(688.726382, 325.368943) rotate(-51.000000) translate(-688.726382, -325.368943) translate(531.226382, 58.368943)">
                <polygon id="Fill-1" fill="#7FFDFF" points="168.071893 471.346811 157.164417 429.27983 140.820481 425.524101"></polygon>
                <polygon id="Fill-3" fill="#7FFDFF" points="177.379847 266.874201 202.317504 197.910803 212.579391 257.597191"></polygon>
                <polygon id="Fill-5" fill="#7FFDFF" points="190.870466 175.493772 146.433182 166.593749 161.807948 123.163333"></polygon>
                <polygon id="Fill-7" fill="#7FFDFF" points="104.0222 188.601435 85.6236881 160.132671 87.7316709 198.046518"></polygon>
                <polygon id="Fill-9" fill="#7FFDFF" points="92.0121956 279.723 150.506364 267.577752 138.357974 289.210494"></polygon>
                <polygon id="Fill-11" fill="#7FFDFF" points="189.001556 300.596115 186.639107 361.245389 152.231234 364.793775"></polygon>
                <polygon id="Fill-13" fill="#7FFDFF" points="180.221697 337.819919 147.474136 355.227271 152.23202 364.793304"></polygon>
                <polygon id="Fill-15" fill="#7FFDFF" points="157.165045 429.279358 180.220912 337.819291 142.363613 389.943958"></polygon>
                <polygon id="Fill-17" fill="#7FFDFF" points="69.5976776 117.679121 109.650923 138.916028 123.520885 123.784732"></polygon>
                <polygon id="Fill-19" fill="#7FFDFF" points="117.317415 63.86257 134.398045 77.4482212 92.0121956 93.4245951"></polygon>
                <polygon id="Fill-21" fill="#7FFDFF" points="97.789985 42.3864343 117.317886 63.8620988 123.520885 123.784104"></polygon>
                <polygon id="Fill-23" fill="#7FFDFF" points="134.398516 77.4474358 152.29595 86.3867283 124.74452 123.163333"></polygon>
                <polygon id="Fill-25" fill="#7FFDFF" points="52.9554513 4.95308813 95.6146158 15.0092031 117.318043 63.8619417"></polygon>
                <polygon id="Fill-27" fill="#7FFDFF" points="34.8184735 14.9168414 97.7893567 42.3865914 52.9562367 4.95340229"></polygon>
                <g id="Group-31" transform="translate(0.000000, 0.113681)">
                  <mask id="mask-11" fill="white">
                    <use xlink:href="#path-10"></use>
                  </mask>
                  <g id="Clip-30"></g>
                  <polygon id="Fill-29" fill="#7FFDFF" mask="url(#mask-11)" points="36.2158368 0.456781981 0 4.83925009 94.8419505 28.553272"></polygon>
                </g>
                <polygon id="Fill-32" fill="#7FFDFF" points="178.401795 242.453172 150.506364 267.577752 177.379219 266.874044"></polygon>
                <polygon id="Fill-34" fill="#7FFDFF" points="169.48842 232.226942 177.380004 266.873572 141.943273 243.082582"></polygon>
                <polygon id="Fill-36" fill="#7FFDFF" points="102.949831 216.349998 84.2214553 226.498789 69.5833835 210.773739"></polygon>
                <polygon id="Fill-38" fill="#7FFDFF" points="87.7321422 198.047303 84.2214553 226.498789 104.022671 188.602221"></polygon>
                <polygon id="Fill-40" fill="#7FFDFF" points="56.2589526 241.080784 40.2998573 255.56492 64.6406192 249.167145"></polygon>
                <polygon id="Fill-42" fill="#7FFDFF" points="22.0378456 252.623797 40.2997002 255.565862 27.1632913 274.757617"></polygon>
                <polygon id="Fill-44" fill="#7FFDFF" points="19.5470644 212.901043 22.0383169 252.624425 56.2592668 241.080784"></polygon>
                <polygon id="Fill-46" fill="#7FFDFF" points="37.6075453 194.940149 69.5838548 210.773582 54.1996638 189.346612"></polygon>
                <polygon id="Fill-48" fill="#7FFDFF" points="87.7321422 198.047303 54.1991926 189.346769 56.2584814 241.080313"></polygon>
                <polygon id="Fill-50" fill="#7FFDFF" points="109.650923 138.915871 104.022829 188.601121 190.871094 175.492986"></polygon>
                <polygon id="Fill-52" fill="#7FFDFF" points="194.95056 427.484431 160.404459 432.446516 168.071422 471.346811"></polygon>
                <polygon id="Fill-54" fill="#7FFDFF" points="168.071893 471.346811 128.657954 461.072358 161.055232 432.195192"></polygon>
                <polygon id="Fill-56" fill="#7FFDFF" points="142.36377 389.944429 152.231391 364.793146 186.639264 361.244761"></polygon>
                <g id="Group" transform="translate(72.829417, 414.576960)" fill="#7FFDFF">
                  <polygon id="Fill-32" points="81.6098991 17.241669 65.4754792 31.7734584 81.0184521 31.3664411"></polygon>
                  <polygon id="Fill-34" points="73.6937821 17.3684501 78.2581903 37.4076922 57.7619623 23.6472369"></polygon>
                  <polygon id="Fill-36" points="41.0792608 45.3101599 30.2469679 51.1801125 21.7804631 42.0849113"></polygon>
                  <polygon id="Fill-38" points="19.1732098 32.8978764 17.1426659 49.3539126 28.5954784 27.4349411"></polygon>
                  <polygon id="Fill-40" points="33.9748612 66.279178 24.7442904 74.6566478 38.8227279 70.9562428"></polygon>
                  <polygon id="Fill-42" points="23.2855632 78.6893901 33.8480253 80.3910495 26.2500663 91.4913559"></polygon>
                  <polygon id="Fill-44" points="4.52384052 65.3502057 5.96475443 88.3257868 25.7577874 81.6490679"></polygon>
                  <polygon id="Fill-46" points="0.510164351 51.8937599 19.0049212 61.051649 10.106869 48.6585175"></polygon>
                  <polygon id="Fill-48" points="25.9445662 46.2330427 6.54946534 41.2007463 7.74053604 71.122877"></polygon>
                  <polygon id="Fill-50" points="5.27177748 0.448919112 2.01654765 29.1863388 52.2486596 21.6047332"></polygon>
                  <polygon id="Fill-52" points="172.229651 80.1424825 152.248555 83.0124995 156.683044 105.512016"></polygon>
                  <polygon id="Fill-54" points="165.60713 118.771872 142.810528 112.829238 161.548768 96.1269926"></polygon>
                  <polygon id="Fill-56" points="115.90762 80.7405035 121.614947 66.1932693 141.516094 64.1409209"></polygon>
                </g>
                <g id="Group-Copy" transform="translate(142.000000, 183.000000)" fill="#7FFDFF">
                  <polygon id="Fill-32" points="81.6098991 17.241669 65.4754792 31.7734584 81.0184521 31.3664411"></polygon>
                  <polygon id="Fill-34" points="73.6937821 17.3684501 78.2581903 37.4076922 57.7619623 23.6472369"></polygon>
                  <polygon id="Fill-36" points="41.0792608 45.3101599 30.2469679 51.1801125 21.7804631 42.0849113"></polygon>
                  <polygon id="Fill-38" points="19.1732098 32.8978764 17.1426659 49.3539126 28.5954784 27.4349411"></polygon>
                  <polygon id="Fill-40" points="33.9748612 66.279178 24.7442904 74.6566478 38.8227279 70.9562428"></polygon>
                  <polygon id="Fill-42" points="23.2855632 78.6893901 33.8480253 80.3910495 26.2500663 91.4913559"></polygon>
                  <polygon id="Fill-44" points="4.52384052 65.3502057 5.96475443 88.3257868 25.7577874 81.6490679"></polygon>
                  <polygon id="Fill-46" points="0.510164351 51.8937599 19.0049212 61.051649 10.106869 48.6585175"></polygon>
                  <polygon id="Fill-48" points="25.9445662 46.2330427 6.54946534 41.2007463 7.74053604 71.122877"></polygon>
                  <polygon id="Fill-50" points="5.27177748 0.448919112 2.01654765 29.1863388 52.2486596 21.6047332"></polygon>
                  <polygon id="Fill-52" points="172.229651 80.1424825 152.248555 83.0124995 156.683044 105.512016"></polygon>
                  <polygon id="Fill-54" points="165.60713 118.771872 142.810528 112.829238 161.548768 96.1269926"></polygon>
                  <polygon id="Fill-56" points="115.90762 80.7405035 121.614947 66.1932693 141.516094 64.1409209"></polygon>
                </g>
                <polygon id="Fill-58" fill="#7FFDFF" points="169.48842 232.226942 178.40258 242.452701 177.380004 266.873572"></polygon>
              </g>
              <g id="CIRCLE-GLOW" transform="translate(180.000000, 0.000000)">
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-12)" cx="983.5" cy="181.5" r="8.5"></circle>
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-13)" cx="913.5" cy="850.5" r="8.5"></circle>
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-14)" cx="235.5" cy="83.5" r="8.5"></circle>
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-15)" cx="874.5" cy="137.5" r="8.5"></circle>
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-16)" cx="96.5" cy="361.5" r="8.5"></circle>
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-17)" cx="491.5" cy="463.5" r="8.5"></circle>
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-18)" cx="857" cy="508" r="6"></circle>
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-19)" cx="8.5" cy="149.5" r="8.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1060.5" cy="590.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="956.5" cy="719.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="913.5" cy="851.5" r="1.5"></circle>
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-20)" cx="288" cy="508" r="19"></circle>
                <circle id="Oval-6" fill="#024A79" class="blend-saturation" style="mix-blend-mode: hard-light;" filter="url(#brain-desktop-filter-21)" cx="416.5" cy="13.5" r="13.5"></circle>
              </g>
              <g id="DOTS" transform="translate(0.000000, 12.000000)">
                <circle id="Oval-7" stroke="#979797" fill="#D8D8D8" cx="69.5" cy="155.5" r="1.5"></circle>
                <circle id="Oval-7" stroke="#979797" fill="#D8D8D8" cx="1.5" cy="262.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="995.5" cy="172.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="995.5" cy="172.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1036.5" cy="345.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1206.5" cy="314.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1287.5" cy="466.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1140.5" cy="433.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="901.5" cy="563.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1038.5" cy="496.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="653.5" cy="675.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="443.5" cy="652.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="307.5" cy="426.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="400.5" cy="437.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="310.5" cy="145.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="158.5" cy="286.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="414.5" cy="72.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="186.5" cy="137.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="933.5" cy="93.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="710.5" cy="50.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1054.5" cy="125.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1149.5" cy="61.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1162.5" cy="169.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="1302.5" cy="272.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="570.5" cy="227.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="577.5" cy="217.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="691.5" cy="222.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="823.5" cy="141.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="773" cy="211" r="1"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="718.5" cy="256.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="566.5" cy="161.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="880.5" cy="329.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="679.5" cy="408.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="596" cy="249" r="1"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="473.5" cy="377.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="931.5" cy="265.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="776" cy="312" r="1"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="778" cy="241" r="1"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="822.5" cy="346.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="918.5" cy="425.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="868.5" cy="438.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="493.5" cy="286.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="779.5" cy="406.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="788.5" cy="337.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="631.5" cy="153.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="693.5" cy="279.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="693.5" cy="290.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="619.5" cy="225.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="890.5" cy="268.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="788.5" cy="272.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="963.5" cy="299.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="881.5" cy="175.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="898.5" cy="198.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="929.5" cy="223.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="857.5" cy="140.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="739.5" cy="88.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="772.5" cy="96.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="814.5" cy="117.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="599.5" cy="102.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="639.5" cy="88.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="692.5" cy="100.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="540.5" cy="211.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="490.5" cy="136.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="537.5" cy="110.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="637.5" cy="361.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="660.5" cy="349.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="736.5" cy="402.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="695.5" cy="331.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="765.5" cy="590.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="759.5" cy="632.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="845.5" cy="520.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="904.5" cy="487.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="848.5" cy="411.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="959.5" cy="383.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="734.5" cy="524.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="772.5" cy="466.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="768.5" cy="560.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="788.5" cy="531.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="669.5" cy="455.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="708.5" cy="496.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="604.5" cy="468.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="562.5" cy="454.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="525.5" cy="443.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="494.5" cy="358.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="586.5" cy="397.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="538.5" cy="353.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="493.5" cy="318.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="496.5" cy="407.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="425.5" cy="311.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="418.5" cy="365.5" r="2.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="387.5" cy="305.5" r="2.5"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="469" cy="496" r="3"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="669.5" cy="770.5" r="3.5"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="792.5" cy="732.5" r="3.5"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="596" cy="3" r="3"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="274.5" cy="349.5" r="1.5"></circle>
                <circle id="Oval-3" fill="#FFFFFF" cx="154.5" cy="483.5" r="1.5"></circle>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </section>
  <section id="home" class="on">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-8 text-right animate-fade-in animate-delay-8 d-none d-lg-block " style="padding:0px 30px 0 0;margin-top:-40px;">
          <h1>
            <span style="color:#0079b8;font-weight:500;">{{ 'front.next' | translate }}</span> <span style="font-weight:500;">{{ 'front.title2' | translate }}</span><br />
            {{ 'front.title3' | translate }} <span style="color:#0079b8;font-weight:500;">{{ 'front.title4' | translate }}</span> {{ 'front.title5' | translate }}
          </h1>
        </div>
        <div class="col-lg-7 col-8 text-left animate-fade-in animate-delay-8 d-lg-none" style="padding-top:0px">
          <h1>
            <span style="color:#0079b8;font-weight:500;">NextMind</span> <br />
            <span style="font-weight:500;">connects you</span><br />
            to your <span style="color:#0079b8;">future</span> business
          </h1>
        </div>
        <div class="col-lg-5 col-7 text-left animate-fade-in animate-delay-9 text-padding" style="padding-right:0;">
          <p class="lead">
            {{ 'front.bring' | translate }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <section id="about" class="block edge--bottom gradient" style="padding-bottom:0;">
    <svg width="502px" viewBox="0 0 502 774" class="paralax overview zindexupper">
      <defs>
        <filter x="-29.3%" y="-9.8%" width="158.6%" height="119.5%" filterUnits="objectBoundingBox" id="filter-1">
          <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
          <stop stop-color="#A5EBED" offset="0%"></stop>
          <stop stop-color="#0F98FF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="-223.367446%" x2="169.674805%" y2="197.953216%" id="linearGradient-3">
          <stop stop-color="#0079B9" offset="0%"></stop>
          <stop stop-color="#F8F8F8" offset="100%"></stop>
        </linearGradient>
        <ellipse id="path-4" cx="76.5" cy="62.5" rx="57.5" ry="55.5"></ellipse>
        <filter x="-7.0%" y="-5.4%" width="113.9%" height="114.4%" filterUnits="objectBoundingBox" id="filter-5">
          <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
          <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
          <feMorphology radius="1" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
          <feOffset dx="0" dy="2" in="shadowInner" result="shadowInner"></feOffset>
          <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <ellipse id="path-6" cx="75.5" cy="62.5" rx="57.5" ry="55.5"></ellipse>
        <filter x="-7.0%" y="-5.4%" width="113.9%" height="114.4%" filterUnits="objectBoundingBox" id="filter-7">
          <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
          <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
          <feMorphology radius="1" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
          <feOffset dx="0" dy="2" in="shadowInner" result="shadowInner"></feOffset>
          <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <ellipse id="path-8" cx="75.5" cy="62.5" rx="57.5" ry="55.5"></ellipse>
        <filter x="-7.0%" y="-5.4%" width="113.9%" height="114.4%" filterUnits="objectBoundingBox" id="filter-9">
          <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
          <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
          <feMorphology radius="1" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
          <feOffset dx="0" dy="2" in="shadowInner" result="shadowInner"></feOffset>
          <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <filter x="-29.3%" y="-10.8%" width="158.6%" height="121.7%" filterUnits="objectBoundingBox" id="overview-glow-filter-1">
          <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <filter x="-44.5%" y="-16.5%" width="189.0%" height="132.9%" filterUnits="objectBoundingBox" id="overview-glow-filter-2">
          <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <filter x="-23.4%" y="-9.4%" width="146.8%" height="118.7%" filterUnits="objectBoundingBox" id="overview-glow-filter-3">
          <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <filter x="-23.4%" y="-9.4%" width="146.8%" height="118.7%" filterUnits="objectBoundingBox" id="overview-glow-filter-4">
          <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
        </filter>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Desktop-HD" transform="translate(-500.000000, -826.000000)">
          <g id="GLOW" transform="translate(456.000000, 140.000000)">
            <!--<polygon id="Rectangle-10" class="blend-saturation" style="mix-blend-mode: overlay;" filter="url(#overview-glow-filter-1)" transform="translate(237.631571, 876.572066) rotate(-313.000000) translate(-237.631571, -876.572066) " points="260.131571 737.348433 289.131571 747.806546 189.131571 1014.48843 186.131571 1015.7957"></polygon>-->
            <polygon id="Rectangle-10" class="blend-saturation-dark" style="mix-blend-mode: overlay;" filter="url(#overview-glow-filter-2)" transform="translate(326.759110, 755.759110) rotate(-40.000000) translate(-326.759110, -755.759110) " points="341.567435 664.129384 360.653721 671.012368 294.838942 846.528462 292.864499 847.388835"></polygon>
            <polygon id="Rectangle-10" class="blend-saturation-dark" style="mix-blend-mode: overlay;" filter="url(#overview-glow-filter-3)" transform="translate(353.351182, 1337.538436) rotate(-150.000000) translate(-353.351182, -1337.538436) " points="404.957105 1176.54711 417.725103 1181.38317 294.057666 1496.74884 288.977262 1498.52976"></polygon>
            <polygon id="Rectangle-10" class="blend-saturation-dark" style="mix-blend-mode: overlay;" filter="url(#overview-glow-filter-4)" transform="translate(353.351182, 1337.538436) rotate(-150.000000) translate(-353.351182, -1337.538436) " points="404.957105 1176.54711 417.725103 1181.38317 294.057666 1496.74884 288.977262 1498.52976"></polygon>
          </g>
          <g id="CONNECTIONS" transform="translate(-265.000000, -18.000000)">
            <path d="M1073.5,970.5 L1002.5,844.5" id="Line" stroke="#6CD3D6" stroke-linecap="square"></path>
            <polygon id="Rectangle-10" fill="#FFFFFF" class="blend-saturation-dark" style="mix-blend-mode: overlay;" filter="url(#filter-1)" transform="translate(946.276102, 1045.652637) rotate(-312.000000) translate(-946.276102, -1045.652637) " points="968.776102 891.152637 997.776102 902.758271 897.776102 1198.70193 894.776102 1200.15264"></polygon>
          </g>
          <g id="2-OVERVIEW" transform="translate(473.000000, 951.000000)">
            <polygon id="Triangle" fill="#0079B9" opacity="0.511605525" transform="translate(92.500000, 130.000000) rotate(87.000000) translate(-92.500000, -130.000000) " points="83.9007411 112 119 135.089428 66 148"></polygon>
            <polygon id="Triangle" fill="#0079B9" opacity="0.511605525" transform="translate(215.500000, 633.000000) rotate(23.000000) translate(-215.500000, -633.000000) " points="206.900741 615 242 638.089428 189 651"></polygon>
            <polygon id="Triangle" fill="#0079B9" opacity="0.511605525" transform="translate(215.500000, 633.000000) rotate(23.000000) translate(-215.500000, -633.000000) " points="206.900741 615 242 638.089428 189 651"></polygon>
            <path d="M338.5,1.5 L92.5,127.5" id="Line" stroke="url(#linearGradient-2)" stroke-linecap="square"></path>
            <path d="M173.5,240.5 L93.5,126.5" id="Line" stroke="url(#linearGradient-2)" stroke-linecap="square"></path>
            <path d="M98.5,325.5 L174.5,240.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
            <path d="M142.5,414.5 L216.5,632.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
            <path d="M241.5,429.5 L216.5,632.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
            <path d="M438.5,435.5 L216.5,632.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
            <path d="M278.5,330.5 L175.5,243.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
            <path d="M459.5,331.5 L171.5,241.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
            <circle id="Oval" fill="#FFFFFF" cx="336.5" cy="3.5" r="3.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="93.5" cy="126.5" r="3.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="173.5" cy="240.5" r="3.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="215.5" cy="633.5" r="3.5"></circle>
            <g id="IN-CLOUD" transform="translate(13.000000, 324.000000)">
              <g id="CLOUD" transform="translate(43.112903, 30.274194)" stroke="url(#linearGradient-3)" stroke-width="2">
                <path d="M40.8064516,55.5 C40.8064516,51.5455355 37.4840645,48.3387097 33.3870968,48.3387097 C29.290129,48.3387097 25.9677419,51.5455355 25.9677419,55.5 C25.9677419,59.4544645 29.290129,62.6612903 33.3870968,62.6612903 C37.4840645,62.6612903 40.8064516,59.4544645 40.8064516,55.5 Z" id="Stroke-1"></path>
                <path d="M35.2419355,48.3387097 L35.2419355,39.3870968" id="Stroke-3"></path>
                <path d="M42.6612903,55.5 L68.6290323,55.5" id="Stroke-5"></path>
                <path d="M0,55.5 L25.9677419,55.5" id="Stroke-7"></path>
                <path d="M51.0080645,14.3914392 C51.0080645,6.4428201 44.3027298,0 36.0302419,0 C29.1231008,0 22.8198024,4.49315881 21.0886815,10.6042184 C19.909375,10.6042184 19.4127419,10.6042184 18.6875,10.6042184 C10.4150121,10.6042184 3.70967742,17.0470385 3.70967742,24.9956576 C3.70967742,32.9442767 10.4150121,39.3870968 18.6875,39.3870968 L54.1612903,39.3870968 C61.1267661,39.3870968 66.7741935,33.9607667 66.7741935,27.2679901 C66.7741935,20.5752134 61.1267661,15.1488834 54.1612903,15.1488834 L51.0080645,15.1488834 L51.0080645,14.3914392 Z" id="Stroke-9"></path>
              </g>
              <g id="Oval-2">
                <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
                <use stroke="#0079B9" stroke-width="2" xlink:href="#path-4"></use>
              </g>
              <text x="50" transform="translate(75)" font-family=".SFNSDisplay, .SF NS Display" font-size="20" font-weight="normal" line-spacing="16" fill="#FFFFFF">
                <tspan dy="150" text-anchor="middle" x="0">{{'front.in' | translate}}</tspan>
              </text>
              <circle id="Oval" fill="#FFFFFF" cx="82.5" cy="6.5" r="6.5"></circle>
              <circle id="Oval" fill="#FFFFFF" cx="129.5" cy="89.5" r="6.5"></circle>
            </g>
            <g id="SECURE" transform="translate(205.000000, 324.000000)" style="text-align: center;">
              <path d="M104,72.6514523 C104,79.3034232 99.7044706,85.1431535 93.2381176,88.4496888 L76,97 L58.7618824,88.4496888 C52.2955294,85.1431535 48,79.3034232 48,72.6514523 L48,41.5995851 L76,31 L104,41.5995851 L104,72.6514523 Z" id="Stroke-1" stroke="url(#linearGradient-3)" stroke-width="2"></path>
              <polyline id="Stroke-3" stroke="url(#linearGradient-3)" stroke-width="2" points="89.0666667 55.972973 71.8078212 72.027027 61.0666667 62.0356822"></polyline>
              <text x="50" transform="translate(75)" font-family=".SFNSDisplay, .SF NS Display" text-anchor="middle" font-size="20" item-width="100px" font-weight="normal" line-spacing="16" fill="#FFFFFF">
                <tspan dy="150" text-anchor="middle" x="0">{{'front.secure' | translate}}</tspan>
              </text>
              <g id="Oval-2">
                <use fill="black" fill-opacity="1" filter="url(#filter-7)" xlink:href="#path-6"></use>
                <use stroke="#0079B9" stroke-width="2" xlink:href="#path-6"></use>
              </g>
              <circle id="Oval" fill="#FFFFFF" cx="69.5" cy="6.5" r="6.5"></circle>
              <circle id="Oval" fill="#FFFFFF" cx="35.5" cy="103.5" r="6.5"></circle>
            </g>
            <g id="FAST" transform="translate(391.000000, 324.000000)">
              <path d="M87.2325581,75.984375 C87.2325581,71.5581469 83.0669767,67.96875 77.9302326,67.96875 C72.7934884,67.96875 68.627907,71.5581469 68.627907,75.984375 C68.627907,80.4106031 72.7934884,84 77.9302326,84 C83.0669767,84 87.2325581,80.4106031 87.2325581,75.984375 Z" id="Stroke-1" stroke="url(#linearGradient-3)" stroke-width="2"></path>
              <path d="M83.5116279,71.53125 L105.837209,51.9375" id="Stroke-3" stroke="url(#linearGradient-3)" stroke-width="2"></path>
              <path d="M104.272727,62.625 L117,62.625 C117,42.9502841 99.0909091,27 77,27 C54.9090909,27 37,42.9502841 37,62.625 L49.7272727,62.625" id="Stroke-5" stroke="url(#linearGradient-3)" stroke-width="2"></path>
              <path d="M98.3953488,44.8125 L97.1236734,43.6051356 C91.9045435,38.8376825 84.8132752,35.90625 77,35.90625 C69.1867248,35.90625 62.0936424,38.8376825 56.8763266,43.6051356 L55.6046512,44.8125" id="Stroke-7" stroke="url(#linearGradient-3)" stroke-width="2"></path>
              <g id="Oval-2">
                <use fill="black" fill-opacity="1" filter="url(#filter-9)" xlink:href="#path-8"></use>
                <use stroke="#0079B9" stroke-width="2" xlink:href="#path-8"></use>
              </g>
              <circle id="Oval" fill="#FFFFFF" cx="69.5" cy="6.5" r="6.5"></circle>
              <circle id="Oval" fill="#FFFFFF" cx="43.5" cy="110.5" r="6.5"></circle>
              <text x="50" transform="translate(75)" font-family=".SFNSDisplay, .SF NS Display" font-size="20" font-weight="normal" line-spacing="16" fill="#FFFFFF">
                <tspan dy="150" text-anchor="middle" x="0" text-anchor="middle">{{'front.fast' | translate}}</tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <!--<svg width="502px" height="637px" viewBox="0 0 502 637" class="paralax overview">
        <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                <stop stop-color="#A5EBED" offset="0%"></stop>
                <stop stop-color="#0F98FF" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="50%" y1="-223.367446%" x2="169.674805%" y2="197.953216%" id="linearGradient-2">
                <stop stop-color="#0079B9" offset="0%"></stop>
                <stop stop-color="#F8F8F8" offset="100%"></stop>
            </linearGradient>
            <ellipse id="path-3" cx="76.5" cy="62.5" rx="57.5" ry="55.5"></ellipse>
            <filter x="-7.0%" y="-5.4%" width="113.9%" height="114.4%" filterUnits="objectBoundingBox" id="filter-4">
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
                <feOffset dx="0" dy="2" in="shadowInner" result="shadowInner"></feOffset>
                <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
            <ellipse id="path-5" cx="75.5" cy="62.5" rx="57.5" ry="55.5"></ellipse>
            <filter x="-7.0%" y="-5.4%" width="113.9%" height="114.4%" filterUnits="objectBoundingBox" id="filter-6">
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
                <feOffset dx="0" dy="2" in="shadowInner" result="shadowInner"></feOffset>
                <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
            <ellipse id="path-7" cx="75.5" cy="62.5" rx="57.5" ry="55.5"></ellipse>
            <filter x="-7.0%" y="-5.4%" width="113.9%" height="114.4%" filterUnits="objectBoundingBox" id="filter-8">
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
                <feOffset dx="0" dy="2" in="shadowInner" result="shadowInner"></feOffset>
                <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Desktop-HD" transform="translate(-500.000000, -951.000000)">
                <g id="2-OVERVIEW" transform="translate(473.000000, 951.000000)">
                    <path d="M338.5,1.5 L92.5,127.5" id="Line" stroke="url(#linearGradient-1)" stroke-linecap="square"></path>
                    <path d="M173.5,240.5 L93.5,126.5" id="Line" stroke="url(#linearGradient-1)" stroke-linecap="square"></path>
                    <path d="M98.5,325.5 L174.5,240.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
                    <path d="M142.5,414.5 L216.5,632.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
                    <path d="M241.5,429.5 L216.5,632.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
                    <path d="M438.5,435.5 L216.5,632.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
                    <path d="M278.5,330.5 L175.5,243.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
                    <path d="M459.5,331.5 L171.5,241.5" id="Line" stroke="#0079B9" stroke-linecap="square"></path>
                    <circle id="Oval" fill="#FFFFFF" cx="336.5" cy="3.5" r="3.5"></circle>
                    <circle id="Oval" fill="#FFFFFF" cx="93.5" cy="126.5" r="3.5"></circle>
                    <circle id="Oval" fill="#FFFFFF" cx="173.5" cy="240.5" r="3.5"></circle>
                    <circle id="Oval" fill="#FFFFFF" cx="215.5" cy="633.5" r="3.5"></circle>
                    <g id="IN-CLOUD" transform="translate(13.000000, 324.000000)">
                        <g id="CLOUD" transform="translate(43.112903, 30.274194)" stroke="url(#linearGradient-2)" stroke-width="2">
                            <path d="M40.8064516,55.5 C40.8064516,51.5455355 37.4840645,48.3387097 33.3870968,48.3387097 C29.290129,48.3387097 25.9677419,51.5455355 25.9677419,55.5 C25.9677419,59.4544645 29.290129,62.6612903 33.3870968,62.6612903 C37.4840645,62.6612903 40.8064516,59.4544645 40.8064516,55.5 Z" id="Stroke-1"></path>
                            <path d="M35.2419355,48.3387097 L35.2419355,39.3870968" id="Stroke-3"></path>
                            <path d="M42.6612903,55.5 L68.6290323,55.5" id="Stroke-5"></path>
                            <path d="M0,55.5 L25.9677419,55.5" id="Stroke-7"></path>
                            <path d="M51.0080645,14.3914392 C51.0080645,6.4428201 44.3027298,0 36.0302419,0 C29.1231008,0 22.8198024,4.49315881 21.0886815,10.6042184 C19.909375,10.6042184 19.4127419,10.6042184 18.6875,10.6042184 C10.4150121,10.6042184 3.70967742,17.0470385 3.70967742,24.9956576 C3.70967742,32.9442767 10.4150121,39.3870968 18.6875,39.3870968 L54.1612903,39.3870968 C61.1267661,39.3870968 66.7741935,33.9607667 66.7741935,27.2679901 C66.7741935,20.5752134 61.1267661,15.1488834 54.1612903,15.1488834 L51.0080645,15.1488834 L51.0080645,14.3914392 Z" id="Stroke-9"></path>
                        </g>
                        <g id="Oval-2">
                            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                            <use stroke="#0079B9" stroke-width="2" xlink:href="#path-3"></use>
                        </g>
                        <text font-family=".SFNSDisplay, .SF NS Display" font-size="20" font-weight="normal" line-spacing="16" fill="#FFFFFF">
                            <tspan x="25.6816406" y="146.369141">IN CLOUD</tspan>
                        </text>
                        <circle id="Oval" fill="#FFFFFF" cx="82.5" cy="6.5" r="6.5"></circle>
                        <circle id="Oval" fill="#FFFFFF" cx="129.5" cy="89.5" r="6.5"></circle>
                    </g>
                    <g id="SECURE" transform="translate(205.000000, 324.000000)">
                        <path d="M104,72.6514523 C104,79.3034232 99.7044706,85.1431535 93.2381176,88.4496888 L76,97 L58.7618824,88.4496888 C52.2955294,85.1431535 48,79.3034232 48,72.6514523 L48,41.5995851 L76,31 L104,41.5995851 L104,72.6514523 Z" id="Stroke-1" stroke="url(#linearGradient-2)" stroke-width="2"></path>
                        <polyline id="Stroke-3" stroke="url(#linearGradient-2)" stroke-width="2" points="89.0666667 55.972973 71.8078212 72.027027 61.0666667 62.0356822"></polyline>
                        <text font-family=".SFNSDisplay, .SF NS Display" font-size="20" font-weight="normal" line-spacing="16" fill="#FFFFFF">
                            <tspan x="33.7480469" y="146.369141">SECURE</tspan>
                        </text>
                        <g id="Oval-2">
                            <use fill="black" fill-opacity="1" filter="url(#filter-6)" xlink:href="#path-5"></use>
                            <use stroke="#0079B9" stroke-width="2" xlink:href="#path-5"></use>
                        </g>
                        <circle id="Oval" fill="#FFFFFF" cx="69.5" cy="6.5" r="6.5"></circle>
                        <circle id="Oval" fill="#FFFFFF" cx="35.5" cy="103.5" r="6.5"></circle>
                    </g>
                    <g id="FAST" transform="translate(391.000000, 324.000000)">
                        <path d="M87.2325581,75.984375 C87.2325581,71.5581469 83.0669767,67.96875 77.9302326,67.96875 C72.7934884,67.96875 68.627907,71.5581469 68.627907,75.984375 C68.627907,80.4106031 72.7934884,84 77.9302326,84 C83.0669767,84 87.2325581,80.4106031 87.2325581,75.984375 Z" id="Stroke-1" stroke="url(#linearGradient-2)" stroke-width="2"></path>
                        <path d="M83.5116279,71.53125 L105.837209,51.9375" id="Stroke-3" stroke="url(#linearGradient-2)" stroke-width="2"></path>
                        <path d="M104.272727,62.625 L117,62.625 C117,42.9502841 99.0909091,27 77,27 C54.9090909,27 37,42.9502841 37,62.625 L49.7272727,62.625" id="Stroke-5" stroke="url(#linearGradient-2)" stroke-width="2"></path>
                        <path d="M98.3953488,44.8125 L97.1236734,43.6051356 C91.9045435,38.8376825 84.8132752,35.90625 77,35.90625 C69.1867248,35.90625 62.0936424,38.8376825 56.8763266,43.6051356 L55.6046512,44.8125" id="Stroke-7" stroke="url(#linearGradient-2)" stroke-width="2"></path>
                        <g id="Oval-2">
                            <use fill="black" fill-opacity="1" filter="url(#filter-8)" xlink:href="#path-7"></use>
                            <use stroke="#0079B9" stroke-width="2" xlink:href="#path-7"></use>
                        </g>
                        <circle id="Oval" fill="#FFFFFF" cx="69.5" cy="6.5" r="6.5"></circle>
                        <circle id="Oval" fill="#FFFFFF" cx="43.5" cy="110.5" r="6.5"></circle>
                        <text font-family=".SFNSDisplay, .SF NS Display" font-size="20" font-weight="normal" line-spacing="16" fill="#FFFFFF">
                            <tspan x="48.0107422" y="146.369141">FAST</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </svg>-->
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-4">
        </div>
        <div class="col-lg-7 col-8 text-left animate-fade-in" style="padding-top:0px;padding-left:40px;">
          <h1><span style="color:#0079b8;font-weight:500;">NextMind</span> <span style="font-weight:500;">{{ 'front.over' | translate }}</span></h1>
          <p class="lead">
            {{ 'front.connect' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div style="z-index:100;position:relative;max-width:960px;padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;">
      <div class="row text-content" style="">
        <div class="col-lg-7 col-7" style="position:relative">
          <a href="#" style="height:100%; width:100%;" class="js-play"><img class="img-fluid" style="float:right;" src="assets/img/video-placeholder.png" alt="NextMind video" /></a>
        </div>
        <div class="col-lg-5  col-5 text-left animate-fade-in" style="padding-top:60px;padding-left:20px;">
          <p class="lead">
            {{ 'front.connect2' | translate }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <section id="features" class="block image fixed-background" style="">

    <svg width="566px" height="913px" viewBox="0 0 566 913" class="paralax features d-none d-lg-block" style="z-index:2;">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="features-desktop-linearGradient-1">
          <stop stop-color="#00B1DD" offset="0%"></stop>
          <stop stop-color="#B6519E" offset="100%"></stop>
        </linearGradient>
        <filter x="-30.2%" y="-8.8%" width="160.4%" height="117.6%" filterUnits="objectBoundingBox" id="features-desktop-filter-2">
          <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <filter x="-23.4%" y="-7.3%" width="146.8%" height="114.7%" filterUnits="objectBoundingBox" id="features-desktop-filter-3">
          <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <filter x="-23.4%" y="-7.3%" width="146.8%" height="114.7%" filterUnits="objectBoundingBox" id="features-desktop-filter-4">
          <feGaussianBlur stdDeviation="10.0530134" in="SourceGraphic"></feGaussianBlur>
        </filter>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Desktop-HD" transform="translate(-478.000000, -1808.000000)">
          <g id="CONNECTIONS" transform="translate(-265.000000, -18.000000)">
            <polygon id="Triangle" fill="#B6519E" opacity="0.511605525" transform="translate(1139.500000, 1968.000000) rotate(79.000000) translate(-1139.500000, -1968.000000) " points="1130.90074 1950 1166 1973.08943 1113 1986"></polygon>
            <path d="M1259.5,2019.5 L889.5,1846.5" id="Line" stroke="url(#features-desktop-linearGradient-1)" stroke-linecap="square"></path>
          </g>
          <g id="2-OVERVIEW" transform="translate(473.000000, 951.000000)" fill="#FFFFFF">
            <circle id="Oval" cx="196.0" cy="898" r="3.5"></circle>
          </g>
          <g id="3-FEATURES" transform="translate(404.000000, 1994.000000)">
            <g id="ICONS" transform="translate(416.000000, 82.000000)" stroke-width="2" stroke="#FFFFFF">
              <polygon id="Stroke-1" points="5 240.125 21 240.125 21 228.125 5 228.125"></polygon>
              <path d="M13,240.125 L13,245.125" id="Stroke-2"></path>
              <path d="M18,245.125 L8,245.125" id="Stroke-3"></path>
              <polygon id="Stroke-4" points="25 259.125 41 259.125 41 247.125 25 247.125"></polygon>
              <path d="M33,259.125 L33,264.125" id="Stroke-5"></path>
              <path d="M38,264.125 L28,264.125" id="Stroke-6"></path>
              <polyline id="Stroke-7" points="41 240.125 41 228.125 29 228.125"></polyline>
              <polyline id="Stroke-8" points="5 252.125 5 264.125 17 264.125"></polyline>
              <polygon id="Stroke-9" points="4 105.875 42 105.875 42 77.875 4 77.875"></polygon>
              <path d="M23,105.875 L23,112.875" id="Stroke-10"></path>
              <path d="M32,112.875 L14,112.875" id="Stroke-11"></path>
              <polyline id="Stroke-12" points="31.5859 86.2891 20.9999 96.8751 14.4059 90.2811"></polyline>
              <polygon id="Stroke-13" points="18 161.75 28 161.75 28 151.75 18 151.75"></polygon>
              <g id="Group-17" transform="translate(2.000000, 179.000000)">
                <polygon id="Stroke-14" points="0 10.75 10 10.75 10 0.75 0 0.75"></polygon>
                <polygon id="Stroke-16" points="32 10.75 42 10.75 42 0.75 32 0.75"></polygon>
              </g>
              <polygon id="Stroke-18" points="18 189.75 28 189.75 28 179.75 18 179.75"></polygon>
              <path d="M23,179.75 L23,161.75" id="Stroke-19"></path>
              <polyline id="Stroke-20" points="7 179.75 7 170.75 39 170.75 39 179.75"></polyline>
              <polyline id="Stroke-21" points="21.5 337 5.5 337 5.5 301 37.5 301 37.5 320"></polyline>
              <path d="M5.5,313 L37.5,313" id="Stroke-22"></path>
              <path d="M5.5,325 L24.5,325" id="Stroke-23"></path>
              <path d="M24.5,307 L32.5,307" id="Stroke-24"></path>
              <path d="M24.5,319 L32.5,319" id="Stroke-25"></path>
              <path d="M10.5,319 L14.5,319" id="Stroke-26"></path>
              <path d="M10.5,331 L14.5,331" id="Stroke-27"></path>
              <path d="M10.5,307 L14.5,307" id="Stroke-28"></path>
              <g id="Group-36">
                <polygon id="Stroke-29" points="26.5 342 40.5 342 40.5 332 26.5 332"></polygon>
                <path d="M28.5,332 L28.5,329 C28.5,326.239 30.739,324 33.5,324 C36.261,324 38.5,326.239 38.5,329 L38.5,332" id="Stroke-31"></path>
                <polygon id="Stroke-32" points="12 40 34 40 34 20 12 20"></polygon>
                <path d="M16,25 L20,25" id="Stroke-33"></path>
                <path d="M16,35 L20,35" id="Stroke-34"></path>
                <path d="M11,30 C4.925,30 0,25.075 0,19 C0,12.925 4.925,8 11,8 L12.078,8 C13.385,3.385 17.966,0 23,0 C29.075,0 34,4.925 34,11 L36.5,11 C41.747,11 46,15.253 46,20.5 C46,25.747 41.747,30 36.5,30 L11,30 Z" id="Stroke-35"></path>
              </g>
            </g>
            <polygon id="Rectangle-2" fill="#303E4F" points="121.40681 550.236576 532 503 522.622116 727 74 717.35191"></polygon>
            <polygon id="Rectangle-2" fill="#B6519E" transform="translate(524.000000, 538.500000) rotate(92.000000) translate(-524.000000, -538.500000) " points="492.009221 487 567 520.103107 565.161022 590 481 570.824805"></polygon>
            <path d="M590.5,7.5 L482.5,102.5" id="Line" stroke="#B6519E" stroke-linecap="square"></path>
            <path d="M482.5,171.5 L482.5,105.5" id="Line" stroke="#B6519E" stroke-linecap="square"></path>
            <path d="M482.5,171.5 L482.5,260.5" id="Line" stroke="#B6519E" stroke-linecap="square"></path>
            <path d="M482.5,331.5 L482.5,260.5" id="Line" stroke="#B6519E" stroke-linecap="square"></path>
            <path d="M482.5,331.5 L482.5,402.5" id="Line" stroke="#B6519E" stroke-linecap="square"></path>
            <path d="M446.5,561.5 L483.5,402.5" id="Line" stroke="#B6519E" stroke-linecap="square"></path>
            <circle id="Oval" fill="#FFFFFF" cx="587.5" cy="6.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="481.5" cy="102.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="481.5" cy="172.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="481.5" cy="259.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="481.5" cy="329.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="481.5" cy="398.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="447.5" cy="556.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="100.5" cy="716.5" r="6.5"></circle>
            <g id="KOG" transform="translate(488.000000, 507.000000)" stroke="#FFFFFF" stroke-width="2">
              <path d="M41,32 C41,27.029 36.971,23 32,23 C27.029,23 23,27.029 23,32 C23,36.971 27.029,41 32,41 C36.971,41 41,36.971 41,32 Z" id="Stroke-1"></path>
              <polygon id="Stroke-3" points="0 35.1542857 8.79085714 38.1622857 10.7702857 44.3779048 7.47580952 53.1900952 10.8099048 56.5241905 19.6220952 53.2297143 25.8377143 55.2091429 28.8457143 64 35.1542857 64 38.1622857 55.2091429 44.3779048 53.2297143 53.1900952 56.5241905 56.5241905 53.1900952 53.2297143 44.3779048 55.2091429 38.1622857 64 35.1542857 64 28.8457143 55.2091429 25.8377143 53.2297143 19.6220952 56.5241905 10.8099048 53.1900952 7.47580952 44.3779048 10.7702857 38.1622857 8.79085714 35.1542857 0 28.8457143 0 25.8377143 8.79085714 19.6220952 10.7702857 10.8099048 7.47580952 7.47580952 10.8099048 10.7702857 19.6220952 8.79085714 25.8377143 0 28.8457143"></polygon>
            </g>
            <polygon id="GLOW" fill="#FFFFFF" class="blend-saturation-dark" style="mix-blend-mode: overlay;" filter="url(#features-desktop-filter-2)" transform="translate(477.738410, 256.066511) rotate(-371.000000) translate(-477.738410, -256.066511) " points="513.825705 85.0665108 527.670094 88.2456803 439.91223 427.066511 427.806726 417.431698"></polygon>
          </g>
          <g id="GLOW" transform="translate(456.000000, 130.000000)" fill="#FFFFFF">
            <polygon id="Rectangle-10" class="blend-saturation-dark" style="mix-blend-mode: overlay;" filter="url(#features-desktop-filter-3)" transform="translate(369.103156, 1784.501873) rotate(-84.000000) translate(-369.103156, -1784.501873) " points="420.709079 1579.00187 433.477077 1585.17494 309.80964 1987.72858 304.729236 1990.00187"></polygon>
            <polygon id="Rectangle-10" class="blend-saturation-dark" style="mix-blend-mode: overlay;" filter="url(#features-desktop-filter-4)" transform="translate(369.103156, 1784.501873) rotate(-84.000000) translate(-369.103156, -1784.501873) " points="420.709079 1579.00187 433.477077 1585.17494 309.80964 1987.72858 304.729236 1990.00187"></polygon>
          </g>
        </g>
      </g>
    </svg>
    <svg width="352px" height="636px" viewBox="0 0 352 636" class="paralax features d-lg-none">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="features-mobile-linearGradient-1">
          <stop stop-color="#00B1DD" offset="0%"></stop>
          <stop stop-color="#B6519E" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="MOBILE-VERSION" transform="translate(-12.000000, -1213.000000)">
          <g id="3-FEATURES" transform="translate(12.000000, 1213.000000)">
            <g id="ICONS" transform="translate(243.199995, 130.399999)" stroke-width="1.39999998" stroke="#FFFFFF">
              <polygon id="Stroke-1" points="4.43333378 212.910855 18.6200019 212.910855 18.6200019 202.270854 4.43333378 202.270854"></polygon>
              <path d="M11.5266678,212.910855 L11.5266678,217.344189" id="Stroke-2"></path>
              <path d="M15.9600016,217.344189 L7.09333405,217.344189" id="Stroke-3"></path>
              <polygon id="Stroke-4" points="22.1666689 229.757523 36.353337 229.757523 36.353337 219.117522 22.1666689 219.117522"></polygon>
              <path d="M29.260003,229.757523 L29.260003,234.190857" id="Stroke-5"></path>
              <path d="M33.6933368,234.190857 L24.8266692,234.190857" id="Stroke-6"></path>
              <polyline id="Stroke-7" points="36.353337 212.910855 36.353337 202.270854 25.7133359 202.270854"></polyline>
              <polyline id="Stroke-8" points="4.43333378 223.550856 4.43333378 234.190857 15.0733349 234.190857"></polyline>
              <polygon id="Stroke-9" points="3.54666703 93.8758429 37.2400038 93.8758429 37.2400038 69.0491737 3.54666703 69.0491737"></polygon>
              <path d="M20.3933354,93.8758429 L20.3933354,100.08251" id="Stroke-10"></path>
              <path d="M28.3733362,100.08251 L12.4133346,100.08251" id="Stroke-11"></path>
              <polyline id="Stroke-12" points="28.0061675 76.5096764 18.6199132 85.8959307 12.7732326 80.0492501"></polyline>
              <polygon id="Stroke-13" points="15.9600016 143.418348 24.8266692 143.418348 24.8266692 134.55168 15.9600016 134.55168"></polygon>
              <g id="Group-17" transform="translate(1.773334, 158.713349)">
                <polygon id="Stroke-14" points="0 9.53166764 8.86666757 9.53166764 8.86666757 0.665000068 0 0.665000068"></polygon>
                <polygon id="Stroke-16" points="28.3733362 9.53166764 37.2400038 9.53166764 37.2400038 0.665000068 28.3733362 0.665000068"></polygon>
              </g>
              <polygon id="Stroke-18" points="15.9600016 168.245017 24.8266692 168.245017 24.8266692 159.37835 15.9600016 159.37835"></polygon>
              <path d="M20.3933354,159.37835 L20.3933354,143.418348" id="Stroke-19"></path>
              <polyline id="Stroke-20" points="6.2066673 159.37835 6.2066673 151.398349 34.5800035 151.398349 34.5800035 159.37835"></polyline>
              <polyline id="Stroke-21" points="19.0633353 298.806697 4.87666716 298.806697 4.87666716 266.886694 33.2500034 266.886694 33.2500034 283.733362"></polyline>
              <path d="M4.87666716,277.526695 L33.2500034,277.526695" id="Stroke-22"></path>
              <path d="M4.87666716,288.166696 L21.7233355,288.166696" id="Stroke-23"></path>
              <path d="M21.7233355,272.206694 L28.8166696,272.206694" id="Stroke-24"></path>
              <path d="M21.7233355,282.846695 L28.8166696,282.846695" id="Stroke-25"></path>
              <path d="M9.31000095,282.846695 L12.856668,282.846695" id="Stroke-26"></path>
              <path d="M9.31000095,293.486696 L12.856668,293.486696" id="Stroke-27"></path>
              <path d="M9.31000095,272.206694 L12.856668,272.206694" id="Stroke-28"></path>
              <g id="Group-36">
                <polygon id="Stroke-29" points="23.4966691 303.240031 35.9100036 303.240031 35.9100036 294.373363 23.4966691 294.373363"></polygon>
                <path d="M25.2700026,294.373363 L25.2700026,291.713363 C25.2700026,289.265276 27.2552494,287.280029 29.7033364,287.280029 C32.1514233,287.280029 34.1366701,289.265276 34.1366701,291.713363 L34.1366701,294.373363" id="Stroke-31"></path>
                <polygon id="Stroke-32" points="10.6400011 35.4666703 30.1466697 35.4666703 30.1466697 17.7333351 10.6400011 17.7333351"></polygon>
                <path d="M14.1866681,22.1666689 L17.7333351,22.1666689" id="Stroke-33"></path>
                <path d="M14.1866681,31.0333365 L17.7333351,31.0333365" id="Stroke-34"></path>
                <path d="M9.75333432,26.6000027 C4.36683378,26.6000027 0,22.2331689 0,16.8466684 C0,11.4601678 4.36683378,7.09333405 9.75333432,7.09333405 L10.7091611,7.09333405 C11.8680345,3.00136697 15.929855,0 20.3933354,0 C25.779836,0 30.1466697,4.36683378 30.1466697,9.75333432 L32.3633366,9.75333432 C37.0156771,9.75333432 40.7866708,13.524328 40.7866708,18.1766685 C40.7866708,22.829009 37.0156771,26.6000027 32.3633366,26.6000027 L9.75333432,26.6000027 Z" id="Stroke-35"></path>
              </g>
            </g>
            <path d="M298.5,77.5 L298.5,153.5" id="Line" stroke="#B6519E" stroke-width="0.699999988" stroke-linecap="square"></path>
            <path d="M298.5,77.5 L101.5,4.5" id="Line" stroke="url(#features-mobile-linearGradient-1)" stroke-width="0.699999988" stroke-linecap="square"></path>
            <path d="M293.5,212.5 L297.5,152.5" id="Line" stroke="#B6519E" stroke-width="0.699999988" stroke-linecap="square"></path>
            <path d="M293.472656,213.769531 L296.507812,286.632812" id="Line" stroke="#B6519E" stroke-width="0.699999988" stroke-linecap="square"></path>
            <path d="M295.134766,348.388672 L295.134766,286.925781" id="Line" stroke="#B6519E" stroke-width="0.699999988" stroke-linecap="square"></path>
            <path d="M296.5,349.5 L296.5,413.5" id="Line" stroke="#B6519E" stroke-width="0.699999988" stroke-linecap="square"></path>
            <path d="M273.5,489.5 L296.5,413.5" id="Line" stroke="#B6519E" stroke-width="0.699999988" stroke-linecap="square"></path>
            <circle id="Oval" fill="#FFFFFF" cx="298.55" cy="77.5499999" r="4.54999992"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="298.55" cy="77.5499999" r="4.54999992"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="101.55" cy="4.54999992" r="4.54999992"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="298.049994" cy="151.749999" r="4.54999992"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="293.049994" cy="212.749998" r="4.54999992"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="296.049994" cy="286.649997" r="4.54999992"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="296.049994" cy="348.649996" r="4.54999992"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="296.049994" cy="413.949995" r="4.54999992"></circle>
            <g id="Group-2" transform="translate(0.000000, 472.000000)">
              <polygon id="Rectangle-2" fill="#303E4F" points="33.1847665 40.0656027 320.599995 7 314.035476 163.799997 0 157.046335"></polygon>
              <polygon id="Rectangle-2" fill="#B6519E" transform="translate(315.078514, 31.339790) rotate(92.000000) translate(-315.078514, -31.339790) " points="292.684969 -4.71020917 345.178513 18.4619651 343.891228 67.3897896 284.978514 53.9671531"></polygon>
              <circle id="Oval" fill="#FFFFFF" cx="273.55" cy="11.5499999" r="4.54999992"></circle>
              <circle id="Oval" fill="#FFFFFF" cx="180.55" cy="157.55" r="4.54999992"></circle>
              <g id="KOG" transform="translate(290.000000, 9.000000)" stroke="#FFFFFF" stroke-width="1.39999998">
                <path d="M28.6999995,22.3999996 C28.6999995,18.9202997 25.8796996,16.0999997 22.3999996,16.0999997 C18.9202997,16.0999997 16.0999997,18.9202997 16.0999997,22.3999996 C16.0999997,25.8796996 18.9202997,28.6999995 22.3999996,28.6999995 C25.8796996,28.6999995 28.6999995,25.8796996 28.6999995,22.3999996 Z" id="Stroke-1"></path>
                <polygon id="Stroke-3" points="0 24.6079996 6.1535999 26.7135995 7.53919987 31.0645328 5.23306658 37.233066 7.5669332 39.5669327 13.7354664 37.2607994 18.0863997 38.6463993 20.1919997 44.7999992 24.6079996 44.7999992 26.7135995 38.6463993 31.0645328 37.2607994 37.233066 39.5669327 39.5669327 37.233066 37.2607994 31.0645328 38.6463993 26.7135995 44.7999992 24.6079996 44.7999992 20.1919997 38.6463993 18.0863997 37.2607994 13.7354664 39.5669327 7.5669332 37.233066 5.23306658 31.0645328 7.53919987 26.7135995 6.1535999 24.6079996 0 20.1919997 0 18.0863997 6.1535999 13.7354664 7.53919987 7.5669332 5.23306658 5.23306658 7.5669332 7.53919987 13.7354664 6.1535999 18.0863997 0 20.1919997"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div class="container" style="z-index: 99999;">
      <div class="row">
        <div class="col-lg-12 text-center animate-fade-in">
          <h1><span style="color:#b6509f;font-weight:500;">NextMind</span> <span style="font-weight:500;"> {{ 'front.feature' | translate }}</span></h1>
        </div>
        <div class="col-8 col-lg-7 text-right animate-fade-in" style="margin-top:0;">
          <ul class="list-unstyled icon-list">
            <li><a [routerLink]="['services']" fragment="digitizing-link" style="z-index: 999; font-weight: bold; color: white;"><strong>{{ 'front.account' | translate }}</strong></a><span class="d-none d-lg-block">{{ 'front.access' | translate }}</span></li>
            <li><a [routerLink]="['vocean']" style="z-index: 999; font-weight: bold; color: white;"><strong>{{ 'front.easy' | translate }}</strong></a><span class="d-none d-lg-block">{{ 'front.identify' | translate }}</span></li>
            <li><a [routerLink]="['services']" fragment="consulting-link" style="z-index: 999; font-weight: bold; color: white;"><strong>{{ 'front.multiple' | translate }}</strong></a><span class="d-none d-lg-block">{{ 'front.add' | translate }}</span></li>
            <li><a [routerLink]="['services']" fragment="communication-link" style="z-index: 999; font-weight: bold; color: white;"><strong>{{ 'front.controlled' | translate }}</strong></a><span class="d-none d-lg-block">{{ 'front.connect3' | translate }}</span></li>
            <li><a [routerLink]="['services']" fragment="sell-link" style="z-index: 999; font-weight: bold; color: white;"><strong>{{ 'front.data' | translate }}</strong></a><span class="d-none d-lg-block">{{ 'front.export' | translate }} </span> </li>
          </ul>
        </div>
        <div class="col-lg-5">
        </div>
      </div>
      <div class="row">
        <div class="col-11 cog-box animate-fade-in">
          <h1 style="margin-top: -13px"><span style="color:#B8509C;font-weight:500;">{{ 'front.real' | translate }}</span> <span style="font-weight:500;">{{ 'front.tool' | translate }}</span></h1>
          <p>
            {{ 'front.brings' | translate }}  <br />
            {{ 'front.need' | translate }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <section id="customers" class="block edge--top fill-color">
    <svg width="281px" height="679px" viewBox="0 0 281 679" style="z-index:2;" class="paralax collaboration d-none d-lg-block">
      <defs>
        <linearGradient x1="50%" y1="3.33824936%" x2="50%" y2="100%" id="collaboration-desktop-linearGradient-1">
          <stop stop-color="#B6519E" offset="0%"></stop>
          <stop stop-color="#FFCC00" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Desktop-nextmind" transform="translate(-409.000000, -2709.000000)">
          <g id="4-CLIENTS" transform="translate(0.000000, 2840.000000)">
            <path d="M682.5,33.5 L605.5,117.5" id="Line" stroke="#FFCC00" stroke-linecap="square"></path>
            <path d="M440.5,160.5 L605.5,117.5" id="Line" stroke="#FFCC00" stroke-linecap="square"></path>
            <path d="M409.5,296.5 L457.5,392.5" id="Line" stroke="#FFCC00" stroke-linecap="square"></path>
            <path d="M526.5,520.5 L459.5,398.5" id="Line" stroke="#FFCC00" stroke-linecap="square"></path>
            <circle id="Oval" fill="#FFFFFF" cx="605.5" cy="116.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="440.5" cy="160.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="685.5" cy="27.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="456.5" cy="392.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="526.5" cy="521.5" r="6.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="406.5" cy="291.5" r="6.5"></circle>
          </g>
          <g id="CONNECTIONS" transform="translate(-265.000000, -18.000000)">
            <path d="M946.5,2880.5 L770.5,2732.5" id="Line" stroke="url(#collaboration-desktop-linearGradient-1)" stroke-linecap="square"></path>
            <polygon id="Triangle" fill="#FFCC00" opacity="0.511605525" transform="translate(888.500000, 2820.000000) rotate(18.000000) translate(-888.500000, -2820.000000) " points="879.900741 2802 915 2825.08943 862 2838"></polygon>
          </g>
        </g>
      </g>
    </svg>
    <svg width="142px" height="138px" viewBox="0 0 142 138" style="z-index:2;" class="paralax collaboration d-lg-none">
      <defs>
        <linearGradient x1="50%" y1="3.33824936%" x2="50%" y2="100%" id="collaboration-mobile-linearGradient-1">
          <stop stop-color="#B6519E" offset="0%"></stop>
          <stop stop-color="#FFCC00" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="MOBILE-VERSION" transform="translate(-54.000000, -1841.000000)">
          <g id="4-CLIENTS" transform="translate(-419.000000, 1841.000000)">
            <path d="M478.5,66.5 L484.5,131.5" id="Line" stroke="#FFCC00" stroke-width="0.699999988" stroke-linecap="square"></path>
            <path d="M478.5,66.5 L614.5,0.5" id="Line" stroke="url(#collaboration-mobile-linearGradient-1)" stroke-width="0.699999988" stroke-linecap="square"></path>
            <circle id="Oval" fill="#FFFFFF" cx="482.55" cy="132.55" r="4.54999992"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="478.449992" cy="65.3499998" r="4.54999992"></circle>
          </g>
        </g>
      </g>
    </svg>
    <svg width="146px" height="88px" viewBox="0 0 146 88" style="z-index:2;" class="paralax collaboration-2 d-lg-none">
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="MOBILE-VERSION" transform="translate(-68.000000, -2158.000000)">
          <g id="4-CLIENTS" transform="translate(-419.000000, 1841.000000)">
            <path d="M629.000007,322.5 L490.5,400.5" id="Line" stroke="#FFCC00" stroke-width="0.699999988" stroke-linecap="square"></path>
            <circle id="Oval" fill="#FFFFFF" cx="627.55" cy="321.55" r="4.54999992"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="491.55" cy="399.55" r="4.54999992"></circle>
          </g>
        </g>
      </g>
    </svg>
    <div class="container customer-header">
      <div class="row">
        <div class="col-lg-5">
        </div>
        <div class="col-lg-7 text-center animate-fade-in" style="padding-top:10px; padding-left:0px;">
          <h1><span style="color:#fecb04;font-weight:500;">{{ 'front.collaboration2' | translate }}</span> <span style="font-weight:500;">{{ 'front.collaboration3' | translate }} </span></h1>
        </div>
      </div>
    </div>
    <div class="block edge--top--reverse">
      <div class="container customer-box-1">
        <div class="row">
          <div class="col-lg-5 col-6">
            <img style="float:right;margin-top:-50px;" class="img-fluid" src="assets/img/collaboration-1.png" />
          </div>
          <div class="col-lg-7 col-6 text-left animate-fade-in" style="padding-top:20px;">

            <span style="text-transform: uppercase;"><strong>{{ 'front.research' | translate }}</strong></span>
            <p>
              {{ 'front.business' | translate }}<br />
            </p>
            <p class="d-none d-lg-block">
              {{ 'front.we1' | translate }}
            </p>
          </div>
          <div class="col-11 text-left d-lg-none animate-fade-in">
            <p>
              {{ 'front.we2' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="block edge--top " style="margin-top:100px">
      <svg style="margin-left: 428px" width="406px" height="778px" viewBox="0 0 406 778" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="paralax planet">
        <!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->
        <title>Group 2</title>
        <desc>Created with Sketch.</desc>
        <defs>
          <linearGradient x1="2.93019495%" y1="69.887509%" x2="61.8789287%" y2="16.7923306%" id="linearGradient-1">
            <stop stop-color="#61BEC3" offset="0%"></stop>
            <stop stop-color="#FFCC00" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Desktop-nextmind-4-Copy" transform="translate(-673.000000, -3373.000000)">
            <g id="CONNECTIONS" transform="translate(-265.000000, -18.000000)">
              <g id="Group-2" transform="translate(939.000000, 3391.000000)">
                <path d="M390.5,438.5 L295.5,222.5" id="Line" stroke="#FFCC00" stroke-linecap="square"></path>
                <!--<path d="M0.5,776.5 L384.5,467.5" id="Line" stroke="url(#linearGradient-1)" stroke-linecap="square"></path>-->
                <path d="M291.5,214.5 L313.5,0.5" id="Line" stroke="#FFCC00" stroke-linecap="square"></path>
                <circle id="Oval" fill="#FFFFFF" cx="292.5" cy="216.5" r="6.5"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="390" cy="437" r="6.5"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="314" cy="0" r="6.5"></circle>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div class="container customer-box-2" style="z-index: 99999;">
        <div class="row">
          <div class="col-lg-7 col-6 text-right animate-fade-in" style="padding-top:20px;">
            <span style="text-transform: uppercase;"><strong>{{ 'front.technology' | translate }}</strong></span>
            <p>
              {{ 'front.microsoft' | translate }}
            </p>
            <p class="d-none d-lg-block">
              {{ 'front.to' | translate }}
            </p>
            <p class="d-none d-lg-block">Nyfiken på mer?<br /><b><i><a [routerLink]="['contact']" fragment="contact-link" style="z-index: 999; font-size: 18px;">KONTAKTA OSS</a></i></b>.</p>
          </div>
          <div class="col-lg-5 col-6">
            <img style="margin-top:-50px;float:left;" class="img-fluid" src="assets/img/collaboration-2.png" />
          </div>
          <div class="col-8 text-left d-lg-none animate-fade-in">
            <p>
              {{ 'front.to1' | translate }}
            </p>
            <p>Är du intresserad av strategisk nulägesanalys eller demo av VOCEAN,<br /><b><i><a [routerLink]="['contact']" fragment="contact-link" style="z-index: 999; font-size: 18px;">KLICKA HÄR</a></i></b>.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="planet" class="block image fixed-background " style=""></section>
  <!-- Footer -->
  <footer>
    <div class="container-fluid">
      <p class="m-0 text-center text-white">&copy;{{ 'front.2019' | translate }}</p>
    </div>
    <!-- /.container -->
  </footer>

</div>
