<!-- <header>
   <div class="container">
      <nav>
        <a href="javascript:void(0)" class="pull-left">
          <img src="assets/img/logo_white.png" class="logo">
        </a>
        <div class="navLinks pull-right">
          <ul>
            <li><a href="javascript:void(0)">OVERVIEW</a></li>
            <li><a href="javascript:void(0)">PRODUCTS</a></li>
            <li><a href="javascript:void(0)">FEATURES</a></li>
            <li><a href="javascript:void(0)">PRICING </a></li>
            <li><a href="javascript:void(0)"> LOG IN  <img src="assets/img/ico_login.png" alt="Login" /></a></li>
          </ul>
        </div>
      </nav>
   </div>
 </header> -->

<div id="page-top">
  <svg
    width="1440px"
    height="1778px"
    viewBox="0 0 1440 1778"
    class="d-none d-lg-block"
    style="
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    "
  >
    <defs></defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="NEW-LOG-IN">
        <g id="CONNECTIONS" transform="translate(-79.000000, -18.000000)">
          <g id="CONNECTIONS-2">
            <!--<path d="M1196.5,375.5 L1582.5,283.5" id="Line" stroke="#6CD3D6" stroke-width="0.5" stroke-linecap="square"></path>-->
            <circle id="Oval" fill="#FFFFFF" cx="1170" cy="77" r="3"></circle>
            <path
              d="M1383.5,138.5 L1350.5,0.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1694.5,126.5 L1383.5,137.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1273.5,573.5 L1579.5,663.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1272.5"
              cy="572.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1383.5"
              cy="137.5"
              r="1.5"
            ></circle>
            <path
              d="M1384.5,137.5 L1288.5,201.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1441.5,390.5 L1520.5,541.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1475.5,653.5 L1520.5,541.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1475.5,653.5 L1370.5,782.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1272.5,573.5 L1370.5,782.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1441.5,390.5 L1537.5,348.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1272.5,573.5 L1373.5,509.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1272.5,573.5 L1521.5,542.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.3"
              stroke-linecap="square"
            ></path>
            <path
              d="M1272.5,573.5 L1269.5,421.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1272.5,573.5 L1119.5,510.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1474.5"
              cy="654.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1370.5"
              cy="780.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="268.5"
              cy="135.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="153.5"
              cy="562.5"
              r="1.5"
            ></circle>
            <path
              d="M268.5,136.5 L309.5,224.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M268.5,136.5 L575.5,205.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M268.5,136.5 L178.5,6.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M268.5,136.5 L185.5,216.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M272.5,427.5 L69.5,235.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M0.5,341.5 L157.5,365.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M186.5,216.5 L69.5,235.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M305.5,505.5 L154.5,562.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.3"
              stroke-linecap="square"
            ></path>
            <path
              d="M273.5,429.5 L154.5,562.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1129.5,1181.5 L1522.5,1225.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1528.5,1479.5 L1394.5,1409.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1454.66429,1677.33645 L1546.33571,1536.66355"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M247.5,1729.5 L61.6666667,1801.66096"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
              transform="translate(154.500000, 1765.500000) rotate(-180.000000) translate(-154.500000, -1765.500000) "
            ></path>
            <path
              d="M63.3405797,1482.6625 L188.5,1537.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
              transform="translate(126.000000, 1510.000000) rotate(-180.000000) translate(-126.000000, -1510.000000) "
            ></path>
            <path
              d="M278.5,1379.5 L63.6594203,1302.3375"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
              transform="translate(171.000000, 1341.000000) rotate(-180.000000) translate(-171.000000, -1341.000000) "
            ></path>
          </g>
          <g id="CONNECTIONS-1" transform="translate(156.000000, 162.000000)">
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1073.5"
              cy="86.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1073.5"
              cy="86.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1114.5"
              cy="259.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1284.5"
              cy="228.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1218.5"
              cy="347.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1011.5"
              cy="7.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1132.5"
              cy="39.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1240.5"
              cy="83.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1041.5"
              cy="213.5"
              r="2.5"
            ></circle>
            <path
              d="M1011.5,7.5 L1074.5,87.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1011.5,7.5 L1132.5,39.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1073.5,86.5 L1132.5,39.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1073.5,86.5 L1241.5,83.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1040.5,214.5 L1241.5,83.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1040.5,214.5 L1114.5,259.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1285.5,228.5 L1114.5,259.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1284.5,228.5 L1240.5,83.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1285.5,228.5 L1217.5,347.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1073.5,86.5 L963.5,74.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1073.5,86.5 L1041.5,214.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M1011.5,7.5 L895.5,0.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="150.5"
              cy="343.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="153.5"
              cy="62.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="1.5"
              cy="203.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="29.5"
              cy="54.5"
              r="1.5"
            ></circle>
            <circle
              id="Oval-3"
              fill="#FFFFFF"
              cx="117.5"
              cy="266.5"
              r="1.5"
            ></circle>
            <path
              d="M353.5,120.5 L153.5,62.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M30.5,54.5 L153.5,62.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M30.5,54.5 L1.5,203.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M116.5,265.5 L1.5,203.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M115.5,266.5 L228.5,160.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M115.5,266.5 L333.5,146.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M149.5,343.5 L261.5,282.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M149.5,343.5 L119.5,266.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
            <path
              d="M228.5,160.5 L153.5,62.5"
              id="Line-2"
              stroke="#FFFFFF"
              stroke-width="0.1"
              stroke-linecap="square"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- Navigation -->

  <nav
    class="navbar navbar-expand-lg navbar-dark fixed-top"
    id="mainNav"
    style=""
  >
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" routerLink="/"
        ><img src="assets/img/logo_white.png" class="logo" alt="Logotype"
      /></a>
      <button
        class="navbar-toggler collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav">
          <!--
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#about">Overview</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#customers">Collaboration</a>
        </li>
        -->

          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/services">{{
              "front.services" | translate
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/vocean"
              >Lösning</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/education"
              >Utbildning</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/about">{{
              "front.about" | translate
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/blog">{{
              "front.blog" | translate
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger login" routerLink="/contact"
              >{{ "front.contact" | translate }}
              <img src="assets/img/ico_login.png" alt="Contact"
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <section id="contact" class="block">
    <div>
      <div class="contactForm" style="margin-top: 85px; margin-bottom: 95px">
        <div
          class="row"
          style="
            border-bottom: 1px solid #606367;
            margin-bottom: 15px;
            padding-bottom: 25px;
            padding-top: 5px;
          "
        >
          <div class="col-4 text-right d-none d-lg-block">
            <img
              src="assets/img/mats_profil.jpg"
              alt="Mats Strandow"
              style="border-radius: 30px; width: 100%"
            />
          </div>
          <div class="col-lg-8 col-12 text-left">
            <h2>Mats Strandow</h2>
            <p><i>"Finns det ett mål finns det motivation"</i></p>
            <p>
              Att som ledare uppfatta, lyssna av och ge positiv feedback till
              sina medarbetare. Att involvera och att få medarbetare inse att
              med sina förmågor kan vi lyckas göra större saker tillsammans även
              stordåd.
            </p>
            <p>
              Mats Strandow
              <a
                href="https://www.linkedin.com/in/mats-strandow-4528461b/"
                target="_blank"
                ><i class="fab fa-linkedin" style="color: #0077b5"></i></a
              ><br />
              Account Manager<br />
              +46 (0) 70 261 21 96<br />
              <a href="mailto:mats@nextmind.com">mats@nextmind.com</a>
            </p>
          </div>
        </div>

        <div
          class="row"
          style="
            border-bottom: 1px solid #606367;
            margin-top: 15px;
            margin-bottom: 20px;
            padding-bottom: 10px;
          "
        >
          <div class="col-4 text-right d-none d-lg-block">
            <img
              src="assets/img/people/edvin.jpg"
              alt="Edvin Frunck"
              style="border-radius: 30px; width: 100%"
            />
          </div>
          <div class="col-lg-8 col-12 text-left">
            <h2>Edvin Kollin</h2>
            <p><i>"Ungt företagande är framtiden!"</i></p>
            <p>
              Varför vänta på något som du kan börja med idag? Varför ska du
              spara den där revolutionerande idén. Varför inte börja med idén nu
              när du har all tid i världen och har den ork som du inte kommer ha
              om några år. Börja på din dröm idag istället för att vänta och se
              vad som händer om du inte följer den.
            </p>

            <p>
              Edvin Frunck
              <a
                href="https://www.linkedin.com/in/edvin-frunck-7b48a1188/"
                target="_blank"
                ><i class="fab fa-linkedin" style="color: #0077b5"></i></a
              ><br />
              Assistant Account Manager<br />
              +46 (0) 70 617 38 17<br />
              <a href="mailto:edvin@nextmind.com">edvin@nextmind.com</a>
            </p>
          </div>
        </div>
        <div
          class="row"
          style="
            border-bottom: 1px solid #606367;
            margin-top: 15px;
            margin-bottom: 20px;
            padding-bottom: 10px;
          "
        >
          <div class="col-4 text-right d-none d-lg-block">
            <img
              src="assets/img/people/Svante.jpg"
              alt="Edvin Frunck"
              style="border-radius: 30px; width: 100%"
            />
          </div>
          <div class="col-lg-8 col-12 text-left">
            <h2>Svante Wiström</h2>
            <p><i>"Våga ta det digitala steget"</i></p>
            <p>
              Digtalisering kommer att förändra framtiden. Vi lever i ett
              informationssamhälle och digitalisering bidrar till att utveckla
              det. Många av dagens analoga funktioner kommer även att
              digitaliseras, så det gäller att hänga med så man inte hamnar på
              efterkälken. Våga ta steget mot det digitala och mot framtiden!
            </p>

            <p>
              Svante Wiström
              <a
                href="https://www.linkedin.com/in/svante-wiström-40a195192"
                target="_blank"
                ><i class="fab fa-linkedin" style="color: #0077b5"></i></a
              ><br />
              Assistant Account Manager<br />
              +46 (0) 73 594 51 73<br />
              <a href="mailto:svante@nextmind.com">svante@nextmind.com</a>
            </p>
          </div>
        </div>
        <div
          class="row"
          style="
            border-bottom: 1px solid #606367;
            margin-top: 15px;
            margin-bottom: 20px;
            padding-bottom: 10px;
          "
        >
          <div class="col-4 text-right d-none d-lg-block">
            <img
              src="assets/img/peter_profil.jpg"
              alt="Peter Kollin"
              style="border-radius: 30px; width: 100%"
            />
          </div>
          <div class="col-lg-8 col-12 text-left">
            <h2>Peter Kollin</h2>
            <p><i>"Människor och digitalisering"</i></p>
            <p>
              Goda affärer och värden växer fram i mötet mellan människor och
              digitalisering. Relationer, förtroende, tillit och partnerskap är
              grundläggande beståndsdelar i allt företagsbyggande. Vi ser
              kraften hos medarbetare och tror på kommande generation, där både
              människor och det digitala växer tillsammans och skapar något
              nytt.
            </p>
            <p>
              Peter Kollin
              <a
                href="https://www.linkedin.com/in/peter-kollin-620239103/"
                target="_blank"
                ><i class="fab fa-linkedin" style="color: #0077b5"></i></a
              ><br />
              Founder & CEO<br />
              +46 (0) 72 207 53 43<br />
              <a href="mailto:peter@nextmind.com">peter@nextmind.com</a>
            </p>
          </div>
        </div>

        <div
          class="row"
          style="
            border-bottom: 1px solid #606367;
            margin-top: 15px;
            margin-bottom: 20px;
            padding-bottom: 10px;
          "
        >
          <div class="col-12 text-center">
            <h1 style="margin-top: 15px; margin-bottom: 20px">
              Samverkande konsulter
            </h1>

            <h3 style="font-size: 22px">
              <a href="https://stegsandsisters.se/" target="_blank"
                >Stegs and Sisters AB</a
              >
            </h3>
            <p>
              <i>Mella Stegs VD</i>
            </p>

            <h3 style="font-size: 22px">
              <a href="https://www.mikaelreijer.se/" target="_blank"
                >Mikael Reijer AB</a
              >
            </h3>
            <p>
              <i>Mikael Reijer VD</i>
            </p>

            <h3 style="font-size: 22px">
              <a href="https://www.xlent.se/kontor/hudiksvall" target="_blank"
                >XLENT AB Hudiksvall</a
              >
            </h3>
            <p>
              <i>Lennart Sundh VD</i>
            </p>

            <h3 style="font-size: 22px">
              <a href="http://wb-revision.se/" target="_blank"
                >Wennberg & Blennå AB</a
              >
            </h3>
            <p>
              <i>Mats Blennå VD</i>
            </p>

            <h3 style="font-size: 22px">
              <a href="https://www.thinkthank.se/" target="_blank"
                >ThinkThank Sweden AB</a
              >
            </h3>
            <p>
              <i>Mats Strandow VD</i>
            </p>

            <h3 style="font-size: 22px">
              <a href="https://www.davidlindqvist.com/" target="_blank"
                >LindQvist and Q-Holding</a
              >
            </h3>
            <p>
              <i>David Lindqvist VD</i>
            </p>

            <h3 style="font-size: 22px">Monemus AB</h3>
            <p>
              <i>Håkan Kingstedt VD</i>
            </p>

            <h3 style="font-size: 22px">AR Konsulting AB</h3>
            <p>
              <i>Rolf Viberg VD</i>
            </p>
          </div>
        </div>

        <div
          class="row"
          style="margin-bottom: 5px; padding-bottom: 10px; padding-top: 5px"
        >
          <div class="col-12 text-center">
            <h1 style="margin-top: 15px; margin-bottom: 20px">Kontakt</h1>
            <p>
              Vi finns tillgängliga för dig som kund oavsett var du har din
              verksamhet. Våra kontor finns i Gävle, Söderhamn och Hudiksvall.
            </p>
            <p>
              NextMind AB<br />Sjötullgatan 64, 826 50 Söderhamn<br />Sverige
            </p>
          </div>
        </div>

        <div
          class="row"
          style="margin-bottom: 20px; padding-bottom: 10px; padding-top: 5px"
        >
          <div class="col-4 text-center" style="padding: 0px 5px" *ngIf="false">
            <h5>Gävle</h5>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1954.0556121060379!2d17.146862416246574!3d60.67515560943161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4660c666c2a404bb%3A0x54984a04035627d5!2sKyrkogatan+33%2C+803+11+G%C3%A4vle!5e0!3m2!1ssv!2sse!4v1542138392903"
              style="border: 0; width: 100%; height: 300px"
            ></iframe>
          </div>
          <div class="col-6 text-center" style="padding: 0px 5px">
            <h5>Söderhamn</h5>
            <iframe
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD09zQ9PNDNNy9TadMuzRV_UsPUoWKntt8&amp;q=Sj%C3%B6tullgatan+64%2C+82650+S%C3%B6derhamn"
              style="border: 0; width: 100%; height: 300px"
            ></iframe>
          </div>
          <div class="col-6 text-center" style="padding: 0px 5px">
            <h5>Hudiksvall</h5>
            <iframe
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD09zQ9PNDNNy9TadMuzRV_UsPUoWKntt8&amp;q=Norra+kyrkogatan+17%2C+hudiksvall"
              style="border: 0; width: 100%; height: 300px"
            ></iframe>
          </div>
        </div>

        <div id="contact-link"></div>
        <div class="row" style="padding-top: 5px">
          <div class="col-12 text-center">
            <p>
              Epost: <a href="mailto:info@nextmind.com">info@nextmind.com</a>
            </p>
          </div>
        </div>

        <div class="row" *ngIf="!contactSuccess && false">
          <div class="col-12 d-lg-none">
            <h2 style="padding: 20px 0px 0 0; margin-bottom: 0px">
              <span style="font-weight: 500">Här kan du</span><br />
              <span style="font-weight: 500; color: #82ad41">kontakta oss</span>
            </h2>
          </div>
        </div>
        <div class="row" *ngIf="!contactSuccess && false">
          <div class="col-4 d-none d-lg-block">
            <h2 style="padding: 20px 0px 0 0">
              <span style="font-weight: 500">Här kan du</span><br />
              <span style="font-weight: 500; color: #82ad41">kontakta oss</span>
            </h2>
          </div>
          <div class="col-lg-8 col-12 text-left" *ngIf="!contactSuccess">
            <input
              type="text"
              name="name"
              placeholder="ditt namn"
              tabindex="1"
              [(ngModel)]="contactName"
              (keyup)="contactInputChange()"
            />
            <input
              type="email"
              name="email"
              placeholder="din e-post"
              tabindex="2"
              [(ngModel)]="contactEmail"
              (keyup)="contactInputChange()"
            />
            <textarea
              placeholder="meddelande"
              tabindex="3"
              [(ngModel)]="contactMessage"
              (keyup)="contactInputChange()"
              style="font-size: 18px"
            ></textarea>
            <p style="margin-bottom: 0px" *ngIf="showContactErrorMessage">
              Felaktig information inmatat! Se till att du anger all information
              korrekt i de tre fälten ovan (ditt namn, din giltiga e-post och
              ett text-meddelande).
            </p>
            <button
              [disabled]="loading"
              class="secondary"
              tabindex="4"
              style="margin-top: 10px; margin-left: 0px; padding-bottom: 15px"
              (click)="contactSubmit()"
            >
              Skicka
            </button>
          </div>
        </div>

        <div class="row" *ngIf="contactSuccess && false">
          <div class="col-12 text-center">
            <h3 style="margin-top: 15px; margin-bottom: 20px">
              Meddelande skickat!
            </h3>
            <p>Tack för ditt meddelande. Vi återkommer snart till dig.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--
  <section id="lamp" class="edge--top--reverse">

    <svg width="1271px" height="696px" viewBox="0 0 1271 696" class="paralax on lamp d-none d-lg-block " style="z-index:2;">
      <defs>
        <linearGradient x1="100%" y1="0%" x2="-91.8802083%" y2="260.022615%" id="linearGradient-1">
          <stop stop-color="#0079B9" offset="0%"></stop>
          <stop stop-color="#A2CCE2" offset="65.1393556%"></stop>
          <stop stop-color="#F8F8F8" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="0%" y1="19.4029018%" x2="77.1816038%" y2="109.672215%" id="linearGradient-2">
          <stop stop-color="#0079B9" offset="0%"></stop>
          <stop stop-color="#F8F8F8" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="LIGHTBULB">
          <circle id="Oval-3" fill="#FFFFFF" cx="467.5" cy="77.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="540.5" cy="41.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="621.5" cy="77.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="694.5" cy="174.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="694.5" cy="318.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="592.5" cy="130.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="551.5" cy="186.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="592.5" cy="260.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="592.5" cy="355.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="472.5" cy="215.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="595.5" cy="441.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="575.5" cy="513.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="506.5" cy="495.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="490.5" cy="531.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="416.5" cy="545.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="578.5" cy="545.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="426.5" cy="570.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="581.5" cy="567.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="416.5" cy="590.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="578.5" cy="590.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="416.5" cy="629.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="575.5" cy="624.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="431.5" cy="647.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="556.5" cy="647.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="413.5" cy="508.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="406.5" cy="446.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="485.5" cy="425.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="504.5" cy="358.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="324.5" cy="379.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="324.5" cy="379.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="283.5" cy="260.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="357.5" cy="304.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="451.5" cy="309.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="401.5" cy="382.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="357.5" cy="177.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="314.5" cy="125.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="436.5" cy="144.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="406.5" cy="52.5" r="2.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="655.5" cy="232.5" r="2.5"></circle>
          <path d="M621.5,77.5 L694.5,174.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M592.5,130.5 L694.5,174.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M621.5,77.5 L540.5,41.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M405.5,52.5 L540.5,41.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M405.5,52.5 L312.5,127.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M357.5,177.5 L314.5,126.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M283.5,261.5 L314.5,124.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M283.5,261.5 L324.5,379.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M357.5,303.5 L324.5,379.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M357.5,303.5 L400.5,382.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M357.5,303.5 L283.5,260.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M401.5,382.5 L324.5,379.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M406.5,446.5 L324.5,379.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M401.5,382.5 L407.5,448.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M505.5,495.5 L407.5,448.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M505.5,495.5 L485.5,426.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M505.5,495.5 L576.5,513.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M488.5,532.5 L576.5,513.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M488.5,532.5 L412.5,508.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M578.5,544.5 L416.5,544.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M416.5,546.5 L426.5,569.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M579.5,546.5 L582.5,566.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M582.5,566.5 L425.5,571.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M578.5,591.5 L416.5,591.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M575.5,623.5 L419.5,628.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M416.5,629.5 L416.5,591.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M416.5,629.5 L432.5,648.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M554.5,648.5 L432.5,648.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M554.5,648.5 L575.5,624.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M578.5,591.5 L575.5,624.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M425.5,570.5 L416.5,591.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M582.5,567.5 L579.5,591.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M582.5,567.5 L579.5,591.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M507.5,495.5 L412.5,508.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M406.5,448.5 L412.5,508.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M595.5,442.5 L576.5,513.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M595.5,442.5 L695.5,317.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M695.5,175.5 L695.5,317.5" id="Line" stroke="#0BB8FF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M695.5,175.5 L654.5,232.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M548.5,185.5 L654.5,232.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M592.5,130.5 L654.5,232.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M592.5,130.5 L621.5,78.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M551.5,186.5 L591.5,260.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M470.5,216.5 L591.5,260.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M470.5,216.5 L552.5,185.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M472.5,215.5 L437.5,144.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M472.5,215.5 L451.5,311.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M504.5,358.5 L591.5,260.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M591.5,355.5 L591.5,260.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M591.5,355.5 L656.5,232.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M450.5,309.5 L591.5,260.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M450.5,309.5 L505.5,358.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M450.5,309.5 L400.5,382.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M450.5,309.5 L358.5,304.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M450.5,309.5 L358.5,178.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M592.5,355.5 L358.5,178.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M324.5,377.5 L357.5,177.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M694.5,317.5 L591.5,260.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M504.5,358.5 L592.5,355.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M504.5,358.5 L485.5,426.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M593.5,355.5 L485.5,426.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M403.5,383.5 L485.5,426.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M596.5,441.5 L592.5,355.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M595.5,440.5 L485.5,426.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M405.5,52.5 L467.5,77.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M541.5,40.5 L467.5,77.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M541.5,40.5 L467.5,77.5" id="Line" stroke="url(#linearGradient-1)" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M541.5,40.5 L593.5,131.5" id="Line" stroke="url(#linearGradient-2)" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M435.5,144.5 L591.5,130.5 L473.095703,214.822266 L357.550781,177.826172 C307.744792,232.814453 283.364583,260.233724 284.410156,260.083984 C285.455729,259.934245 326.547526,190.204427 407.685547,50.8945312 L435.5,144.5 Z" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M551.5,186.5 L591.5,130.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M435.5,144.5 L357.5,177.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M406.5,52.5 L357.5,177.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M357.5,305.5 L357.5,177.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <path d="M435.5,144.5 L468.5,77.5" id="Line" stroke="#FFFFFF" stroke-width="0.5" stroke-linecap="square"></path>
          <circle id="Oval-8" fill="#D8D8D8" cx="540.5" cy="41.5" r="7.5"></circle>
          <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="593 131 540 40 467.853516 77.3056641"></polygon>
          <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="451 310 553 186 473 216"></polygon>
          <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="593 262 553 186 473 216"></polygon>
          <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="597.154297 440.466797 631.34375 279.851562 592.285156 262.376953"></polygon>
          <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="486 426 452 309 505 358"></polygon>
          <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="504 359 358 305 452 309"></polygon>
          <polygon id="Triangle" fill="#0079B9" opacity="0.203804348" points="436 146 540 40 468.379883 77.5585938"></polygon>
          <polygon id="Triangle" fill="#0079B9" opacity="0.63915308" points="471 215 535.725586 170.165039 506.297852 290.238281"></polygon>
          <path d="M695.515625,227.758789 L693.501953,174.580078 C722.867501,157.080866 737.593412,148.610163 737.679688,149.167969 C737.765963,149.725774 723.711275,206.192897 695.515625,318.569336 L695.515625,227.758789 Z" id="Triangle" fill="#0079B9" opacity="0.206804801"></path>
          <polygon id="Triangle" fill="#0079B9" opacity="0.292798913" points="357 177.474609 416.944336 153 450.407227 310.892578"></polygon>
          <polygon id="Triangle" fill="#0079B9" opacity="0.63915308" points="357.705078 305.708984 436.725586 145 436.725586 237.580078"></polygon>
          <polygon id="Triangle" fill="#0079B9" opacity="0.63915308" points="654.866211 232.765625 695.515625 175.155273 695.515625 318.569336"></polygon>
          <path d="M406.697266,420.394531 L400.821871,382.224349 C529.037942,364.520884 593.189116,355.948054 593.275391,356.505859 C593.361666,357.063665 531.168957,387.357285 406.697266,447.386719 L406.697266,420.394531 Z" id="Triangle" fill="#0079B9" opacity="0.206804801"></path>
          <path d="M302.193359,316.359375 L282.821871,260.437956 C319.99888,281.090784 338.630522,291.696101 338.716797,292.253906 C338.803072,292.811712 334.775728,321.751165 326.634766,379.072266 L302.193359,316.359375 Z" id="Triangle" fill="#0079B9" opacity="0.206804801"></path>
          <path d="M944.335052,2.66153846 L816.664948,87.3384615" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M737.658333,150.333333 L815.341667,87.6666667" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M737.658333,150.333333 L621.5,77.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M737.658333,150.333333 L693.5,175.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M737.662722,149.375 L960.337278,145.625" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M694.663366,319.337121 L961.336634,145.662879" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M694.66,318.663043 L792.34,378.336957" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M1019.33721,337.65625 L792.662791,378.34375" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M1018.33333,337.339041 L959.666667,145.660959" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M1018.33333,337.339041 L1207.5,230.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M957.5,145.5 L1207.5,230.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M942.5,2.5 L1207.5,230.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M941.5,4.5 L959.666667,145.660959" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M1018.34058,337.6625 L928.65942,495.3375" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M1268.5,495.3375 L928.65942,495.3375" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M794.661765,580.338462 L928.338235,495.661538" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M794.375,580.336601 L790.625,378.663399" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M794.375,580.336601 L573.5,625.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M738.333333,149.662791 L695.666667,319.337209" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          <path d="M203.335052,608.661538 L75.6649485,693.338462" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(139.500000, 651.000000) rotate(-180.000000) translate(-139.500000, -651.000000) "></path>
          <path d="M204.658333,608.333333 L282.341667,545.666667" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(243.500000, 577.000000) rotate(-180.000000) translate(-243.500000, -577.000000) "></path>
          <path d="M59.6627219,550.375 L282.337278,546.625" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(171.000000, 548.500000) rotate(-180.000000) translate(-171.000000, -548.500000) "></path>
          <path d="M58.6633663,550.337121 L325.336634,376.662879" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(192.000000, 463.500000) rotate(-180.000000) translate(-192.000000, -463.500000) "></path>
          <path d="M227.66,317.663043 L325.34,377.336957" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(276.500000, 347.500000) rotate(-180.000000) translate(-276.500000, -347.500000) "></path>
          <path d="M227.337209,317.65625 L0.662790698,358.34375" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(114.000000, 338.000000) rotate(-180.000000) translate(-114.000000, -338.000000) "></path>
          <path d="M60.3333333,550.339041 L1.66666667,358.660959" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(31.000000, 454.500000) rotate(-180.000000) translate(-31.000000, -454.500000) "></path>
          <path d="M60.5,550.5 L77.6666667,695.660959" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(69.000000, 623.000000) rotate(-180.000000) translate(-69.000000, -623.000000) "></path>
          <path d="M91.3405797,200.6625 L1.65942029,358.3375" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(46.500000, 279.500000) rotate(-180.000000) translate(-46.500000, -279.500000) "></path>
          <path d="M91.6617647,200.338462 L225.338235,115.661538" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(158.500000, 158.000000) rotate(-180.000000) translate(-158.500000, -158.000000) "></path>
          <path d="M229.375,317.336601 L225.625,115.663399" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(227.500000, 216.500000) rotate(-180.000000) translate(-227.500000, -216.500000) "></path>
          <path d="M314.375,124.336601 L225.5,115.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(270.000000, 120.000000) rotate(-180.000000) translate(-270.000000, -120.000000) "></path>
          <path d="M283.375,259.336601 L225.5,115.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(254.500000, 187.500000) rotate(-180.000000) translate(-254.500000, -187.500000) "></path>
          <path d="M324.333333,376.662791 L281.666667,546.337209" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(303.000000, 461.500000) rotate(-180.000000) translate(-303.000000, -461.500000) "></path>
          <path d="M416.333333,589.662791 L281.5,546.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(349.000000, 568.000000) rotate(-180.000000) translate(-349.000000, -568.000000) "></path>
          <circle id="Oval" fill="#FFFFFF" cx="695" cy="318" r="4"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="738" cy="149" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="738" cy="149" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="792" cy="379" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="1018" cy="338" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="930" cy="495" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="795" cy="579" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="816" cy="87" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="942" cy="2" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="959" cy="146" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="695.5" cy="317.5" r="3.5"></circle>
          <circle id="Oval-3" fill="#FFFFFF" cx="1208" cy="230" r="2"></circle>
          <circle id="Oval-7" stroke="#979797" fill="#D8D8D8" cx="1268" cy="498" r="2"></circle>
          <circle id="Oval" fill="#FFFFFF" transform="translate(325.000000, 378.000000) rotate(-180.000000) translate(-325.000000, -378.000000) " cx="325" cy="378" r="4"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(282.000000, 547.000000) rotate(-180.000000) translate(-282.000000, -547.000000) " cx="282" cy="547" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(282.000000, 547.000000) rotate(-180.000000) translate(-282.000000, -547.000000) " cx="282" cy="547" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(228.000000, 317.000000) rotate(-180.000000) translate(-228.000000, -317.000000) " cx="228" cy="317" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(2.000000, 358.000000) rotate(-180.000000) translate(-2.000000, -358.000000) " cx="2" cy="358" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(90.000000, 201.000000) rotate(-180.000000) translate(-90.000000, -201.000000) " cx="90" cy="201" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(225.000000, 117.000000) rotate(-180.000000) translate(-225.000000, -117.000000) " cx="225" cy="117" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(204.000000, 609.000000) rotate(-180.000000) translate(-204.000000, -609.000000) " cx="204" cy="609" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(78.000000, 694.000000) rotate(-180.000000) translate(-78.000000, -694.000000) " cx="78" cy="694" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(61.000000, 550.000000) rotate(-180.000000) translate(-61.000000, -550.000000) " cx="61" cy="550" r="2"></circle>
          <circle id="Oval-3" fill="#FFFFFF" transform="translate(324.500000, 378.500000) rotate(-180.000000) translate(-324.500000, -378.500000) " cx="324.5" cy="378.5" r="3.5"></circle>
        </g>
      </g>
    </svg>
  </section>
  -->
  <!-- Footer -->
  <footer>
    <div class="container-fluid">
      <p class="m-0 text-center text-white">
        &copy;2019 NextMind® AB. ALL RIGHTS RESERVED.
      </p>
    </div>
    <!-- /.container -->
  </footer>
</div>
