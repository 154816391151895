<!-- <header>
  <div class="container">
     <nav>
       <a href="javascript:void(0)" class="pull-left">
         <img src="assets/img/logo_white.png" class="logo">
       </a>
       <div class="navLinks pull-right">
         <ul>
           <li><a href="javascript:void(0)">OVERVIEW</a></li>
           <li><a href="javascript:void(0)">PRODUCTS</a></li>
           <li><a href="javascript:void(0)">FEATURES</a></li>
           <li><a href="javascript:void(0)">PRICING </a></li>
           <li><a href="javascript:void(0)"> LOG IN  <img src="assets/img/ico_login.png" alt="Login" /></a></li>
         </ul>
       </div>
     </nav>
  </div>
</header> -->


<div id="page-top">
  <svg width="1440px" height="1778px" viewBox="0 0 1440 1778" class="d-none d-lg-block" style="position:absolute;left:0;top:0;bottom:0;right:0;margin-left:auto;margin-right:auto;width:100%; pointer-events: none;">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="NEW-LOG-IN">
        <g id="CONNECTIONS" transform="translate(-79.000000, -18.000000)">
          <g id="CONNECTIONS-2">
            <!--<path d="M1196.5,375.5 L1582.5,283.5" id="Line" stroke="#6CD3D6" stroke-width="0.5" stroke-linecap="square"></path>-->
            <circle id="Oval" fill="#FFFFFF" cx="1170" cy="77" r="3"></circle>
            <path d="M1383.5,138.5 L1350.5,0.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1694.5,126.5 L1383.5,137.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1273.5,573.5 L1579.5,663.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="1272.5" cy="572.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1383.5" cy="137.5" r="1.5"></circle>
            <path d="M1384.5,137.5 L1288.5,201.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1441.5,390.5 L1520.5,541.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1475.5,653.5 L1520.5,541.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1475.5,653.5 L1370.5,782.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1370.5,782.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1441.5,390.5 L1537.5,348.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1373.5,509.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1521.5,542.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1269.5,421.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1272.5,573.5 L1119.5,510.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="1474.5" cy="654.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1370.5" cy="780.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="268.5" cy="135.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="153.5" cy="562.5" r="1.5"></circle>
            <path d="M268.5,136.5 L309.5,224.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L575.5,205.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L178.5,6.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M268.5,136.5 L185.5,216.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M272.5,427.5 L69.5,235.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M0.5,341.5 L157.5,365.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M186.5,216.5 L69.5,235.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M305.5,505.5 L154.5,562.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></path>
            <path d="M273.5,429.5 L154.5,562.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1129.5,1181.5 L1522.5,1225.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1528.5,1479.5 L1394.5,1409.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1454.66429,1677.33645 L1546.33571,1536.66355" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M247.5,1729.5 L61.6666667,1801.66096" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(154.500000, 1765.500000) rotate(-180.000000) translate(-154.500000, -1765.500000) "></path>
            <path d="M63.3405797,1482.6625 L188.5,1537.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(126.000000, 1510.000000) rotate(-180.000000) translate(-126.000000, -1510.000000) "></path>
            <path d="M278.5,1379.5 L63.6594203,1302.3375" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" transform="translate(171.000000, 1341.000000) rotate(-180.000000) translate(-171.000000, -1341.000000) "></path>
          </g>
          <g id="CONNECTIONS-1" transform="translate(156.000000, 162.000000)">
            <circle id="Oval-3" fill="#FFFFFF" cx="1073.5" cy="86.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1073.5" cy="86.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1114.5" cy="259.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1284.5" cy="228.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1218.5" cy="347.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1011.5" cy="7.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1132.5" cy="39.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1240.5" cy="83.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1041.5" cy="213.5" r="2.5"></circle>
            <path d="M1011.5,7.5 L1074.5,87.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1011.5,7.5 L1132.5,39.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1132.5,39.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1241.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1040.5,214.5 L1241.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1040.5,214.5 L1114.5,259.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1285.5,228.5 L1114.5,259.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1284.5,228.5 L1240.5,83.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1285.5,228.5 L1217.5,347.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L963.5,74.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1073.5,86.5 L1041.5,214.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M1011.5,7.5 L895.5,0.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <circle id="Oval-3" fill="#FFFFFF" cx="150.5" cy="343.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="153.5" cy="62.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="1.5" cy="203.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="29.5" cy="54.5" r="1.5"></circle>
            <circle id="Oval-3" fill="#FFFFFF" cx="117.5" cy="266.5" r="1.5"></circle>
            <path d="M353.5,120.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M30.5,54.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M30.5,54.5 L1.5,203.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M116.5,265.5 L1.5,203.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M115.5,266.5 L228.5,160.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M115.5,266.5 L333.5,146.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M149.5,343.5 L261.5,282.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M149.5,343.5 L119.5,266.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
            <path d="M228.5,160.5 L153.5,62.5" id="Line-2" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- Navigation -->

  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" routerLink="/"><img src="assets/img/logo_white.png" class="logo" alt="Logotype" /></a>
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav">       
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/services">{{ 'front.services' | translate }}</a>
          </li>
          <li class="nav-item">
              <a class="nav-link js-scroll-trigger" routerLink="/vocean">Lösning</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/education">Utbildning</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/about">{{ 'front.about' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" routerLink="/blog">{{ 'front.blog' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger login" routerLink="/contact">{{ 'front.contact' | translate }} <img src="assets/img/ico_login.png" alt="Contact" /></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <section id="contact" class="block">
  <div class="contactForm" style="margin-top: 65px;">
      <div class="row">
        <div class="col-12 text-center" style="margin-bottom: 20px;">
             <img alt="Vocean People View" src="assets/img/innovate_usage.png" style="width: 100%; margin-top: 10px; margin-bottom: 25px; border-radius: 10px;" />
             <h3>Frigör kraften i digitalt samskapande - förbättra dina agila processer</h3>
             <img alt="Digitalt samskapande" src="assets/img/digitalt_samskapande.png" style="width: 100%; margin-top: 10px; margin-bottom: 25px; border-radius: 10px;" />
             <p>Vi använder<a href="https://vocean.com/sv/landingpage/">Vocean</a>, släpp loss kraften i digitalt samskapande i det agila arbetet.</p>
        </div>
      </div>
    </div>
  </section>
  <section id="contact" class="block">
    <div class="contactForm qr-wrapper">
        <div class="row">
          <div class="col-12 text-center" style="margin-bottom: 20px;">
  
            <h3><b>Använd QR-koder i din verksamhet</b></h3>
            <p>Vi samarbetar med och använder <a href="https://vocean.com/sv/landingpage/">digitala tjänster från Vocean,</a> partner med Microsoft. Släpp loss kraften i digitalt samskapande i det agila arbetet.</p>
            <p>Genom QR-koder i Vocean kommer du direkt till det digitala verktyget! Prova direkt, här och nu. Scanna mobiltelefonen eller klicka på QR-koderna för att komma direkt in i det digitala verktyget.</p>
        
              <div class="row qr-list-container">
                <div class="col-lg-4">
                  <a href="https://thinker.vocean.com/#/direct/2cc7e83a-6113-4cb7-aa9e-cd34cbb0a05d" target="NEW"><img src="../../assets/img/vocean/vote-qr.png" /></a>
                </div>
                <div class="col-lg-8">
                  <h3>Rösta</h3>
                  <p class="qr-list-text">Vad tycker du om Vocean? Ge din röst och sedan kan du följa resultatet av röstning i realtid.</p>
                  <p><a href="https://sessions.vocean.com/526ff380-43a6-4537-9206-09644363e636" target="NEW">Se dashboard</a></p>
                </div>
              </div>
        
              <div class="row qr-list-container">
                <div class="col-lg-4">
                  <a href="https://thinker.vocean.com/#/direct/3aae192a-2602-4567-bcff-ee0e99aa1b6d" target="NEW"><img src="../../assets/img/vocean/innovate-qr.png" /></a>
                </div>
                <div class="col-lg-8">
                  <h3>Innovera</h3>
                  <p class="qr-list-text">Hur får man en organisation att bli digitaliserad? I Voceans innovera aktivitet får tänkaren komma med idéer, värdera dem och ledaren av aktiviteten kan enkelt jobba med de insamlade tankarna.</p>
                  <p><a href="https://sessions.vocean.com/91f08b64-e91e-4860-a324-bb8808c712e0" target="NEW">Se dashboard</a></p>
                </div>
              </div>
        
              <div class="row qr-list-container">
                <div class="col-lg-4">
                  <a href="https://thinker.vocean.com/#/direct/129879fc-b34a-44d3-81fc-d84f4445d35c" target="NEW"><img src="../../assets/img/vocean/explore-qr.png" /></a>
                </div>
                <div class="col-lg-8">
                  <h3>Utforska</h3>
                  <p class="qr-list-text">Hur mår du? Med aktiviteten Utforska kan du snabbt och enkelt fråga och få svar i realtid direkt från de som du bjudit in som deltagare eller de som använder QR-kod. Deltagarna i aktiviteten får också se det aggregerade resultatet av aktiviteten efteråt som uppdateras i realtid.</p>
                  <p><a href="https://sessions.vocean.com/9095e67f-30fe-4bdd-8431-b1d18e2a3b81" target="NEW">Se dashboard</a></p>
                </div>
              </div>              
            </div>
          </div>
      </div>
    </section>
      <section id="contact" class="block">
        <div class="contactForm">
          <h1 style="margin-top: 85px; margin-bottom: 20px;">Nu är du redo för nästa steg!</h1>
          <h3><a href="https://thinker.vocean.com/#/report/0418bf7c-2262-472c-b88d-e15abb3fc40f">Boka 15-30 minuter</a></h3>
          <p>webbmöte med en av våra produktspecialister.</p>
          <img alt="Nextmind Logotype" src="assets/img/logo_white.png" style="width: 250px; margin-bottom: 10px;" />
          <p>NextMind är certifierad och rådgivande partner till Vocean.</p>
        </div>
      </section>
  <!-- Footer -->
  <footer>
    <div class="container-fluid">
      <p class="m-0 text-center text-white">&copy;2022 NextMind® AB. ALL RIGHTS RESERVED.</p>
    </div>
    <!-- /.container -->
  </footer>
</div>
