<h1>Kod till framgång!</h1>
<p>juni 25, 2019</p>
<img src="../../../assets/img/blog/city-globe.png" />

<p>Känn-Tänk-Gör<br><br>Koden för framgång i din organisation tror vi är när medarbetare, känner, tänker och sedan tar action. Viktigt att detta fångas upp av ledning, medarbetare får återkoppling och kan genom dessa steg snabbt utvecklas.</p>

<p>Det kan handla om att fånga, idéer, kreativa tankar, saker som inte fungerar bra, sådant som behöver göras omedelbart, sådant som behöver förändras över tid eller enbart åsikter som är viktiga att framföra. Kraften för innovation i organisation skapas genom att ta tillvara på känslor och tankar som medarbetare bär på dagligen!</p>

<p>Känn-Tänk-Gör-Utvecklas</p>

<p>Kod för framgång? Hur tänker du?</p>

<p>Den stora frågan och utmaningen blir då hur kan du lyckas med detta? Fånga känslan, tanken, idé just när den uppkommer och låt den sedan göra rätt resa.</p>

<p>Peter Kollin,<br>Founder & CEO<br>NextMind</p>
