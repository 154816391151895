import { Component, OnInit, AfterViewInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss", "../../assets/partials/contact.scss"],
})
export class ContactComponent implements OnInit, AfterViewInit {
  contactName: string;
  contactEmail: string;
  contactMessage: string;

  showContactErrorMessage: boolean = false;
  loading: boolean = false;
  contactSuccess: boolean = false;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const tree = this.router.parseUrl(this.router.url);
    if (tree.fragment) {
      const element = document.querySelector("#" + tree.fragment);
      console.log(element);
      if (element) {
        element.scrollIntoView(true);
      }
    }
  }

  contactSubmit(): void {
    this.loading = true;
    this.showContactErrorMessage = false;

    let requestData = {
      Name: this.contactName,
      From: this.contactEmail,
      Text: this.contactMessage,
    };

    if (
      requestData.Name == null ||
      requestData.Name.length < 1 ||
      requestData.Text == null ||
      requestData.Text.length < 1 ||
      requestData.From == null ||
      !this.isValidEmail(requestData.From)
    ) {
      this.showContactErrorMessage = true;
      this.loading = false;
      return;
    }

    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    const functionURI = "https://functions.vocean.com/api/nextmind/contact";

    this.http
      .post(functionURI, requestData, { headers: headers })
      .subscribe((data) => {
        if (data == false) {
          this.showContactErrorMessage = true;
        } else {
          this.contactSuccess = true;
        }
        this.loading = false;
      });
  }

  contactInputChange(): void {
    this.showContactErrorMessage = false;
  }

  isValidEmail(email: string): boolean {
    let EMAIL_REGEXP = /\S+@\S+\.\S+/;
    return EMAIL_REGEXP.test(email);
  }
}
