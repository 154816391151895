import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";

import { AppComponent } from "./app.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { ContactComponent } from "./contact/contact.component";
import { ServicesComponent } from "./services/services.component";
import { PolicyComponent } from "./policy/policy.component";
import { AboutComponent } from "./about/about.component";
import { EducationComponent } from "./education/education.component";
import { VoceanComponent } from "./vocean/vocean.component";
import { RouterModule, Routes } from "@angular/router";
import { BlogComponent } from "./blog/blog.component";
import { Blog190624Component } from "./blog/blog-190624/blog-190624.component";
import { Blog190625Component } from "./blog/blog-190625/blog-190625.component";
import { Blog190629Component } from "./blog/blog-190629/blog-190629.component";
import { Blog190831Component } from "./blog/blog-190831/blog-190831.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const appRoutes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "login", component: LoginComponent },
  { path: "login_b2c.html", component: LoginComponent },
  { path: "contact", component: ContactComponent },
  { path: "services", component: ServicesComponent },
  { path: "policy", component: PolicyComponent },
  { path: "about", component: AboutComponent },
  { path: "education", component: EducationComponent },
  { path: "vocean", component: VoceanComponent },
  {
    path: "blog",
    component: BlogComponent,
    children: [
      {
        path: "blog-190624",
        component: Blog190624Component,
        pathMatch: "full",
      },
      {
        path: "blog-190625",
        component: Blog190625Component,
        pathMatch: "full",
      },
      {
        path: "blog-190629",
        component: Blog190629Component,
        pathMatch: "full",
      },
      {
        path: "blog-190831",
        component: Blog190831Component,
        pathMatch: "full",
      },
    ],
  },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ContactComponent,
    ServicesComponent,
    PolicyComponent,
    AboutComponent,
    EducationComponent,
    VoceanComponent,
    BlogComponent,
    Blog190624Component,
    Blog190625Component,
    Blog190629Component,
    Blog190831Component,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true, relativeLinkResolution: 'legacy' } // <-- debugging purposes only
 // <-- debugging purposes only
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
