import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

declare var navigator: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'app';

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
    // Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // (<any>window).ga('set', 'page', event.urlAfterRedirects);
        // (<any>window).ga('send', 'pageview');
        (<any>window).gtag('config', 'UA-135288899-1', { 'page_path': event.urlAfterRedirects });
      }
    });

    this.setLang();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0);
    });
  }

  setLang() {
    let userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.toLowerCase();
    console.log('Browser language - ' + userLang);
    if (userLang.includes('sv')) {
      userLang = 'sv';
    } else {
      userLang = 'en';
    }
    this.translate.setDefaultLang(userLang);
  }
}
