<h1>Släpp din osäkerhet: Tillit får ditt team att växa!</h1>
<p>juni 25, 2019</p>
<img src="../../../assets/img/blog/trust1.png" />

<p>VOCEAN® existerar för att företagets grundare insåg att de flesta organisationer inte till fullo drar nytta av alla de färdigheter och den unika potential som deras medarbetare har. Till stor del handlar det om ett synsätt kring hur man ser på ”mänskliga resurser”. I Vocean är ingen medarbetare decimerad till en resurs. Alla är vi individer som sammantaget bygger upp en organisation. Vi är alla människor; var och en med sitt unika synsätt, kompetens och sina egna drivkrafter. Förändringen gällande detta synsätt börjar med ledarskapet och därför handlar vår blogg ofta om just ledarskap och det som kallas ”Det Nya Ledarskapet”. Vi har tidigare berört hur Det Nya Ledarskapet påverkar och gör vardagen bättre för de anställda och i det långa loppet även företaget.</p>

<p>Idag skriver vi om vad det innebär för dig som ledare.</p>

<p>Det är djupt inpräntat i de flesta av oss människor hur man leder. Genom populärkultur, företagskultur, utbildningar och förutfattade meningar har vi alla en bild kring vad en ”chef” är. Det handlar ofta om kontroll, om att styra, om synen att en person vet mycket mer och bättre än de under hen. Det synsättet utmanar vi. Den samlade forskningen pekar på att mjukare, mer empatiskt och transparant sätt att leda är mycket mer effektiva än de emotionellt frånkopplade och strikt hierarkiska.</p>

<h4>Ett målstyrt arbetssätt</h4>
<p>För en chef betyder det att man måste släppa sitt kontrollbehov över hur uppgifter utförs och istället fokusera på målsättningen och syftet med uppgiften. Det vardagliga måste få ha sin gång och det måste finnas en struktur, men det är resultaten som ska ligga i fokus. Ledaren måste tillåta sig själva att lita på sina anställda och medarbetare. Det som ska byggas in i strukturen är att tillåta nya arbetssätt och att medarbetarna till och med får misslyckas, så länge man drar lärdom. Att få alla att engagera sig och arbeta för företagets bästa, är mycket svårare när man pekar med hela handen och säger ”så här ska det göras”. Om inte människorna i en organisation känner att misstag tillåts, så kommer de inte att ge sig hän och utvecklas.</p>

<h4>Struktur, tillit och frihet</h4>
<p>Det nya ledarskapet handlar också om praktisk öppenhet och inte bara om EQ. Hur företaget organiseras, varifrån människor jobbar och vilka tider de jobbar på. Om medarbetare kan påverka även det praktiska kring sina arbeten växer de oftast och blir en mer engagerad spelare i byggandet av katedralen. De måste också kunna samarbeta enkelt med kollegor såväl som med externa samarbetspartners. Samarbeten behövs dessutom uppmuntras inte bara från ledarhåll, men också genom den digitala infrastrukturen. Exempel på struktur i detta fall är till exempel vilken mjukvara som används, hur arbetsplatser ser ut samt också hur ledarna beter sig.</p>

<h4>Bejakande och förlåtande ledarskap</h4>
<p>Arbetsplatser där ledare jobbar utifrån empati och tillit skapar en kultur där anställda i allt högre grad stöttar varandra. En ledare som vill uppnå detta måste alltså själv präglas av egenskaper som empati och tillit. Ledaren måste se sina medarbetare, låta dem ta ansvar, möjliggöra självledarskap, vara förlåtande och tillåta misstag. Mod och trygghet måste byggas upp hos de anställda, det sker inte genom hård kontroll och ett oförlåtande klimat.</p>
<p>Kraften av snällhet och EQ är enorm. Även inom organisationer då människor även där växer av tillit och krymper av intolerans.</p>
